<template>
  <footer class="font-avenir no-print">
    <div class="border-bottom px-4">
      <div class="container-design px-4">
        <div
          class="row py-4 justify-content-between align-items-center font-70"
        >
          <div class="col-md-6 col-lg-4 mb-4 mb-md-0">
            <ul class="nav justify-content-center justify-content-md-start">
              <li
                v-for="(social, index) in fields.footer.socials"
                :key="'footer-social-' + index"
                class="nav-item mr-4"
              >
                <a
                  v-if="social.social_link"
                  class="p-2 hover:opacity-75 transition-250"
                  :href="social.social_link.url"
                  :target="social.social_link.target"
                >
                  <component
                    v-if="social.social_type"
                    :is="social.social_type"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div class="col-md-6 col-lg-5">
            <ul class="nav justify-content-center justify-content-md-end">
              <li
                v-for="(link, index) in fields.footer.links"
                :key="'footer-link-' + index"
                class="nav-item"
              >
                <prismic-link
                  class="nav-link text-lighter"
                  :v-if="link.link_url"
                  :field="link.link_url"
                >
                  {{ link.link_text }}
                </prismic-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="px-4 opacity-75">
      <div class="container-design px-4">
        <div
          class="row py-4 justify-content-between align-items-center font-70 text-lighter"
        >
          <div class="col-md-6 col-lg-3 text-center text-md-left">
            <p class="m-0 nav-link text-lighter">
              <a class="text-primary" href="https://hiyield.co.uk">
                Web Development Bristol
              </a>
              by
              <a class="text-primary" href="https://hiyield.co.uk">
                hiyield
              </a>
            </p>
          </div>
          <div class="col-md-6 col-lg-3 text-center text-md-right">
            <p class="m-0 nav-link">Copyright {{ year }} This Is Cool</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import Facebook from "@/components/icons/Facebook.vue";
import Twitter from "@/components/icons/Twitter.vue";
import Instagram from "@/components/icons/Instagram.vue";
import LinkedIn from "@/components/icons/LinkedIn.vue";

export default {
  name: "Footer",
  components: {
    Facebook,
    Twitter,
    Instagram,
    LinkedIn
  },
  created() {
    this.getContent();
  },
  data() {
    return {
      fields: {
        footer: {
          socials: null,
          links: null
        }
      }
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    }
  },
  methods: {
    getContent() {
      this.$prismic.client
        .query(
          this.$prismic.Predicates.at("document.type", "options_page"),

          { lang: this.$lang }
          // { lang: "es-es" }
        )
        .then(data => {
          let document =
            data.results !== undefined && data.results.length > 0
              ? data.results[0]
              : null;
          if (document !== null) {
            this.parseDocument(document);
          } else {
            this.$prismic.client.getSingle("options_page").then(document => {
              this.parseDocument(document);
            });
          }
          this.afterContent();
        });
    },
    afterContent() {},
    parseDocument(document) {
      if (document) {
        // Socials
        this.fields.footer.socials = document.data.footer_socials;

        // Links
        this.fields.footer.links = document.data.footer_links;
      }
    }
  }
};
</script>
