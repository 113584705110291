<template>
	<div class="container-design px-4 pt-3">
		<div class="row justify-content-center pt-5 py-lg-5">
			<!--- Left Side - Section Image --->
			<div v-if="section.image" class="col-md-8 col-lg-6">
				<prismic-image class="w-100 image-fluid" :field="section.image" />
			</div>

			<!--- Right Side - Section Content --->
			<div class="col-md-10 col-lg-6">
				<!--- Title, Heading, Desc --->
				<p class="h6 text-sm text-uppercase mb-1">
					{{ section.title }}
				</p>
				<h1 class="display-4 mb-3">
					{{ section.heading }}
				</h1>
				<prismic-rich-text
					v-if="section.content"
					:field="section.content"
					class="text-darker h6"
					style="line-height: 1.5;"
				/>

				<!--- Assignable Button --->
				<button v-if="button" @click="button.action" class="btn btn-yellow text-uppercase mt-5">
					<div class="d-flex align-items-center justify-content-between pl-2">

						<p class="mr-3 mb-0 text-sm font-weight-bold text-darker">
							{{ button.text }}
						</p>
						<div class="icon-large text-darker">
							<b-icon icon="arrow-right-short" aria-hidden="true"></b-icon>
						</div>

					</div>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "FeatureLeftTextSection",
	props: ["section", "button"]
};
</script>
