<template>
  <svg
    width="23px"
    height="19px"
    viewBox="0 0 23 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Twitter</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.498039 0 0 0 0 0.772549 0 0 0 0 0.815686 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Desktop"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Cool-Home-Two" transform="translate(-86.000000, -4713.000000)">
        <g id="FOOTER" transform="translate(-126.000000, 4522.000000)">
          <g id="Lower-Footer" transform="translate(126.000000, 148.000000)">
            <g filter="url(#filter-1)" id="social">
              <g transform="translate(34.000000, 41.000000)">
                <path
                  d="M75,4.24799897 C74.1534708,4.62947504 73.2451573,4.88818774 72.290806,5.00368926 C73.2652635,4.41024382 74.0111863,3.46895613 74.3647179,2.35078522 C73.4506543,2.90035384 72.4416968,3.29941121 71.366633,3.51573768 C70.5057476,2.58175004 69.281221,2 67.9230162,2 C65.3173224,2 63.2045886,4.14862639 63.2045886,6.79713509 C63.2045886,7.17276346 63.2462667,7.5396394 63.3267669,7.89046285 C59.4060121,7.69018887 55.929361,5.77982737 53.6024863,2.87696306 C53.1957389,3.58441942 52.964348,4.40875324 52.964348,5.28869747 C52.964348,6.95353225 53.797949,8.42248838 55.0627256,9.28190836 C54.2894809,9.25561284 53.5622362,9.03928637 52.9255636,8.67971049 L52.9255636,8.7396398 C52.9255636,11.0636588 54.5525156,13.0032588 56.7097838,13.4447024 C56.3145365,13.5528656 55.8977547,13.6127949 55.4665792,13.6127949 C55.1619039,13.6127949 54.8672629,13.5821041 54.5783719,13.5236271 C55.1791163,15.4310839 56.9210685,16.8181723 58.9849461,16.8562014 C57.3709222,18.1424649 55.3357947,18.9069076 53.1253482,18.9069076 C52.7444947,18.9069076 52.3693535,18.8835168 52,18.8411306 C54.0883057,20.2048664 56.5675369,21 59.2321589,21 C67.9115914,21 72.6559128,13.688815 72.6559128,7.3481943 L72.6400909,6.72700098 C73.5670826,6.05459256 74.3690398,5.20977271 75,4.24799897 Z"
                  id="Path"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Twitter"
};
</script>
