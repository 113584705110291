<template>
  <svg
    width="9px"
    height="19px"
    viewBox="0 0 9 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Facebook</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.498039 0 0 0 0 0.772549 0 0 0 0 0.815686 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Desktop"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Cool-Home-Two" transform="translate(-34.000000, -4711.000000)">
        <g id="FOOTER" transform="translate(-126.000000, 4522.000000)">
          <g id="Lower-Footer" transform="translate(126.000000, 148.000000)">
            <g filter="url(#filter-1)" id="social">
              <g transform="translate(34.000000, 41.000000)">
                <path
                  d="M1.94530246,3.67969506 C1.94530246,4.1585029 1.94530246,6.295623 1.94530246,6.295623 L0,6.295623 L0,9.49438983 L1.94530246,9.49438983 L1.94530246,19 L5.94136667,19 L5.94136667,9.49465488 L8.62291129,9.49465488 C8.62291129,9.49465488 8.87404968,7.96087066 8.99578522,6.2838284 C8.64676518,6.2838284 5.95647712,6.2838284 5.95647712,6.2838284 C5.95647712,6.2838284 5.95647712,4.42288753 5.95647712,4.09670272 C5.95647712,3.76981112 6.39216927,3.33009774 6.82279471,3.33009774 C7.25261307,3.33009774 8.1597782,3.33009774 9,3.33009774 C9,2.89458095 9,1.38977522 9,0 C7.87832923,0 6.60223593,0 6.03974153,0 C1.84652405,-0.000220872703 1.94530246,3.20172653 1.94530246,3.67969506 Z"
                  id="Facebook"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Facebook"
};
</script>
