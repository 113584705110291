import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const wizardToolUser = {
	namespaced: true,
	state: {
		userExists: false,
		userConditional: {
			coolingNeed: "",
			country: "",
			socialEco: "",
			userPerspective: "",
			climate: ""
		},
		userRisk: {
			fn: {
				calc: [],
				result: 0
			},
			hc: {
				calc: [],
				result: 0
			},
			hs: {
				calc: [],
				result: 0
			}
		},
		userRiskResult: "",
		userCountry: undefined,
		userQuestions: new Map(),
		userIntroQuestions: new Map(),
		userSections: new Map(),
		userSolutions: new Map(),

		apiResponse: {}
	},
	getters: {
		// Normal State GETs
		getUserExists: state => {
			return state.userExists;
		},

		getUserConditional: state => {
			return state.userConditional;
		},

		getUserRisk: state => {
			return state.userRisk;
		},
		getUserRiskResult: state => {
			return state.userRiskResult;
		},

		getUserCountry: state => {
			return state.userCountry;
		},

		// Normal GETs for the Maps
		getUserQuestions: state => {
			let foundQuestions = {};

			state.userQuestions.forEach(userQuestion => {
				// Find group (first two chars)
				if (!foundQuestions[userQuestion.fieldName.substring(0, 2)])
					foundQuestions[userQuestion.fieldName.substring(0, 2)] = [];

				// Add to group (first two chars)
				foundQuestions[userQuestion.fieldName.substring(0, 2)].push(
					userQuestion
				);
			});

			// Loop-Persistent Array
			let sortedFoundQuestions = [];

			// Go through all keys created
			for (const key of Object.keys(foundQuestions)) {
				// Sort and concat the questions
				sortedFoundQuestions = sortedFoundQuestions.concat(
					foundQuestions[key].sort((a, b) => {
						if (a.priority < b.priority) return -1;

						if (a.priority > b.priority) return 1;

						return 0;
					})
				);
			}

			return sortedFoundQuestions;
		},
		getUserQuestion: state => fieldName => {
			return state.userQuestions.get(fieldName);
		},

		getUserIntroQuestions: state => {
			return Array.from(state.userIntroQuestions.values()).sort((a, b) => {
				if (a.priority < b.priority) return -1;

				if (a.priority > b.priority) return 1;

				return 0;
			});
		},
		getUserIntroQuestion: state => id => {
			return state.userIntroQuestions.get(id);
		},

		getUserSections: (state, getters) => (excludeUnanswered = false) => {
			let userSections = Array.from(state.userSections.values());

			if (excludeUnanswered)
				userSections = userSections.filter(section => {
					// Filter Section Questions
					let sectionQuestions = getters["getUserQuestionsByFieldNames"](
						section.questions,
						true
					);

					// Check Section Questions
					if (sectionQuestions.length > 0) return true;
					return false;
				});

			return userSections.sort((a, b) => {
				if (a.priority < b.priority) return -1;

				if (a.priority > b.priority) return 1;

				return 0;
			});
		},
		getUserSection: state => sectionUid => {
			return state.userSections.get(sectionUid);
		},

		getUserSolutions: state => {
			return Array.from(state.userSolutions.values());
		},
		getUserSolution: state => id => {
			return state.userSolutions.get(id);
		},

		getApiResponse: state => {
			return state.apiResponse;
		},

		// Lookup GETs for the Maps
		getUserQuestionsBySectionUid: state => prismicSectionUid => {
			let foundQuestions = [];

			state.userQuestions.forEach(userQuestion => {
				if (userQuestion.section.uid == prismicSectionUid)
					foundQuestions.push(userQuestion);
			});

			return foundQuestions.sort((a, b) => {
				if (a.priority < b.priority) return -1;

				if (a.priority > b.priority) return 1;

				return 0;
			});
		},
		// eslint-disable-next-line prettier/prettier
		getUserQuestionsByFieldNames: state => (fieldNames, excludeUnanswered = false) => {
			let foundQuestions = [];

			state.userQuestions.forEach(userQuestion => {
				/* eslint-disable prettier/prettier */
				if (
					fieldNames.includes(userQuestion.fieldName) &&
					(
						!excludeUnanswered ||
						(
							!userQuestion.fieldUnsure && 
							(
								userQuestion.fieldValue ||
								userQuestion.fieldValue == 0
							) && 
							userQuestion.fieldValue >= 0
						)
					)
				)
					foundQuestions.push(userQuestion);
				/* eslint-enable prettier/prettier */
			});

			return foundQuestions;
		},
		getUserFields: state => {
			let userFields = {};

			// Get Base User Country Field
			userFields = state.userCountry.defaults;

			// Get User Questions
			state.userQuestions.forEach(userQuestion => {
				/* eslint-disable prettier/prettier */
				if (
					!userQuestion.fieldUnsure && 
					(
						userQuestion.fieldValue ||
						userQuestion.fieldValue == 0
					) && 
					userQuestion.fieldValue >= 0
				)
					userFields[userQuestion.fieldName] = userQuestion.fieldValue;
				/* eslint-enable prettier/prettier */
			});

			// Get Climate Logic
			userFields["te1"] = state.userConditional.climate;

			return userFields;
		},

		// Functionality GETs
		getJSON: state => {
			/* eslint-disable prettier/prettier */
			return JSON.stringify({
				"userExists": 			state.userExists,
				"userConditional": 		state.userConditional,
				"userRisk": 			state.userRisk,
				"userRiskResult": 		state.userRiskResult,
				"userCountry": 			state.userCountry,
				"userQuestions": 		Array.from(state.userQuestions.entries()),
				"userIntroQuestions": 	Array.from(state.userQuestions.entries()),
				"userSections": 		Array.from(state.userSections.entries())
			});
			/* eslint-enable prettier/prettier */
		}
	},
	mutations: {
		// Normal SET for State
		setUserExists(state, payload) {
			state.userExists = payload;
		},

		setUserConditional(state, payload) {
			state.userConditional = payload;
		},

		setUserRisk(state, payload) {
			state.userRisk = payload;
		},
		setUserRiskResult(state, payload) {
			state.userRiskResult = payload;
		},

		setUserCountry(state, payload) {
			state.userCountry = payload;
		},

		setApiResponse(state, payload) {
			state.apiResponse = payload;
		},

		// Normal SET for Maps
		setUserQuestion(state, payload) {
			state.userQuestions.set(payload.id, payload.question);
		},
		setUserIntroQuestion(state, payload) {
			state.userIntroQuestions.set(payload.id, payload.introQuestion);
		},
		setUserSection(state, payload) {
			state.userSections.set(payload.id, payload.section);
		},
		setUserSolution(state, payload) {
			state.userSolutions.set(payload.id, payload.solution);
		},

		// Reset State
		// Resets anything that would be set from the setupUser Action
		// Doesn't do a full-reset as it doesn't need to
		resetState(state) {
			state.userExists = false;

			state.userCountry = undefined;
			state.userQuestions = new Map();
			state.userSections = new Map();
		}
	},
	actions: {
		async setupUser({ dispatch, commit }) {
			commit("resetState");
			await dispatch("setupUserQuestions");
			await dispatch("setupUserSections");
			await dispatch("setupUserCountry");
			commit("setUserExists", true);
		},

		async setupUserQuestions({ dispatch, commit, getters, rootGetters }) {
			// Get from store
			let questions = rootGetters["prismicWizardTool/getPrismicQuestions"];

			// Get from API
			if (!questions.size > 0) {
				const questionIds = await dispatch(
					"prismicWizardTool/fetchPrismicQuestions",
					null,
					{ root: true }
				);

				// Check the QuestionIDs
				if (questionIds.length > 0)
					questions = rootGetters["prismicWizardTool/getPrismicQuestions"];
			}

			// Check and Setup Question
			if (questions.size > 0)
				questions.forEach(question => dispatch("parseQuestion", question));
		},
		parseQuestion({ commit, getters }, document) {
			if (document && document.data.body.length > 0) {
				// Storage for Conditional Results
				let userQuestion = {
					coolingNeed: false,
					socialEco: false,
					userPerspective: false
				};
				let userConditional = getters["getUserConditional"];

				// Parse Conditonal
				/* eslint-disable prettier/prettier */
				const questionConditional = {
					coolingNeed: {
						comfort: 	document.data.field_cooling_need_comfort,
						health: 	document.data.field_cooling_need_health,
						nutrition: 	document.data.field_cooling_need_nutrition
					},
					socialEco: {
						aboveMiddleIncome: 	document.data.field_social_eco_above_middle_income,
						lowerMiddleIncome: 	document.data.field_social_eco_lower_middle_income,
						middleIncome: 		document.data.field_social_eco_middle_income,
						ruralPoorIncome: 	document.data.field_social_eco_rural_poor_income,
						urbanPoorIncome: 	document.data.field_social_eco_urban_poor_income
					},
					userPerspective: {
						agriculture: 		document.data.field_user_perspective_agriculture,
						health: 			document.data.field_user_perspective_health,
						localGovernment: 	document.data.field_user_perspective_local_government,

						// TODO -> Fix Spelling Mistake
						nationalGovernment: 
							document.data.field_user_perspective_national_government
								? document.data.field_user_perspective_national_government
								: document.data.field_user_perspective_not_government
					}
				};

				// Check if Question Conditionals match User Conditionals
				//// Cooling Need
				if (
					(questionConditional.coolingNeed.comfort && userConditional.coolingNeed == "comfort")
					||
					(questionConditional.coolingNeed.health && userConditional.coolingNeed == "health")
					||
					(questionConditional.coolingNeed.nutrition && userConditional.coolingNeed == "nutrition")
					||
					userConditional.coolingNeed == "any"
				)
					userQuestion.coolingNeed = true;

				//// Social Eco
				if (
					(questionConditional.socialEco.aboveMiddleIncome && userConditional.socialEco == "aboveMiddleIncome")
					||
					(questionConditional.socialEco.lowerMiddleIncome && userConditional.socialEco == "lowerMiddleIncome")
					||
					(questionConditional.socialEco.middleIncome && userConditional.socialEco == "middleIncome")
					||
					(questionConditional.socialEco.urbanPoorIncome && userConditional.socialEco == "urbanPoorIncome")
					||
					(questionConditional.socialEco.ruralPoorIncome && userConditional.socialEco == "ruralPoorIncome")
					||
					userConditional.socialEco == "any"
				)
					userQuestion.socialEco = true;

				//// User Perspective
				if (
					(questionConditional.userPerspective.agriculture && userConditional.userPerspective == "agriculture")
					||
					(questionConditional.userPerspective.health && userConditional.userPerspective == "health")
					||
					(questionConditional.userPerspective.localGovernment && userConditional.userPerspective == "localGovernment")
					||
					(questionConditional.userPerspective.nationalGovernment && userConditional.userPerspective == "nationalGovernment")
					||
					userConditional.userPerspective == "any"
				)
					userQuestion.userPerspective = true;

				// Check that it is a User Question
				if (
					userQuestion.coolingNeed &&
					userQuestion.socialEco &&
					userQuestion.userPerspective
				) {
					// Get all of the Question Fields from the Document
					const questionFields = document.data.body[0];

					// Parse into User Question
					userQuestion = {
						id: document.id,
						section: document.data.wizard_section,
						priority: parseInt(questionFields.primary.field_name.slice(2)),

						fieldName: questionFields.primary.field_name.toLowerCase(),
						fieldType: questionFields.slice_type,
						fieldCountryValue: -1,

						fieldDesc: questionFields.primary.field_desc,
						fieldText: questionFields.primary.field_text,
						fieldTooltip: questionFields.primary.field_tooltip,
						fieldTooltipLink: {
							prismic: questionFields.primary.field_tooltip_link,
							text: questionFields.primary.field_tooltip_link_text
						},

						fieldOptionUnsure: questionFields.primary.field_option_unsure
					};

					// Extract Fields based on Field Type
					switch (userQuestion.fieldType) {
						case "dropdown":
							// Extract Options from items repeater
							userQuestion["fieldOptions"] = questionFields.items.map(
								fieldOption => {
									return {
										value: parseFloat(fieldOption.field_option_value),
										label: fieldOption.field_option_label
									};
								}
							);

							// Set defaults
							userQuestion["fieldUnsure"] = false;
							userQuestion["fieldValue"] = userQuestion.fieldOptions[0];
							break;

						case "select":
							// Extract Options from items repeater
							userQuestion["fieldOptions"] = questionFields.items.map(
								fieldOption => {
									return {
										value: parseFloat(fieldOption.field_option_value),
										label: fieldOption.field_option_label
									};
								}
							);

							// Set defaults
							userQuestion["fieldUnsure"] = false;
							userQuestion["fieldValue"] = -1;
							break;

						case "range":
							userQuestion["fieldUnsure"] = false;

							userQuestion["fieldUnits"] =
								questionFields.primary.field_units 
								? questionFields.primary.field_units
								: "%";

							userQuestion["fieldValue"] = -1;

							userQuestion["fieldMax"] =
								!isNaN(questionFields.primary.field_max)
									? parseInt(questionFields.primary.field_max)
									: 100;
							break;

						case "number_input":
							userQuestion["fieldUnsure"] = false;
							break;

						default:
							userQuestion["fieldUnsure"] = false;
							userQuestion["fieldValue"] = -1;
							break;
					}
					/* eslint-enable prettier/prettier */

					// Store User Question
					commit("setUserQuestion", {
						id: userQuestion.fieldName,
						question: userQuestion
					});
				}
			}
		},

		async setupUserIntroQuestions({ dispatch, commit, getters, rootGetters }) {
			// Get from store
			let introQuestions =
				rootGetters["prismicWizardTool/getPrismicIntroQuestions"];

			// Get from API
			if (!introQuestions.size > 0) {
				const introQuestionIds = await dispatch(
					"prismicWizardTool/fetchPrismicIntroQuestions",
					null,
					{ root: true }
				);

				// Check the CountryIDs
				if (introQuestionIds.length > 0)
					introQuestions =
						rootGetters["prismicWizardTool/getPrismicIntroQuestions"];
			}

			// Check, parse, set
			if (introQuestions.size > 0)
				introQuestions.forEach(introQuestion =>
					dispatch("parseIntroQuestion", introQuestion)
				);
		},
		parseIntroQuestion({ commit }, document) {
			if (document && document.data.body.length > 0) {
				// Get all of the Intro Question Fields from the Document
				const introQuestionFields = document.data.body[0];

				// Parse into User Intro Question
				const userIntroQuestion = {
					id: document.id,
					priority: document.data.intro_question_priority,

					fieldName: introQuestionFields.primary.field_name.toLowerCase(),
					fieldType: introQuestionFields.slice_type,

					fieldText: introQuestionFields.primary.field_text,
					fieldDesc: introQuestionFields.primary.field_description,
					fieldTooltip: introQuestionFields.primary.field_tooltip,
					fieldTooltipLink: {
						prismic: introQuestionFields.primary.field_tooltip_link,
						text: introQuestionFields.primary.field_tooltip_link_text
					},

					fieldConditional: introQuestionFields.primary.field_conditional,
					fieldValue: "",
					fieldOptions: []
				};

				// Set Compononent and Field Options
				switch (userIntroQuestion.fieldType) {
					case "intro_question_select_card":
						userIntroQuestion.fieldOptions = introQuestionFields.items.map(
							option => {
								return {
									value: option.field_option_value,
									label: option.field_option_label,
									image: option.field_option_image,
									desc: option.field_option_description
								};
							}
						);
						break;

					default:
						userIntroQuestion.fieldOptions = introQuestionFields.items.map(
							option => {
								return {
									value: option.field_option_value,
									label: option.field_option_label
								};
							}
						);
						break;
				}

				// Store User Intro Question
				commit("setUserIntroQuestion", {
					id: userIntroQuestion.id,
					introQuestion: userIntroQuestion
				});
			}
		},

		async setupUserSections({ dispatch, commit, getters, rootGetters }) {
			// Get from store
			let sections = rootGetters["prismicWizardTool/getPrismicSections"];

			// Get from API
			if (!sections.size > 0) {
				const sectionIds = await dispatch(
					"prismicWizardTool/fetchPrismicSections",
					null,
					{ root: true }
				);

				// Check the SectionIDs
				if (sectionIds.length > 0)
					sections = rootGetters["prismicWizardTool/getPrismicSections"];
			}

			// Check and Setup Section
			if (sections.size > 0)
				sections.forEach(section => dispatch("parseSection", section));
		},
		parseSection({ commit, getters }, document) {
			if (document) {
				let userSection = false;

				// Get all the User Questions for this Section
				// eslint-disable-next-line prettier/prettier
				const sectionQuestions = getters["getUserQuestionsBySectionUid"](document.uid);

				// Check if there are any UserQuestions for this Section
				userSection = sectionQuestions.length > 0;

				// Check that it is a User Section
				if (userSection) {
					// Parse into User Section
					userSection = {
						id: document.id,
						uid: document.uid,
						questions: sectionQuestions.map(question => question.fieldName),

						title: document.data.section_title,
						heading: document.data.section_heading,
						content: document.data.section_content,

						image: document.data.section_image,
						priority: document.data.section_priority,

						meta: {
							pageTitle: document.data.page_title,
							pageDesc: document.data.page_description,
							pageTags: document.data.page_keywords
						}
					};

					// Store User Section
					commit("setUserSection", {
						id: userSection.uid,
						section: userSection
					});
				}
			}
		},

		async setupUserCountry({ dispatch, commit, getters, rootGetters }) {
			// Get from store
			let country = rootGetters["prismicWizardTool/getPrismicCountryByUid"](
				getters["getUserConditional"].country
			);

			// Get from API
			if (!country) {
				const countryIds = await dispatch(
					"prismicWizardTool/fetchPrismicCountries",
					null,
					{ root: true }
				);

				// Check the CountryIDs
				if (countryIds.length > 0)
					country = rootGetters["prismicWizardTool/getPrismicCountryByUid"](
						getters["getUserConditional"].country
					);
			}

			// Check and Setup Country
			if (country) dispatch("parseCountry", country);
		},
		parseCountry({ commit, getters }, document) {
			if (document) {
				// Parse into User Country
				const userCountry = {
					id: document.id,
					uid: document.uid,

					name: document.data.country_name,
					desc: document.data.country_description,
					defaults: {}
				};

				// Country Default HS's
				/* eslint-disable prettier/prettier */
				if(document.data.country_field_hs1 || document.data.country_field_hs1  == 0)
					userCountry.defaults["hs1"] = parseFloat(document.data.country_field_hs1);
				if(document.data.country_field_hs2 || document.data.country_field_hs2 == 0)
					userCountry.defaults["hs2"] = parseFloat(document.data.country_field_hs2);
				if(document.data.country_field_hs3 || document.data.country_field_hs3 == 0)
					userCountry.defaults["hs3"] = parseFloat(document.data.country_field_hs3);
				if(document.data.country_field_hs9 || document.data.country_field_hs9 == 0)
					userCountry.defaults["hs9"] = parseFloat(document.data.country_field_hs9);

				// Country Default FN's
				if(document.data.country_field_fn1 || document.data.country_field_fn1 == 0)
					userCountry.defaults["fn1"] = parseFloat(document.data.country_field_fn1);
				if(document.data.country_field_fn2 || document.data.country_field_fn2 == 0)
					userCountry.defaults["fn2"] = parseFloat(document.data.country_field_fn2);
				if(document.data.country_field_fn3 || document.data.country_field_fn3 == 0)
					userCountry.defaults["fn3"] = parseFloat(document.data.country_field_fn3);
				if(document.data.country_field_fn4 || document.data.country_field_fn4 == 0)
					userCountry.defaults["fn4"] = parseFloat(document.data.country_field_fn4);
				if(document.data.country_field_fn5 || document.data.country_field_fn5 == 0)
					userCountry.defaults["fn5"] = parseFloat(document.data.country_field_fn5);
				if(document.data.country_field_fn6 || document.data.country_field_fn6 == 0)
					userCountry.defaults["fn6"] = parseFloat(document.data.country_field_fn6);
				if(document.data.country_field_fn7 || document.data.country_field_fn7 == 0)
					userCountry.defaults["fn7"] = parseFloat(document.data.country_field_fn7);
				if(document.data.country_field_fn9 || document.data.country_field_fn9 == 0)
					userCountry.defaults["fn9"] = parseFloat(document.data.country_field_fn9);
				if(document.data.country_field_fn10 || document.data.country_field_fn10 == 0)
					userCountry.defaults["fn10"] = parseFloat(document.data.country_field_fn10);
				if(document.data.country_field_fn11 || document.data.country_field_fn11 == 0)
					userCountry.defaults["fn11"] = parseFloat(document.data.country_field_fn11);
				if(document.data.country_field_fn12 || document.data.country_field_fn12 == 0)
					userCountry.defaults["fn12"] = parseFloat(document.data.country_field_fn12);
				if(document.data.country_field_fn13 || document.data.country_field_fn13 == 0)
					userCountry.defaults["fn13"] = parseFloat(document.data.country_field_fn13);
				if(document.data.country_field_fn14 || document.data.country_field_fn14 == 0)
					userCountry.defaults["fn14"] = parseFloat(document.data.country_field_fn14);
				if(document.data.country_field_fn16 || document.data.country_field_fn16 == 0)
					userCountry.defaults["fn16"] = parseFloat(document.data.country_field_fn16);
				if(document.data.country_field_fn18 || document.data.country_field_fn18 == 0)
					userCountry.defaults["fn18"] = parseFloat(document.data.country_field_fn18);

				// Default HC's
				if(document.data.country_field_hc1 || document.data.country_field_hc1 == 0)
					userCountry.defaults["hc1"] = parseFloat(document.data.country_field_hc1);
				if(document.data.country_field_hc2 || document.data.country_field_hc2 ==0)
					userCountry.defaults["hc2"] = parseFloat(document.data.country_field_hc2);

				// Apply Defaults
				getters["getUserQuestionsByFieldNames"](Object.keys(userCountry.defaults))
					.forEach(question => {
						question.fieldCountryValue = 
							Math.round(userCountry.defaults[question.fieldName]);

						question.fieldValue = 
							Math.round(userCountry.defaults[question.fieldName])
					});
				/* eslint-enable prettier/prettier */

				// Store User Country
				commit("setUserCountry", userCountry);
			}
		},

		async setupUserSolutions({ dispatch, commit, getters, rootGetters }) {
			// Get from store
			let solutions = rootGetters["prismicWizardTool/getPrismicSolutions"];

			// Get from API
			if (!solutions.size > 0) {
				const solutionIds = await dispatch(
					"prismicWizardTool/fetchPrismicSolutions",
					null,
					{ root: true }
				);

				// Check the SectionIDs
				if (solutionIds.length > 0)
					solutions = rootGetters["prismicWizardTool/getPrismicSolutions"];
			}

			// Check and Setup Solutions
			if (solutions.size > 0)
				solutions.forEach(solution => dispatch("parseSolution", solution));
		},
		parseSolution({ commit, getters }, document) {
			if (document) {
				let userRiskResult = getters["getUserRiskResult"];

				// Parse Risk
				const solutionRisk = {
					high: document.data.field_risk_high,
					medium: document.data.field_risk_medium,
					low: document.data.field_risk_low
				};

				// Risk Check
				/* eslint-disable prettier/prettier */
				if (
					(solutionRisk.high && userRiskResult == "high")
					||
					(solutionRisk.medium && userRiskResult == "medium")
					||
					(solutionRisk.low && userRiskResult == "low")
				) {
					// Storage for Conditional Results
					let userSolution = {
						coolingNeed: false,
						userPerspective: false
					};
					let userConditional = getters["getUserConditional"];

					// Parse Conditonal
					const solutionConditional = {
						coolingNeed: {
							comfort: 	document.data.field_cooling_need_comfort,
							health: 	document.data.field_cooling_need_health,
							nutrition: 	document.data.field_cooling_need_nutrition
						},
						userPerspective: {
							agriculture: 		document.data.field_user_perspective_agriculture,
							health: 			document.data.field_user_perspective_health,
							localGovernment: 	document.data.field_user_perspective_local_government,

							// TODO -> Fix Spelling Mistake
							nationalGovernment:
								document.data.field_user_perspective_national_government
									? document.data.field_user_perspective_national_government
									: document.data.field_user_perspective_not_government
						}
					};

					// Check if Solution Conditionals match User Conditionals

					//// Cooling Need
					if (
						(solutionConditional.coolingNeed.comfort && userConditional.coolingNeed == "comfort")
						||
						(solutionConditional.coolingNeed.health && userConditional.coolingNeed == "health")
						||
						(solutionConditional.coolingNeed.nutrition && userConditional.coolingNeed == "nutrition")
						||
						userConditional.coolingNeed == "any"
					)
						userSolution.coolingNeed = true;

					//// User Perspective
					if (
						(solutionConditional.userPerspective.agriculture && userConditional.userPerspective == "agriculture")
						||
						(solutionConditional.userPerspective.health && userConditional.userPerspective == "health")
						||
						(solutionConditional.userPerspective.localGovernment && userConditional.userPerspective == "localGovernment")
						||
						(solutionConditional.userPerspective.nationalGovernment && userConditional.userPerspective == "nationalGovernment")
						||
						userConditional.userPerspective == "any"
					)
						userSolution.userPerspective = true;

					// Check that it is a User Solution
					if (userSolution.coolingNeed && userSolution.userPerspective) {
						userSolution = {
							id: document.id,

							title: document.data.solution_title,
							desc: document.data.solution_description,
							link: {
								prismic: document.data.solution_link,

								// TODO -> Fix Spelling Mistake
								text: 
									document.data.solution_link_text
										? document.data.solution_link_text
										: document.data.soultion_link_text
							},

							approach: {
								improve: 	document.data.field_approach_improve,
								leverage: 	document.data.field_approach_leverage,
								protect: 	document.data.field_approach_protect,
								reduce: 	document.data.field_approach_reduce,
								shift: 		document.data.field_approach_shift
							},
							pillar: {
								primary: 	document.data.field_pillar,
								financial: 	document.data.field_pillar_financial,
								policy: 	document.data.field_pillar_policy,
								services: 	document.data.field_pillar_services,
								tech: 		document.data.field_pillar_tech
							},
							characteristics: {
								capitalCost: 	document.data.field_characteristics_capital_cost,
								energySaving: 	document.data.field_characteristics_energy_saving,
								energySource: 	document.data.field_characteristics_energy_source,
								integrateEase: 	document.data.field_characteristics_integrate_ease,
								operatingCost: 	document.data.field_characteristics_operating_cost,
								refGwp: 		document.data.field_characteristics_ref_gwp
							}
						};

						// Override Target
						userSolution.link.prismic["target"] = "_blank";
						/* eslint-enable prettier/prettier */

						// Store User Solution
						commit("setUserSolution", {
							id: userSolution.id,
							solution: userSolution
						});
					}
				}
			}
		}
	}
};

export default wizardToolUser;
