<template>
  <div class="404">
    <div class="jumbotron jumbotron-fluid bg-light mb-0">
      <div class="d-flex align-items-center justify-content-center h-100">
        <div class="container">
          <div class="col-8 mx-auto text-white text-center">
            <h1 class="display-3 font-weight-semibold">404</h1>
            <p class="w-75-percent mx-auto mt-4 lead">
              Oops! We can't seem to find the page you requested.
            </p>
            <b-button
              variant="primary"
              class="font-weight-bold"
              size="lg"
              :to="{ name: 'home' }"
            >
              Home
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "not-found"
};
</script>
