<template>
	<div>
		<!--- Content --->
		<WizardQuestionContent :question="question" />

		<!--- Select Pills --->
		<div 
			class="d-flex flex-wrap justify-content-center"
			:class="question.fieldUnsure ? 'opacity-50' : ''"
		>
			<button 
				v-for="(option, index) in question.fieldOptions"
				:key="'option-' + index + '-' + Date.now()"
				class="btn d-flex rounded-pill py-3 px-4 m-2 align-items-center"
				:class="question.fieldValue == option.value ? 'bg-primary text-white' : 'bg-lightest text-dark'"
				@click="question.fieldValue = option.value"
				:disabled="question.fieldUnsure"
			>
				{{ option.label }}
			</button>
		</div>

		<!--- Option Unsure --->
		<WizardQuestionUnsure v-if="!disableUnsure" :question="question" />
	</div>
</template>
<script>
import WizardQuestionContent from '../../components/wizard/WizardQuestionContent.vue';
import WizardQuestionUnsure from '../../components/wizard/WizardQuestionUnsure.vue';

export default {
	name: "WizardQuestionRange",
	props: ["question", "disableUnsure"],
	components: {
		WizardQuestionContent,
		WizardQuestionUnsure
	}
};
</script>
