<template>
  <div class="text-header" :style="getBackground">
    <div class="container-design pb-3 px-4">
      <div class="text-center w-md-75-percent mx-auto">
        <h3 class="h1 mb-4">
          {{ this.header.heading }}
        </h3>
        <prismic-rich-text
          class="lead text-primary"
          v-if="this.header.content"
          :field="this.header.content"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TextHeader",
  props: ["header", "backgroundImage", "backgroundGradient"],
  computed: {
    getBackground() {
      return (
        "background-image: url(" +
        this.backgroundImage +
        ")," +
        this.backgroundGradient +
        ";"
      );
    }
  }
};
</script>
<style lang="scss">
.text-header {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
  background-color: transparent;
}
</style>
