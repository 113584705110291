<template>
	<div>
		<div class="w-100 border-bottom shadow-sm">
			<div class="row">
				<div
					class="col-6 col-md h-4 d-flex align-items-center"
					v-for="(section, index) in getUserSections(false)"
					:key="'section-' + index + '-' + Date.now()"
					:class="(index > 0) ? 'border-left border-top border-top-md-0' : ''"
				>
					<p class="text-sm mb-0 px-4">
						<span
							class="text-uppercase font-weight-bold"
							:class="(section.uid == currentSection) ? 'text-primary' : 'text-gray'"
						>
							{{ (index + 1) + ". " + section.heading }}
						</span>
						<span
							v-if="section.uid == currentSection"
							class="text-light text-lowercase pl-1"
						>
							{{ section.questions.length + " questions" }}
						</span>
					</p>
				</div>
			</div>
		</div>
		<div class="d-flex d-md-none flex-column align-items-center justify-content-center w-100 h-4 text-center bg-yellow">
			The Cooling For All Solutions Tool is in Beta
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	name: "WizardNav",
	props: ["currentSection"],
	computed: {
		...mapGetters({
			getUserSections: "wizardToolUser/getUserSections"
		})
	},
};
</script>
