<template>
	<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
		<circle cx="25" cy="25" r="25" fill="#27ade4"/>
		<path fill="#fff" d="M39.64 18.68c.19 0 .39 0 .39.27 0 1.71 0 3.43-.14 5.22-.5-.49-1-1-1.49-1.48s-.56-.41-.95 0c-3.38 3.35-6.79 6.67-10.18 10-.38.38-.59.41-1 0-1.45-1.49-3-2.91-4.42-4.39-.35-.35-.54-.38-.91 0-2.88 2.87-5.8 5.71-8.68 8.58-.41.41-.62.39-1 0-1-1-1-1 0-2 3.23-3.18 6.48-6.35 9.7-9.55.35-.34.53-.33.86 0 1.51 1.51 3.05 3 4.56 4.51.31.31.47.28.77 0q4.39-4.37 8.84-8.69c.37-.35.32-.55 0-.85-.54-.47-1.71-1.64-1.71-1.64h5.37m-11.1-2.38a1.72 1.72 0 01-1.76-1.77 1.74 1.74 0 011.77-1.71 1.74 1.74 0 110 3.48m-7.15 0a1.74 1.74 0 110-3.48 1.73 1.73 0 011.76 1.71 1.72 1.72 0 01-1.76 1.77M16 14.5a1.73 1.73 0 01-1.75 1.78A1.74 1.74 0 1116 14.5"/>
		<path fill="#fff" d="M10.53 24a.69.69 0 00.86-.58c.08-.31.15-.63.22-.95l.75-3.24a9.1 9.1 0 01-.19 3.45c-.25 1.06-.44 2.14-.67 3.21-.09.39-.1.69.45.6s.53.12.52.52v4.52l.32-.32 1.13-1.11v-3s-.11-.62.37-.6.32.58.32.58v2.35L16 28.06V27s.15-.52.49-.47c.54.08.56-.18.47-.6-.2-.86-.36-1.74-.55-2.61a11.21 11.21 0 01-.35-4.16l.18.7c.27 1.17.55 2.33.81 3.49.09.4.27.7.71.7s.69-.29.76-.72a2 2 0 01.05-.24c.3-1.27.59-2.54.89-3.81h.12v5.22l.49-.5a1.86 1.86 0 011.3-.6 1.83 1.83 0 011.3.62l.46.45v-5.24a1 1 0 01.23.52c.27 1.16.55 2.32.8 3.49.1.44.28.78.78.78s.66-.36.76-.79c.28-1.26.58-2.51.87-3.76h.13V28l1.39-1.38v-1.78c0-.15 0-.48.35-.49s.38.2.37.47v1.07c.5-.49 1-1 1.46-1.43v-5h.13l1 4c.4-.38.78-.77 1.16-1.14a.7.7 0 000-.32c-.3-1.26-.58-2.53-.86-3.79a1.14 1.14 0 00-.47-.69 4.82 4.82 0 00-5.36 0 1.23 1.23 0 00-.52.85c-.09.46-.2.92-.31 1.38a5.08 5.08 0 01-.48-1.44 1.17 1.17 0 00-.61-.86 4.78 4.78 0 00-5.12 0 1.22 1.22 0 00-.61.86 7.39 7.39 0 01-.44 1.58c-.15-.61-.29-1.13-.4-1.67a1.08 1.08 0 00-.46-.68 4.83 4.83 0 00-5.42 0 .86.86 0 00-.38.46c-.39 1.74-.78 3.47-1.15 5.22a.63.63 0 00.54.81"/>
	</svg>
</template>
<script>
export default {
	name: "IconsWizardLeverage"
};
</script>
