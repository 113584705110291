var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('WizardQuestionContent',{attrs:{"question":_vm.question}}),_c('div',{staticClass:"d-flex w-lg-25-percent mt-4 mb-3"},[_c('b-form-input',{staticClass:"p-3 text-center w-100",class:_vm.question.fieldUnsure ? 'opacity-50' : '',staticStyle:{"font-size":"1.5rem"},attrs:{"type":"number","disabled":_vm.question.fieldUnsure,"placeholder":"Enter value"},model:{value:(_vm.question.fieldValue),callback:function ($$v) {_vm.$set(_vm.question, "fieldValue", $$v)},expression:"question.fieldValue"}})],1),(
			(
				(
					_vm.question.fieldCountryValue ||
					_vm.question.fieldCountryValue == 0
				) && 
				_vm.question.fieldCountryValue >= 0
			) &&
			_vm.question.fieldValue == _vm.question.fieldCountryValue
		)?_c('WizardQuestionCountry'):_c('WizardQuestionUnsure',{attrs:{"question":_vm.question}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }