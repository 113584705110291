<template>
	<div
		class="wizard-section transition-250"
		:class="loading.section ? 'opacity-0' : ''"
	>
		<Nav :height="height" />

		<div class="container-lg">
			<div
				v-if="!loading.questions && !loading.section"
				class="min-h-screen mx-xl-10 pb-6"
				:style="'padding-top: calc(6rem + ' + height + ');'"
			>
				<!--- White Wizard Area --->
				<div v-if="getUserExists" class="w-100 min-h-100 bg-white rounded-xl shadow">
					<!--- Wizard Head --->
					<WizardNav :currentSection="$route.params.section_uid" />

					<!--- Wizard Body --->
					<div class="pb-5 px-2">
						<FeatureLeftTextSection
							v-if="maxDisplayQuestions == 0"
							:section="section"
							:button="{
								text: 'View Questions',
								action: increaseDisplayQuestions
							}"
						/>

						<div v-else class="pt-5 px-2 px-lg-0 w-lg-75-percent mx-auto">
							<!--- Question Loop --->
							<div
								v-for="(question, index) in displayedQuestions"
								:key="'question-' + index + '-' + Date.now()"
							>
								<hr v-if="index > 0" class="border-dashed my-5" />
								<p class="text-sm text-primary text-uppercase font-weight-bold mb-2">
									Question {{(index + 1) + "/" + questions.length  }}
								</p>
								<component
									:is="question.component"
									:question="question"
								/>
							</div>

							<!--- More Questions to Load Section --->
							<div v-if="questions.length > maxDisplayQuestions">
								<hr class="border-dashed my-5" />
								<button
									class="btn btn-yellow text-uppercase d-block mx-auto"
									@click="increaseDisplayQuestions"
								>
									<div class="d-flex align-items-center justify-content-between pl-2">
										<p class="mr-3 mb-0 text-sm font-weight-bold text-darker">
											More Questions
										</p>
										<div class="icon-large text-darker">
											<b-icon icon="arrow-right-short" aria-hidden="true"></b-icon>
										</div>
									</div>
								</button>
							</div>

							<!--- Next Section --->
							<div v-else-if="nextSections.length">
								<hr class="border-dashed my-5" />
								<button
									class="btn btn-yellow text-uppercase d-block mx-auto"
									@click="endWizardSection"
									:disabled="loading.route"
								>
									<div class="d-flex align-items-center justify-content-between pl-2">
										<div class="mr-3 font-80 text-darker">
											Next Section
										</div>
										<div class="icon-large text-darker">
											<b-icon icon="arrow-right-short" aria-hidden="true"></b-icon>
										</div>
									</div>
								</button>
							</div>

							<!--- End Wizard --->
							<div v-else>
								<hr class="border-dashed my-5" />
								<button
									class="btn btn-yellow text-uppercase d-block mx-auto"
									@click="endWizard"
									:disabled="loading.route"
								>
									<div class="d-flex align-items-center justify-content-between pl-2">
										<p class="mr-3 mb-0 text-sm font-weight-bold text-darker">
											Generate Report
										</p>
										<div class="icon-large text-darker">
											<b-icon icon="arrow-right-short" aria-hidden="true"></b-icon>
										</div>
									</div>
								</button>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Nav from "@/components/Nav.vue";
import WizardNav from "@/components/wizard/WizardNav.vue";
import FeatureLeftTextSection from "@/components/FeatureLeftTextSection.vue";

import { mapGetters, mapActions } from "vuex";

import WizardQuestionRange from '../../components/wizard/WizardQuestionRange.vue';
import WizardQuestionDropdown from '../../components/wizard/WizardQuestionDropdown.vue';
import WizardQuestionSelect from '../../components/wizard/WizardQuestionSelect.vue';
import WizardQuestionNumberInput from '../../components/wizard/WizardQuestionNumberInput.vue';

export default {
	name: "wizard-section",
	components: {
		Nav,
		WizardNav,
		FeatureLeftTextSection,

		WizardQuestionRange,
		WizardQuestionDropdown,
		WizardQuestionSelect
	},
	metaInfo() {
		return {
			title: this.page.pageTitle
		}
	},
	mounted() {
		this.getContent();

		let multiplier = 0.5;

		if (this.$getMediaBreakpoints.md)
			multiplier = 1.1;

		if (this.$getMediaBreakpoints.lg)
			multiplier = 0.65;

		this.height = 200 * multiplier + "px";
	},
	data() {
		const data = {
			height: "130px",
			displayedQuestions: [],
			maxDisplayQuestions: 0,
			maxDisplayIncrease: 1,
			nextSections: [],
			section: {},
			questions: [],
			fields: {
				// navButton: {
				// 	download: null,
				// 	downloadText: null
				// },
				section: {
					priority: null,
					title: null,
					heading: null,
					content: null,
					image: null
				}
			},
			page: {
				pageTitle: null,
				pageDesc: null,
				pageTags: null
			},
			loading: {
				route: false,

				// Set Loading
				section: true,
				questions: true
			}
		}

		return data;

		return {
			init: data,
			data: [
				...data
			]
		};
	},
	computed: {
		...mapGetters({
			getUserExists: "wizardToolUser/getUserExists",
			getUserSection: "wizardToolUser/getUserSection",
			getUserSections: "wizardToolUser/getUserSections",
			getUserQuestions: "wizardToolUser/getUserQuestionsBySectionUid"
		})
	},
	methods: {
		...mapActions({
			setupUser: "wizardToolUser/setupUser"
		}),

		async getContent() {
			// Set Loading
			this.loading.section = true;
			this.loading.questions = true;

			// Check User
			if(this.getUserExists){
				// Get from User
				var userSection = this.getUserSection(this.$route.params.section_uid);

				// Check Section
				if(userSection) {
					// Set Data
					this.page = userSection.meta;
					this.section = userSection;

					// Stop Loading Section
					this.loading.section = false;

					// After Content
					this.afterContent();
				}
				else
					this.$router.push({ name: 'wizard' });
			}
			else
				this.$router.push({ name: 'wizard' });
		},

		afterContent() {
			// Get Questions
			this.questions = this.getUserQuestions(this.$route.params.section_uid);

			// Stop Loading Questions
			this.loading.questions = false;

			// Get Next Sections
			this.nextSections = this.getUserSections(false).filter((section) => section.priority > this.section.priority);
		},

		increaseDisplayQuestions() {
			//this.maxDisplayQuestions += this.maxDisplayIncrease;
			this.maxDisplayQuestions = this.questions.length;

			for(let index = 0; index < this.questions.length; index++) {
				// Check that we are under the display cap
				if(index >= this.maxDisplayQuestions) return;

				// Only push new questions
				if(index >= this.displayedQuestions.length) {
					// Set Compononent
					switch(this.questions[index].fieldType){
						case 'dropdown':
							this.questions[index].component = WizardQuestionDropdown;
							break;

						case 'select':
							this.questions[index].component = WizardQuestionSelect;
							break;

						case 'range':
							this.questions[index].component = WizardQuestionRange;
							break;

						default:
							this.questions[index].component = WizardQuestionNumberInput;
							break;
					}

					// Push new question
					this.displayedQuestions.push(this.questions[index]);
				}
			}
		},

		resetDataToDefault() {
			this.height = "130px";
			this.displayedQuestions = [];
			this.maxDisplayQuestions = 0;
			this.maxDisplayIncrease = 1;
			this.nextSections = [];
			this.section = {};
			this.questions = [];
			this.fields = {
				// navButton: {
				// 	download: null,
				// 	downloadText: null
				// },
				section: {
					priority: null,
					title: null,
					heading: null,
					content: null,
					image: null
				}
			};
			this.page = {
				pageTitle: null,
				pageDesc: null,
				pageTags: null
			}
		},

		endWizardSection() {
			// Disable Button
			this.loading.route = true;

			// GoTo the Next Section
			this.$router.push(
				{
					name: 'wizard-section',
					params: {
						section_uid: this.nextSections[0].uid
					}
				}
			).then(
				() => {
					// Clear Content
					this.resetDataToDefault()

					// Get Content
					this.getContent();

					// Enable Button
					this.loading.route = false;
				}
			);
		},
		endWizard() {
			// Disable Button
			this.loading.route = true;

			// GoTo the Results
			this.$router.push(
				{
					name: 'wizard-generate'
				}
			).then(
				() => // Enable Button
					this.loading.route = false
			);
		}
	}
};
</script>
<style lang="scss">
.wizard-section {
	background: #d9eef1;
    background-image: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.5) 0%,
		#caeaee 29%,
		#d9eef1 100%
    );
}
</style>