import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const prismicPage = {
	namespaced: true,
	state: {
		prismicPages: new Map()
	},
	getters: {
		getPrismicPages: state => {
			return state.prismicPages.entries();
		},
		getPrismicPageByName: state => prismicPageName => {
			return state.prismicPages.get(prismicPageName);
		},

		// Functionality GETs
		getJSON: state => {
			return JSON.stringify({
				// eslint-disable-next-line prettier/prettier
				"prismicPages": Array.from(state.prismicPages.entries())
			});
		}
	},
	mutations: {
		setPrismicPage(state, payload) {
			state.prismicPages.set(payload.name, payload.document);
		}
	},
	actions: {
		async fetchPrismicPage({ commit }, prismicPageName, prismicPromise = null) {
			// Default Prismic Promise
			if (!prismicPromise)
				prismicPromise = new Promise((resolve, reject) => {
					resolve(
						Vue.prototype.$prismic.client.query(
							Vue.prototype.$prismic.Predicates.at(
								"document.type",
								prismicPageName
							)
						)
					);
				});

			// Default Document Return
			let document = null;

			// Wait for Promise
			await prismicPromise.then(async data => {
				// Get from Normal Data
				if (data.results !== undefined && data.results.length > 0)
					document = data.results[0];
				// Fetch from Single
				else
					document = await Vue.prototype.$prismic.client.getSingle(
						prismicPageName
					);
			});

			// Check and Store new fetch of the Page
			if (document)
				commit("setPrismicPage", {
					name: prismicPageName,
					document: document
				});

			// Return Null or Document Data
			return document;
		}
	}
};

export default prismicPage;
