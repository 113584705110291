<template>
	<div :style="getBackground">
		<div class="container-design overflow-hidden px-4">
			<div class="row pt-5 py-md-5">
				<div
					class="
						col-lg-6 
						d-flex flex-column 
						align-items-center justify-content-center
					"
				>
					<div class="cocoen opacity-0 transition-250">
						<prismic-image
							class="bg-white"
							v-if="this.feature.imageLeft"
							:field="this.feature.imageLeft"
						/>
						<prismic-image
							class="bg-white"
							v-if="this.feature.imageRight"
							:field="this.feature.imageRight"
						/>
					</div>
				</div>
				<div class="col-lg-6 mt-5">
					<p class="text-light text-uppercase m-0 lead">
						{{ this.feature.title }}
					</p>
					<h2 class="mb-3">{{ this.feature.heading }}</h2>
					<prismic-rich-text
						v-if="this.feature.content"
						:field="this.feature.content"
					/>
					<div class="row justify-content-center align-items-center">
						<div
							v-for="(card, index) in this.feature.cards"
							:key="'feature-left-card-' + index"
							class="col-md-6 col-xl-4"
						>
							<div class="d-flex flex-column text-center mx-4">
								<div class="mb-3 d-none d-md-block">
									<prismic-image
										class="image-fluid"
										v-if="card.card_image"
										:field="card.card_image"
									/>
									<picture v-else>
										<source srcset="/img/placeholder.webp" type="image/webp" />
										<source srcset="/img/placeholder.png" type="image/png" />
										<img width="120" src="/img/placeholder.png" />
									</picture>
								</div>
								<h6>{{ card.card_heading }}</h6>
								<prismic-rich-text
									class="font-80 rich-text-links"
									v-if="card.card_content"
									:field="card.card_content"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "FeatureLeft",
	props: ["feature", "backgroundGradient"],
	computed: {
		getBackground() {
			return `background-image: ${this.backgroundGradient};`;
		}
	}
};
</script>
