<template>
	<div>
		<!--- Option Unsure --->
		<b-form-checkbox 
			v-model="question.fieldUnsure"
			value="true"
		>
			<span class="cursor-pointer"> I’m not sure how to answer, skip this question. </span>
		</b-form-checkbox>
	</div>
</template>
<script>
export default {
	name: "WizardQuestionUnsure",
	props: ["question"]
};
</script>