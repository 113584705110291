/* eslint-disable no-console */
import Vue from "vue";
import { EventEmitter } from "events";

class WizardRiskCalculatorService extends EventEmitter {
	constructor() {
		super();
	}

	/**
	* Method to submit the netlify serverless function to the wizard risk calculator
	*/
	sendWizardRiskCalculator(data) {
		const endpoint = `/.netlify/functions/wizard-risk-calculator`;

		return new Promise(
			(resolve, reject) => {
				Vue.axios
					.post(endpoint, data)
					.then(
						response => 
							resolve(response)
					)
					.catch(
						error => 
							resolve(error.response)
					);
			}
		);
	}
}

const wizardRiskCalculatorService = new WizardRiskCalculatorService();

wizardRiskCalculatorService.setMaxListeners(5);

export default wizardRiskCalculatorService;
