<template>
<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
	<circle cx="25" cy="25" r="25" fill="#ed7d31"/>
	<path fill="#fff" d="M35.91 19.82l-.3.3a.63.63 0 00-.06.83 6.79 6.79 0 01-5.45 10.84H17.28l3.62-3.62a.63.63 0 000-.9l-.3-.3a.63.63 0 00-.9 0l-5.22 5.22a.65.65 0 000 .9l5.22 5.22a.63.63 0 00.9 0l.3-.3a.63.63 0 000-.9l-3.62-3.62H30.1a8.49 8.49 0 006.77-13.61.66.66 0 00-.51-.25.63.63 0 00-.45.19m-6.51-8.13l-.3.3a.63.63 0 000 .9l3.62 3.62H19.91a8.49 8.49 0 00-6.78 13.61.64.64 0 001 .06l.3-.3a.63.63 0 00.06-.83 6.79 6.79 0 015.46-10.84h12.77l-3.62 3.62a.63.63 0 000 .9l.3.3a.63.63 0 00.9 0l5.22-5.22a.63.63 0 000-.9l-5.22-5.22a.63.63 0 00-.45-.19.6.6 0 00-.45.19" />
</svg>
</template>
<script>
export default {
	name: "IconsWizardShift"
};
</script>
