<template>
	<div>
		<!--- Modal Close --->
		<div
			v-if="data.showApproachInfoModal"
			class="position-fixed z-10 w-100 h-100 top-0 left-0"
			@click="data.showApproachInfoModal = false"
		></div>

		<!--- Pillars --->
		<div
			v-for="(pillarSolutions, pillarName) in data.pillars"
			:key="'pillar-' + pillarName + '-' + Date.now()"
		>
			<div
				v-if="pillarSolutions.length > 0"
				class="position-relative border border-gray mb-4 mb-lg-5"
			>
				<!--- Pillar Headings --->
				<div
					class="w-100 d-flex p-3 border-bottom border-gray"
					style="background: #d9eef1;"
				>
					<div
						v-if="pillarName == 'financial'"
						class="d-flex align-items-center"
					>
						<IconWizardFinancial class="w-3 h-3" />
						<h4 class="px-4 mb-0">
							Financial Solutions
						</h4>
					</div>
					<div
						v-else-if="pillarName == 'policy'"
						class="d-flex align-items-center"
					>
						<IconWizardPolicy class="w-3 h-3" />
						<h4 class="px-4 mb-0">
							Policy Solutions
						</h4>
					</div>
					<div
						v-else-if="pillarName == 'services'"
						class="d-flex align-items-center"
					>
						<IconWizardService class="w-3 h-3" />
						<h4 class="px-4 mb-0">
							Services
						</h4>
					</div>
					<div
						v-else-if="pillarName == 'tech'"
						class="d-flex align-items-center"
					>
						<IconWizardTechnology class="w-3 h-3" />
						<h4 class="px-4 mb-0">
							Technology Solutions
						</h4>
					</div>
				</div>
				<div
					class="d-flex bg-lightest font-weight-bold border-bottom border-gray"
				>
					<p class="w-100 w-md-75-percent d-block mb-0 p-3">
						Solution
					</p>
					<div
						class="mb-0 p-3 d-none d-md-flex cursor-pointer hover:underline align-items-center"
						@click="data.showApproachInfoModal = !data.showApproachInfoModal"
					>
						<p class="mb-0 mr-2">
							Approach
						</p>

						<!--- Approach Info --->
						<b-icon
							icon="x-circle"
							aria-hidden="true"
							v-if="data.showApproachInfoModal"
						></b-icon>
						<b-icon icon="question-circle" aria-hidden="true" v-else></b-icon>
					</div>
				</div>

				<!--- Approach Info Modal --->
				<div
					v-if="data.showApproachInfoModal"
					class="w-100 w-lg-50-percent p-3 right-0 position-absolute bg-white shadow z-10"
				>
					<h3>
						{{ approachInfo.title }}
					</h3>
					<prismic-rich-text
						v-if="approachInfo.desc"
						:field="approachInfo.desc"
					/>
				</div>

				<!--- Pillar Solutions --->
				<div
					v-for="(solution, solutionIndex) in pillarSolutions"
					:key="'solution-' + solutionIndex + '-' + Date.now()"
				>
					<!--- Top Border --->
					<hr v-if="solutionIndex > 0" class="border-gray" />

					<!--- Solution --->
					<div class="d-flex">
						<!--- Content --->
						<div class="w-100 w-md-75-percent p-3">
							<h6 class="font-weight-bold">
								{{ solution.title }}
							</h6>
							<prismic-rich-text v-if="solution.desc" :field="solution.desc" />
							<prismic-link class="no-print" :field="solution.link.prismic">
								{{ solution.link.text }}
							</prismic-link>
							<prismic-link class="only-print" :field="solution.link.prismic">
								{{ solution.link.prismic.url }}
							</prismic-link>
						</div>

						<!--- Tags ---->
						<div class="p-3 d-none d-md-block" style="max-width: 25%;">
							<WizardApproach
								wizardIcon="improve"
								class="p-lg-2"
								v-if="solution.approach.improve"
								:showApproachName="true"
							/>
							<WizardApproach
								wizardIcon="leverage"
								class="p-lg-2"
								v-if="solution.approach.leverage"
								:showApproachName="true"
							/>
							<WizardApproach
								wizardIcon="protect"
								class="p-lg-2"
								v-if="solution.approach.protect"
								:showApproachName="true"
							/>
							<WizardApproach
								wizardIcon="reduce"
								class="p-lg-2"
								v-if="solution.approach.reduce"
								:showApproachName="true"
							/>
							<WizardApproach
								wizardIcon="shift"
								class="p-lg-2"
								v-if="solution.approach.shift"
								:showApproachName="true"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";

import IconWizardFinancial from "@/components/icons/wizard/Financial.vue";
import IconWizardPolicy from "@/components/icons/wizard/Policy.vue";
import IconWizardService from "@/components/icons/wizard/Service.vue";
import IconWizardTechnology from "@/components/icons/wizard/Technology.vue";

import WizardApproach from "@/components/wizard/WizardApproach.vue";

export default {
	name: "WizardResultSolutions",
	props: ["approachInfo", "characteristicsRequirements", "solutionsCountElement"],
	components: {
		IconWizardFinancial,
		IconWizardPolicy,
		IconWizardService,
		IconWizardTechnology,

		WizardApproach
	},
	data() {
		return {
			init: {
				pillars: {
					tech: [],
					services: [],
					policy: [],
					financial: []
				},
				approachPriorities: {
					protect: 5,
					reduce: 4,
					shift: 3,
					improve: 2,
					leverage: 1
				},
				showApproachInfoModal: false
			},
			data: {}
		};
	},
	computed: {
		...mapGetters({
			getUserSolutions: "wizardToolUser/getUserSolutions",
			getUserRiskResult: "wizardToolUser/getUserRiskResult"
		})
	},
	mounted() {
		this.data = this.init;
		this.getContent();
	},
	watch: {
		characteristicsRequirements: function() {
			this.getContent();
		}
	},
	methods: {
		getContent() {
			/* eslint-disable prettier/prettier */
			// Filter Content
			this.data.pillars.financial = 
				this.getUserSolutions.filter(
					solution => 
						solution.pillar.primary
							? solution.pillar.primary == "Financial"
							: solution.pillar.financial
			);

			this.data.pillars.policy = 
				this.getUserSolutions.filter(
					solution => 
						solution.pillar.primary 
							? solution.pillar.primary == "Policy"
							: solution.pillar.policy
			);

			this.data.pillars.services = 
				this.getUserSolutions.filter(
					solution => 
						solution.pillar.primary 
							? solution.pillar.primary == "Services"
							: solution.pillar.services
			);

			this.data.pillars.tech = 
				this.getUserSolutions.filter(
					solution => 
						solution.pillar.primary 
							? solution.pillar.primary == "Tech"
							: solution.pillar.tech
			);

			/* eslint-enable prettier/prettier */

			// Might be able to move most of the below into the store?

			// Set Approach Priorities
			switch (this.getUserRiskResult) {
				case "high":
					this.data.approachPriorities.protect = 5;
					this.data.approachPriorities.reduce = 4;
					this.data.approachPriorities.shift = 3;
					this.data.approachPriorities.improve = 2;
					this.data.approachPriorities.leverage = 1;
					break;

				case "medium":
					this.data.approachPriorities.reduce = 5;
					this.data.approachPriorities.protect = 4;
					this.data.approachPriorities.shift = 3;
					this.data.approachPriorities.improve = 2;
					this.data.approachPriorities.leverage = 1;
					break;

				case "low":
					this.data.approachPriorities.reduce = 5;
					this.data.approachPriorities.shift = 4;
					this.data.approachPriorities.improve = 3;
					this.data.approachPriorities.leverage = 2;
					this.data.approachPriorities.protect = 1;
					break;

				default:
					break;
			}

			/* eslint-disable prettier/prettier */

			// Solutions Counter
			let solutionsCount = 0;

			// Loop over Pillars
			for (const pillar of Object.keys(this.data.pillars)) {
				if(this.characteristicsRequirements.length > 0) {
					this.data.pillars[pillar] = this.filterPillarSolutionsByCharacteristics(this.data.pillars[pillar]);
					this.data.pillars[pillar] = this.sortPillarSolutionsByCharacteristics(this.data.pillars[pillar]);
				}
				else
					this.data.pillars[pillar] = this.sortPillarSolutionsByApproach(this.data.pillars[pillar]);

				solutionsCount += this.data.pillars[pillar].length;
			}

			// Set Counter
			this.setSolutionsCount(solutionsCount);
		},

		// Total Characteristic Score Calc Function 
		getCalculateTotalCharacteristicScore(solution) {
			if(solution.totalScore)
				return solution.totalScore;

			let solutionTotal = 0;

			for(const characteristic in solution.characteristics) {
				const characteristicTotal = parseInt(solution.characteristics[characteristic]);
				if(characteristicTotal && !isNaN(characteristicTotal))
					solutionTotal += characteristicTotal;
			}

			solution.totalScore = solutionTotal;
			return solutionTotal;
		},

		filterPillarSolutionsByCharacteristics(pillarSolutions) {
			// Filter Remove all results less then the min requirement
			this.characteristicsRequirements.forEach(characteristicsRequirement => 
				{
					pillarSolutions = pillarSolutions.filter(
						solution => solution.characteristics[characteristicsRequirement.characteristic] >= characteristicsRequirement.minimum
					)
				}
			);

			return pillarSolutions;
		},

		sortPillarSolutionsByCharacteristics(pillarSolutions) {
			// Sort based on Total Score from OBJ
			pillarSolutions = pillarSolutions.sort(
				(solutionA, solutionB) => {
					// These funcs store the total score on the OBJ so that it doesn't calc more then once.
					// That way it only needs to loop once via the sort method rather then doing two loops?
					// Making it a little bit faster each iteration
					let totalScoreA = this.getCalculateTotalCharacteristicScore(solutionA);
					let totalScoreB = this.getCalculateTotalCharacteristicScore(solutionB);

					// Total Bigger
					if(totalScoreA > totalScoreB)
						return -1;

					// Total Same
					if(totalScoreA == totalScoreB)
						return 0;

					// Total Smaller
					if(totalScoreA < totalScoreB)
						return 1;
				}
			);

			return pillarSolutions;
		},

		sortPillarSolutionsByApproach(pillarSolutions) {
			// Assign Priority for each solution
			pillarSolutions.forEach(pillarSolution => {
				// Loop Persistent Var
				let priority = 0;

				// Get highest priority in the Approach Priorities
				for (
					const [approach, approachValue] of 
					Object.entries(this.data.approachPriorities)
				)
					if (
						approachValue > priority && 
						pillarSolution.approach[approach]
					)
						priority = approachValue;

				// Set Priority to the Object
				pillarSolution["priority"] = priority;
			});

			/* eslint-enable prettier/prettier */

			// Sort Content (Reserved)
			pillarSolutions.sort((a, b) => {
				if (a.priority > b.priority) return -1;

				if (a.priority < b.priority) return 1;

				return 0;
			});

			return pillarSolutions;
		},

		async setSolutionsCount(solutionsCount) {
			if (this.characteristicsRequirements.length > 0)
				this.solutionsCountElement.innerText = `Showing ${solutionsCount} out of ${this.getUserSolutions.length + 1} Solutions`;
			else
				this.solutionsCountElement.innerText = `Showing all ${this.getUserSolutions.length + 1} Solutions`;
		}
	}
};
</script>
