<template>
	<div class="wizard transition-250" :class="loading.page ? 'opacity-0' : ''">
		<Nav :height="this.height" />

		<div class="container-lg">
			<div
				class="min-h-screen mx-xl-10 pb-6"
				:style="'padding-top: calc(6rem + ' + height + ');'"
			>
				<!--- White Wizard Area --->
				<div class="w-100 min-h-100 bg-white rounded-xl shadow">
					<!--- Wizard Body --->
					<div
						v-if="!loading.introQuestions && !loading.page"
						class="pb-5 px-4"
					>
						<!--- Wizard Hero --->
						<div
							v-if="displayedIntroQuestion < 1"
							class="row justify-content-center py-5"
						>
							<div v-if="fields.hero.image" class="col-md-8 col-lg-3">
								<prismic-image
									class="w-100 image-fluid"
									:field="fields.hero.image"
								/>
							</div>
							<div class="col-md-10 col-lg-9">
								<h1
									class="h1 my-lg-3"
									:class="$getMediaBreakpoints.lg ? 'display-4' : ''"
									style="letter-spacing: -2px;"
								>
									{{ fields.hero.headline }}
								</h1>
								<prismic-rich-text
									v-if="fields.hero.content"
									:field="fields.hero.content"
									:class="$getMediaBreakpoints.lg ? 'h5' : 'h6'"
									class="text-dark font-weight-light"
								/>

								<!--- Wizard CTA --->
								<WizardCta :cta="fields.cta" />
							</div>
						</div>

						<!--- Wizard Hero Headline --->
						<div
							v-else
							class="w-100 d-flex flex-column align-items-center py-5"
						>
							<h2 class="h1 mb-3 my-lg-3" style="letter-spacing: -2px;">
								{{ fields.hero.headline }}
							</h2>

							<!--- Wizard CTA --->
							<WizardCta :cta="fields.cta" />
						</div>

						<!--- Question --->
						<div v-if="introQuestions.length > 0">
							<div
								v-if="introQuestions[displayedIntroQuestion].component"
								class="mx-lg-5 mb-5 rounded border border-lighter py-5 px-4 text-center"
							>
								<p
									class="mb-1 text-sm text-lighter text-uppercase font-weight-bold"
								>
									Question
									{{ displayedIntroQuestion + 1 + "/" + introQuestions.length }}
								</p>
								<component
									:is="introQuestions[displayedIntroQuestion].component"
									:question="introQuestions[displayedIntroQuestion]"
									:disableUnsure="true"
								/>
							</div>

							<!--- Next/Previous/Generate Buttons --->
							<div class="row justify-content-center">
								<!--- Previous Question --->
								<div
									v-if="displayedIntroQuestion > 0"
									class="col-auto my-2 my-md-0"
								>
									<button
										class="btn btn-white border border-gray text-uppercase d-block mx-auto min-w-12"
										@click="previousDisplayIntroQuestion"
									>
										<div
											class="d-flex align-items-center justify-content-between pl-2"
										>
											<div class="icon-large text-darker">
												<b-icon
													icon="arrow-left-short"
													aria-hidden="true"
												></b-icon>
											</div>
											<div class="mr-3 font-80 text-darker font-weight-bold">
												Previous
											</div>
										</div>
									</button>
								</div>

								<!--- Next Question --->
								<div
									v-if="introQuestions.length > (displayedIntroQuestion + 1)"
									class="col-auto my-2 my-md-0 order-first order-lg-last"
								>
									<button
										class="btn btn-yellow text-uppercase d-block mx-auto min-w-12"
										@click="nextDisplayIntroQuestion"
										:disabled="!introQuestions[displayedIntroQuestion].fieldValue || loading.route"
									>
										<div
											class="d-flex align-items-center justify-content-between pl-2"
										>
											<div class="mr-3 font-80 text-darker font-weight-bold">
												Next
											</div>
											<div class="icon-large text-darker">
												<b-icon
													icon="arrow-right-short"
													aria-hidden="true"
												></b-icon>
											</div>
										</div>
									</button>
								</div>

								<!--- Start Wizard --->
								<div v-else class="order-first order-lg-last">
									<button
										class="btn btn-yellow text-uppercase d-block mx-auto min-w-12"
										@click="endDisplayIntroQuestion"
										:disabled="!introQuestions[displayedIntroQuestion].fieldValue || loading.route"
									>
										<div
											class="d-flex align-items-center justify-content-between pl-2"
										>
											<div class="mr-3 font-80 text-darker font-weight-bold">
												Next
											</div>
											<div class="icon-large text-darker">
												<b-icon
													icon="arrow-right-short"
													aria-hidden="true"
												></b-icon>
											</div>
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>

					<!--- Wizard Loading --->
					<div v-else class="p-6">
						<h2 class="h1 text-center">
							Loading
						</h2>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Nav from "@/components/Nav.vue";

import WizardCta from "@/components/wizard/WizardCta.vue";

import WizardQuestionSelect from "@/components/wizard/WizardQuestionSelect.vue";
import WizardQuestionSelectCard from "@/components/wizard/WizardQuestionSelectCard.vue";
import WizardIntroQuestionCountry from "@/components/wizard/WizardIntroQuestionCountry.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
	name: "wizard",
	components: {
		Nav,
		WizardCta
	},
	metaInfo() {
		return {
			title: this.page.pageTitle
		};
	},
	mounted() {
		this.getContent();

		let multiplier = 0.5;

		if (this.$getMediaBreakpoints.md) multiplier = 1.1;

		if (this.$getMediaBreakpoints.lg) multiplier = 0.65;

		this.height = 200 * multiplier + "px";
	},
	data() {
		return {
			height: "130px",
			fields: {
				hero: {
					image: null,
					headline: null,
					content: null
				},
				cta: {
					image: null,
					content: null
				}
			},
			page: {
				pageTitle: null,
				pageDesc: null,
				pageTags: null
			},
			introQuestions: [],
			displayedIntroQuestion: -1,
			loading: {
				route: false,
				user: false,

				// Set Loading
				introQuestions: true,
				page: true
			}
		}
	},
	computed: {
		...mapGetters({
			getUserSections: "wizardToolUser/getUserSections",
			getUserIntroQuestions: "wizardToolUser/getUserIntroQuestions",

			getPrismicPage: "prismicPage/getPrismicPageByName"
		})
	},
	methods: {
		...mapMutations({
			setUserConditional: "wizardToolUser/setUserConditional"
		}),

		...mapActions({
			setupUser: "wizardToolUser/setupUser",
			setupUserIntroQuestions: "wizardToolUser/setupUserIntroQuestions",
			fetchPrismicPage: "prismicPage/fetchPrismicPage"
		}),

		async getContent() {
			// Start Loading
			this.loading.page = true;
			this.loading.introQuestions = true;

			// Get from store
			var document = this.getPrismicPage("wizard_page");

			// Get from API
			if (!document) document = await this.fetchPrismicPage("wizard_page");

			// Check, parse, after-content
			if (document) {
				// Parse Page
				this.parseDocument(document);

				// Stop Page Loading
				this.loading.page = false;

				// After Content
				this.afterContent();
			}
		},
		parseDocument(document) {
			if (document) {
				// Page
				this.page.pageTitle = document.data.page_title;
				this.page.pageDesc = document.data.page_description;
				this.page.pageTags = document.data.page_keywords;

				// Hero
				this.fields.hero.image = document.data.hero_image;
				this.fields.hero.headline = document.data.hero_headline;
				this.fields.hero.content = document.data.hero_content;

				// Cta
				this.fields.cta.image = document.data.cta_image;
				this.fields.cta.content = document.data.cta_content;
			}
		},
		async afterContent() {
			// Setup Intro Questions
			await this.setupUserIntroQuestions();
			this.introQuestions = this.getUserIntroQuestions;

			// Start Intro Questions
			this.nextDisplayIntroQuestion();

			// Stop Questions Loading
			this.loading.introQuestions = false;
		},

		previousDisplayIntroQuestion() {
			if (this.displayedIntroQuestion - 1 >= 0) this.displayedIntroQuestion--;
		},
		nextDisplayIntroQuestion() {
			if (this.displayedIntroQuestion + 1 < this.introQuestions.length)
				this.displayedIntroQuestion++;

			// Set Compononent
			switch (this.introQuestions[this.displayedIntroQuestion].fieldType) {
				case "intro_question_select_card":
					// eslint-disable-next-line prettier/prettier
					this.introQuestions[this.displayedIntroQuestion].component = WizardQuestionSelectCard;
					break;

				case "intro_question_country":
					// eslint-disable-next-line prettier/prettier
					this.introQuestions[this.displayedIntroQuestion].component = WizardIntroQuestionCountry;
					break;

				default:
					// eslint-disable-next-line prettier/prettier
					this.introQuestions[this.displayedIntroQuestion].component = WizardQuestionSelect;
					break;
			}
		},
		async endDisplayIntroQuestion() {
			// Set Loading
			this.loading.route = true;
			this.loading.user = true;

			let remainingIntroQuestions = this.introQuestions.filter(
				introQuestion => !introQuestion.fieldValue
			);
			if (remainingIntroQuestions.length > 0) {
				// console.log("There are Intro Questions Remaining", remainingIntroQuestions);
				return;
			}

			// Filter Questions
			let introQuestionFields = {
				country: this.introQuestions.filter(
					introQuestion => introQuestion.fieldConditional == "Country"
				)[0].fieldValue,

				coolingNeed: this.introQuestions.filter(
					introQuestion => introQuestion.fieldConditional == "Cooling Need"
				)[0].fieldValue,

				socialEco: this.introQuestions.filter(
					introQuestion => introQuestion.fieldConditional == "Social Eco"
				)[0].fieldValue,

				userPerspective: this.introQuestions.filter(
					introQuestion => introQuestion.fieldConditional == "User Perspective"
				)[0].fieldValue,

				climate: this.introQuestions.filter(
					introQuestion => introQuestion.fieldConditional == "Climate"
				)[0].fieldValue
			};

			// Set User Config
			this.setUserConditional(introQuestionFields);

			// Setup User
			await this.setupUser();

			// Stop User Loading
			this.loading.user = false;

			if (this.getUserSections(false).length > 0)
				// GoTo the First Section
				this.$router.push(
					{
						name: "wizard-section",
						params: {
							section_uid: this.getUserSections(false)[0].uid
						}
					}
				).then(
					() => this.loading.route = false
				);
			else
				// Skip to End
				this.$router.push(
					{
						name: "wizard-generate"
					}
				).then(
					() => this.loading.route = false
				);
		}
	}
};
</script>
<style lang="scss">
.wizard {
	background: #d9eef1;
	background-image: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.5) 0%,
		#caeaee 29%,
		#d9eef1 100%
	);
}
</style>
