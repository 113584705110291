<template>
	<div>
		<!--- Content --->
		<p class="h2 text-darker mb-4">
			{{ question.fieldText }}
		</p>

		<!--- Solutions --->
		<div class="row justify-content-center">
			<div
				v-for="(option, index) in question.fieldOptions"
				:key="'option-' + index + '-' + Date.now()"
				class="col-12 col-md-6 col-lg-4 my-2 my-md-0 cursor-pointer"
				@click="question.fieldValue = option.value"
			>
				<div 
					class="
						d-flex flex-column align-items-center
						p-2 border rounded-lg
						bg-white h-100
					"
				>
					<!--- Image and Content --->
					<prismic-image
						class="h-10 w-10 mb-4 object-contain"
						v-if="option.image"
						:field="option.image"
					/>
					<h4 class="h5" style="letter-spacing: -1px;">
						{{ option.label }}
					</h4>
					<prismic-rich-text
						v-if="option.desc"
						:field="option.desc"
						class="text-sm text-lighter font-weight-bold"
					/>
					
					<!--- Button --->
					<button 
						class="btn text-uppercase mx-auto mt-auto"
						:class="
							question.fieldValue == option.value ? 'btn-lighter p-1' : 'btn-outline-lighter' 
						"
					>
						<!--- Select Text --->
						<span 
							v-if="question.fieldValue != option.value" 
							class="min-w-12"
						>
							Select
						</span>
						
						<!--- Check SVG --->
						<svg v-else xmlns="http://www.w3.org/2000/svg" class="h-2 w-2 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
						</svg>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "WizardQuestionSelectCard",
	props: ["question"]
};
</script>
