<template>
  <div class="story-cards" :style="getBackground">
    <div class="container-design px-4">
      <div class="row">
        <div
          v-for="(card, index) in this.storyCards"
          :key="'reasons-card-' + index"
          class="card-container stories h-auto mt-md-4"
          :class="getMaxCards"
        >
          <div
            class="card justify-content-between stories w-100 h-100 border p-4 text-md-left"
          >
            <div>
              <prismic-image
                class="w-100 mx-auto max-w-128"
                v-if="card.card_image"
                :field="card.card_image"
              />
              <picture v-else>
                <source srcset="/img/placeholder.webp" type="image/webp">
                <source srcset="/img/placeholder.png" type="image/png"> 
                <img width="380" src="/img/placeholder.png" />
              </picture>
              <h5 class="h6 my-3">{{ card.card_heading }}</h5>
              <prismic-rich-text
                class="small rich-text-links"
                v-if="card.card_content"
                :field="card.card_content"
              />
            </div>
            <div class="w-md-60">
              <a
                v-if="card.card_button"
                @click="trackClickEvent"
                :href="card.card_button.url"
                :target="card.card_button.target"
                class="btn btn-primary text-uppercase"
              >
                <div
                  class="d-flex align-items-center justify-content-between pl-2"
                >
                  <p class="h5 text-white mb-0 mr-3 font-80">
                    {{ card.card_button_text }}
                  </p>
                  <div class="icon-large">
                    <b-icon
                      :icon="
                        card.card_button_icon
                          ? card.card_button_icon
                          : 'arrow-right-short'
                      "
                      aria-hidden="true"
                    ></b-icon>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StoryCards",
  props: ["storyCards", "backgroundImage", "backgroundGradient"],
  computed: {
    getMaxCards() {
      if (this.storyCards.length >= 3) {
        return "col-lg-4";
      }

      if (this.storyCards.length == 2) {
        return "col-lg-6";
      }

      return "col-lg-8 mx-auto";
    },
    getBackground() {
      return (
        "background-image: url(" +
        this.backgroundImage +
        ")," +
        this.backgroundGradient +
        ";"
      );
    }
  },
  methods: {
    trackClickEvent: function(event) {
      /*this.$gtm.trackEvent({
        event: "interaction",
        category: "Test",
        action: "click",
        label: "test",
        value: 5000,
        noninteraction: false
      });*/
    }
  }
};
</script>
<style lang="scss">
.story-cards {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
  background-color: transparent;
}

.card {
  &.stories {
    border-radius: 0.1875rem;
    border-color: #d9eef1;
  }
}

.card-container {
  &.stories {
    position: relative;

    &::before {
      content: "";
      position: absolute;

      height: 100%;
      width: calc(100% - 30px);

      margin-top: 3.75px;
      margin-left: 3.75px;

      border: 1px solid;
      border-radius: 0.375rem;
      border-color: #b6c4cd;

      background-color: #d9eef1;
    }
  }
}
</style>
