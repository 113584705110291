<template>
	<div>
		<!--- Content --->
		<WizardQuestionContent :question="question" />

		<!--- Dropdown --->
		<b-form-select 
			v-model="question.fieldValue" 
			class="p-2 w-100 rounded-0 border-darker"
			:class="question.fieldUnsure ? 'opacity-50' : ''"
			:disabled="question.fieldUnsure"
		>
			<option 
				v-for="(option, index) in question.fieldOptions"
				:key="'option-' + index + '-' + Date.now()"
				:value="option.value"
			>
				{{ option.label }}
			</option>
		</b-form-select>

		<!--- Option Unsure --->
		<WizardQuestionUnsure :question="question" />
	</div>
</template>
<script>
import WizardQuestionContent from '../../components/wizard/WizardQuestionContent.vue';
import WizardQuestionUnsure from '../../components/wizard/WizardQuestionUnsure.vue';

export default {
	name: "WizardQuestionRange",
	props: ["question"],
	components: {
		WizardQuestionContent,
		WizardQuestionUnsure
	}
};
</script>
