<template>
  <div class="tablet">
    <div class="container-design px-4">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <prismic-image
            class="w-100 px-6 py-5 position-relative"
            v-if="this.tablet.image"
            :field="this.tablet.image"
          />
          <div
            v-else
            class="w-100 px-6 py-5 position-relative bg-transparent"
            style="
              height: 780px;
              width: 1280px;
            "
          ></div>
          <img
            class="w-100 h-100 position-absolute left-0"
            src="/img/iPad.svg"
          />
        </div>
      </div>
    </div>
    <div class="bg-white position-relative -mt-6 min-h-6">
      <div class="container-design px-4">
        <div class="row justify-content-center">
          <div class="col-md-8 pt-5 text-center">
            <prismic-rich-text
              class="lead"
              v-if="this.tablet.content"
              :field="this.tablet.content"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Tablet",
  props: ["tablet"]
};
</script>
<style lang="scss">
.tablet {
  background: #d9eef1;
}
</style>
