<template>
	<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
		<circle cx="25" cy="25" r="25" fill="#fcbf0b"/>
		<path fill="#fff" d="M30.29 18.67h4.21v4.21zm-.92-1.59a1.21 1.21 0 00-.85 2.06l2.21 2.21-4.06 4.07-4.19-4.19a.8.8 0 00-1.12 0l-4.16 4.12a.4.4 0 000 .56l.56.56a.4.4 0 00.56 0l3.6-3.57 4.19 4.19a.79.79 0 001.12 0l4.62-4.62L34 24.65a1.2 1.2 0 002-.85v-5.92a.8.8 0 00-.8-.8zM12.73 15.5a.4.4 0 00-.4.4v17.81a.79.79 0 00.79.79h24.15a.4.4 0 00.4-.4v-.79a.39.39 0 00-.4-.39H13.92v-17a.4.4 0 00-.4-.4z"/>
	</svg>
</template>
<script>
export default {
	name: "IconsWizardImprove"
};
</script>
