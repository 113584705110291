<template>
	<div class="d-flex align-items-center mt-4">
		<prismic-image v-if="cta.image" :field="cta.image" class="w-6 mr-4" />
		<prismic-rich-text
			v-if="cta.content"
			:field="cta.content"
			class="text-dark font-weight-light rich-text-links"
		/>
	</div>
</template>
<script>
export default {
	name: "WizardCta",
	props: ["cta"]
};
</script>
