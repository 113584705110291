<template>
	<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
		<path d="M10 0h30a10 10 0 0110 10v40H0V10A10 10 0 0110 0z" fill="#eb6129"/>
		<path fill="#fff" d="M28.31 21.71l.61-.61a.45.45 0 000-.61l-2.14-2.14 2.43-2.44 4.87 4.88L21.86 33a2.61 2.61 0 01-2.11.74l-3.14-.35-.35-3.15a2.54 2.54 0 01.74-2.09l1.26-1.26L20.39 29a.45.45 0 00.61 0l.61-.61a.43.43 0 000-.61l-2.13-2.13 2.44-2.44 2.13 2.13a.43.43 0 00.61 0l.6-.61a.42.42 0 000-.6L23.13 22l2.44-2.44 2.13 2.13a.43.43 0 00.31.12.42.42 0 00.3-.12m5.47-10.36l-.61.61a.45.45 0 000 .61l1.53 1.52-2.44 2.44-3-3.05-.91-.91a.43.43 0 00-.61 0l-.61.6a.45.45 0 000 .61l.92.92-12.28 12.24a4.31 4.31 0 00-1.24 3.52l.39 3.42-3.57 3.57a.43.43 0 000 .61l.61.61a.42.42 0 00.6 0l3.57-3.57 3.42.38a4.3 4.3 0 003.52-1.23L35.3 22l.91.91a.43.43 0 00.61 0l.61-.6a.43.43 0 000-.61l-4-3.95 2.44-2.44 1.53 1.52a.42.42 0 00.6 0l.61-.6a.44.44 0 000-.62l-4.26-4.26a.42.42 0 00-.3-.12.47.47 0 00-.31.12" />
	</svg>
</template>
<script>
export default {
	name: "IconsWizardComfortSafety"
};
</script>
