<template>
	<div>
		<!--- Sections --->
		<div 
			v-if="getUserSections(true).length > 0"
			class="border border-gray my-5"
		>
			<!--- Table Headings --->
			<div class="bg-lightest font-weight-bold border-bottom border-gray">
				<div class="d-flex mx-3">
					<div class="w-20-percent p-3">
						<p class="mb-0"> Section </p>
					</div>
					<div class="w-50-percent p-3">
						<p class="mb-0"> Question </p>
					</div>
					<div class="w-30-percent p-3">
						<p class="mb-0"> Your Answer </p>
					</div>
				</div>
			</div>

			<!--- User Sections --->
			<div
				v-for="(section, sectionIndex) in getUserSections(true)"
				:key="'section-' + sectionIndex + '-' + Date.now()"
			>
				<!--- Top Border --->
				<hr v-if="sectionIndex > 0" class="border-gray" />

				<!--- User Questions --->
				<div
					v-for="(question, questionIndex) in getUserQuestions(section.questions, true)"
					:key="'question-' + questionIndex + '-' + Date.now()"
				>
					<!--- Question --->
					<div class="d-flex mx-3">
						<div class="w-20-percent p-3">
							<p class="mb-0"> 
								{{ section.heading }}
							</p>
						</div>
						<div class="w-50-percent p-3">
							<p class="mb-0"> 
								{{ question.fieldText }}
							</p>
						</div>
						<div class="w-30-percent p-3">
							<p class="mb-0"> 
								{{ question.fieldValue }}
								<span v-if="question.fieldUnits">
									{{ question.fieldUnits }}
								</span>
							</p>
						</div>
					</div>

					<!--- Top Border --->
					<hr v-if="questionIndex > 0" class="border-gray" />
				</div>
			</div>
		</div>
		<div v-else class="border border-gray p-3 my-5 max-h-screen overflow-y-scroll">
			<h2>You didn't answer any questions</h2>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	name: "WizardResultAnswers",
	computed: {
		...mapGetters({
			getUserSections: "wizardToolUser/getUserSections",
			getUserQuestions: "wizardToolUser/getUserQuestionsByFieldNames"
		})
	}
};
</script>