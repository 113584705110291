import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import VueMoment from "vue-moment";
import VueGtm from "vue-gtm";

import VueMeta from "vue-meta";

import "@/assets/scss/_style.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelope,
  faHandshake,
  faPlus,
  faUsers,
  faLock,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope as farEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import PrismicVue from '@prismicio/vue'
import linkResolver from './link-resolver'
import wizardRiskCalculator from "./plugins/wizard-risk-calculator";
import bootstrapWidthChecker from "./plugins/bootstrap-width-checker";

const accessToken = '' // Leave empty if your repo is public
const endpoint = 'https://this-is-cool.cdn.prismic.io/api/v2' // Use your repo name

//Brands
library.add(faFacebookF);
library.add(faTwitter);

//Solid
library.add(faEnvelope);
library.add(faHandshake);
library.add(faPlus);
library.add(faUsers);
library.add(faLock);
library.add(faMapMarkerAlt);

//Regular
library.add(farEnvelope);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueMoment);

Vue.use(wizardRiskCalculator);
Vue.use(bootstrapWidthChecker);

const isProd = process.env.NODE_ENV === "production";

Vue.use(VueGtm, {
  id: "GTM-KTNXZXV", // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  defer: true, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  async: true
});

Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  linkResolver
});

Vue.use(VueMeta);

Vue.prototype.$lang = navigator.languages
  ? navigator.languages[0]
  : navigator.language || navigator.userLanguage;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
