<template>
	<div>
		<!--- Content --->
		<p class="h2 text-darker mb-2">
			{{ question.fieldText }}
		</p>
		<div class="d-inline">
			<prismic-rich-text
				v-if="question.fieldDesc"
				:field="question.fieldDesc"
			/>
			<button
				class="btn btn-link text-sm text-lighter p-0"
				v-if="
					question.fieldTooltip &&
					question.fieldTooltip.length &&
					question.fieldTooltip[0].text
				"
				@click="
					() => {
						showTooltip = true;
					}
				"
			>
				Help information
			</button>
		</div>

		<!--- ToolTip Popup --->
		<div
			class="fixed-top w-100 h-100 background-tooltip d-flex flex-column justify-content-center align-items-center"
			v-if="showTooltip"
			@click="
				() => {
					showTooltip = false;
				}
			"
		>
			<div class="w-50-percent bg-white p-4 shadow">
				<prismic-rich-text
					v-if="question.fieldTooltip"
					:field="question.fieldTooltip"
				/>
				<prismic-link :field="question.fieldTooltipLink.prismic">
					{{ question.fieldTooltipLink.text }}
				</prismic-link>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "WizardQuestionContent",
	props: ["question"],
	data() {
		return {
			showTooltip: false
		};
	}
};
</script>
