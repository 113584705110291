var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"h1 display-5"},[_vm._v(" Based on living in "+_vm._s(_vm.getUserCountry.name)+" your access to cooling risk today is "),(_vm.getUserRiskResult == 'low')?_c('span',{staticClass:"rounded-pill bg-green px-3 text-white"},[_vm._v(" Low ")]):(_vm.getUserRiskResult == 'medium')?_c('span',{staticClass:"rounded-pill bg-lighter px-3 text-white"},[_vm._v(" Medium ")]):(_vm.getUserRiskResult == 'high')?_c('span',{staticClass:"rounded-pill bg-yellow px-3 text-white"},[_vm._v(" High ")]):_vm._e()]),(
			_vm.yourResults.lowRisk 
			&&
			_vm.getUserRiskResult == 'low'
		)?_c('prismic-rich-text',{attrs:{"field":_vm.yourResults.lowRisk}}):_vm._e(),(
			_vm.yourResults.mediumRisk 
			&&
			_vm.getUserRiskResult == 'medium'
		)?_c('prismic-rich-text',{attrs:{"field":_vm.yourResults.mediumRisk}}):_vm._e(),(
			_vm.yourResults.highRisk 
			&&
			_vm.getUserRiskResult == 'high'
		)?_c('prismic-rich-text',{attrs:{"field":_vm.yourResults.highRisk}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }