var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('WizardQuestionContent',{attrs:{"question":_vm.question}}),_c('div',{staticClass:"pt-5 position-relative d-flex",class:_vm.question.fieldUnsure ? 'opacity-50' : ''},[_c('div',{staticClass:"\n\t\t\t\tflex-auto ml-2 mx-md-0 h-1-5 position-relative\n\t\t\t\tborder border-gray rounded-pill pointer-events-none\n\t\t\t"},[_c('div',{staticClass:"h-100 rounded-pill-left transition-125",class:_vm.question.fieldValue == _vm.question.fieldCountryValue
						? 'bg-primary'
						: _vm.question.fieldUnsure
							? 'bg-gray'
							: 'bg-green',style:('width: ' + 
					(
						(_vm.question.fieldValue >= 0 ? _vm.question.fieldValue : 0) 
						/ _vm.question.fieldMax
					) * 100 + 
					'%;')}),_c('div',{staticClass:"\n\t\t\t\t\tposition-absolute\n\t\t\t\t\tbg-white h-2 w-2 transition-125\n\t\t\t\t\tborder border-gray rounded-circle\n\t\t\t\t",style:('top: -0.25rem; left: calc(' + 
					(
						(_vm.question.fieldValue >= 0 ? _vm.question.fieldValue : 0)
						/ _vm.question.fieldMax
					) * 100 + 
					'% - 1rem);')}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.question.fieldValue),expression:"question.fieldValue"}],staticClass:"position-absolute opacity-0 top-0 pointer-events-all",class:_vm.question.fieldUnsure ? 'cursor-not-allowed' : '',staticStyle:{"width":"calc(100% + 1rem)","height":"calc(100% + 1rem)","top":"-0.5rem"},attrs:{"type":"range","step":"1","min":"0","max":_vm.question.fieldMax,"disabled":_vm.question.fieldUnsure},domProps:{"value":(_vm.question.fieldValue)},on:{"__r":function($event){return _vm.$set(_vm.question, "fieldValue", $event.target.value)}}})]),_c('p',{staticClass:"w-6 w-lg-12 pl-2 pl-lg-4 pointer-events-none"},[_c('span',{staticClass:"h1",style:(_vm.question.fieldValue == 0 ? 'color: #EDA576!important;' : '')},[_vm._v(" "+_vm._s(( _vm.question.fieldValue >= 0 ? Number(_vm.question.fieldValue).toLocaleString() : '~' ) + ( _vm.question.fieldUnits == '%' ? "%" : "" ))+" ")]),(_vm.question.fieldUnits != '%')?_c('span',{class:_vm.$getMediaBreakpoints.lg ? 'h3' : 'h5',style:(_vm.question.fieldValue == 0 ? 'color: #EDA576!important;' : '')},[_vm._v(" "+_vm._s(_vm.question.fieldUnits)+" ")]):_vm._e()])]),(
			(
				(
					_vm.question.fieldCountryValue ||
					_vm.question.fieldCountryValue == 0
				) && 
				_vm.question.fieldCountryValue >= 0
			) &&
			_vm.question.fieldValue == _vm.question.fieldCountryValue
		)?_c('WizardQuestionCountry'):_c('WizardQuestionUnsure',{attrs:{"question":_vm.question}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }