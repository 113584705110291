<template>
	<div
		class="solutions transition-250"
		:class="loading.page ? 'opacity-0' : ''"
		:style="{ backgroundImage: getBackground }"
	>
		<Nav :height="this.height" />

		<div v-if="!loading.solutions && !loading.page">
			<!-- Hero -->
			<div class="hero">
				<div
					class="container-design d-flex px-4"
					:style="{ paddingTop: this.height }"
				>
					<!--- Centering --->
					<div class="row justify-content-center py-5 text-center">
						<div class="col-md-10 mb-4 mb-md-0">
							<!--- Content --->
							<h1 class="display-4 my-4 mx-auto w-75">
								{{ fields.hero.headline }}
							</h1>
							<prismic-rich-text
								class="lead text-dark stronger:font-weight-bold mb-4"
								style="color: #163B41;"
								v-if="fields.hero.content"
								:field="fields.hero.content"
							/>

							<!--- Cards --->
							<div class="row justify-content-center" v-if="fields.cards">
								<div
									class="col-12 col-md-6 col-lg-4 h-auto my-4"
									v-for="(card, index) in fields.cards"
									:key="`card-${index}`"
								>
									<!--- Card Container --->
									<div
										class="
											d-flex flex-column align-items-center justify-content-between 
											h-100 py-4 bg-white bg-opacity-50 rounded-lg
										"
									>
										<!--- Card Image --->
										<prismic-image
											class="w-10 h-8 mb-4 object-contain"
											v-if="card.card_image"
											:field="card.card_image"
										/>

										<!--- Card Content --->
										<div class="w-75 mx-auto">
											<h2 class="h6 text-dark">
												{{ card.card_title }}
											</h2>
											<prismic-rich-text
												class="font-80 rich-text-links mb-4"
												v-if="card.card_content"
												:field="card.card_content"
											/>
										</div>

										<!--- Card Button --->
										<a
											v-if="card.card_button_link"
											:href="card.card_button_link.url"
											:target="card.card_button_link.target"
											@click="
												() => {
													if (card.card_button_link.url.includes('#'))
														hashScroll();
												}
											"
											class="btn btn-primary text-uppercase"
										>
											<div
												class="d-flex align-items-center justify-content-between pl-2"
											>
												<p class="h5 text-white mb-0 mr-3 font-80">
													{{ card.card_button_text }}
												</p>
												<div class="icon-large">
													<b-icon
														:icon="
															card.card_button_icon
																? card.card_button_icon
																: 'arrow-right-short'
														"
														aria-hidden="true"
													></b-icon>
												</div>
											</div>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!--- Solutions --->
			<div class="container-lg" ref="#solutions">
				<div
					class="mt-6"
					:class="$getMediaBreakpoints.lg ? 'w-10/12 mx-auto' : 'w-100'"
				>
					<!--- Heading --->
					<div class="w-75 mx-auto">
						<h2>
							{{ fields.solutionsHero.headline }}
						</h2>
						<prismic-rich-text
							v-if="fields.solutionsHero.content"
							:field="fields.solutionsHero.content"
							class="text-dark font-weight-light h6 mb-5"
						/>
					</div>

					<!--- Top Border --->
					<hr class="border-gray my-4" />

					<!--- Modal Closes --->
					<div
						v-if="showFilterModal"
						class="position-fixed z-10 w-100 h-100 top-0 left-0"
						@click="showFilterModal = false"
					></div>
					<div
						v-if="showApproachInfoModal"
						class="position-fixed z-10 w-100 h-100 top-0 left-0"
						@click="showApproachInfoModal = false"
					></div>

					<!--- Filter --->
					<div class="position-relative">
						<!--- Button --->
						<div class="row justify-content-end">
							<div class="col-12 col-lg-3">
								<button
									@click="showFilterModal = !showFilterModal"
									class="w-100 p-3 border border-gray bg-white d-flex justify-content-between align-items-center"
								>
									<p class="mb-0">
										Filter solutions
									</p>
									<div class="icon-sm">
										<b-icon icon="chevron-down" aria-hidden="true"></b-icon>
									</div>
								</button>
							</div>
						</div>

						<!--- Modal --->
						<div
							v-if="showFilterModal"
							class="w-100 w-lg-75-percent mt-2 p-3 right-0 position-absolute bg-white shadow z-10"
						>
							<b-icon-x-circle
								class="position-absolute m-3 right-0 top-0 d-block d-lg-none"
								@click="showFilterModal = !showFilterModal"
							/>

							<h3 class="h6">Cooling needs</h3>
							<div class="d-flex flex-wrap -mx-2 mb-4">
								<button
									v-for="(option, index) in filterCoolingNeedOptions"
									:key="'option-' + index + '-' + Date.now()"
									class="btn d-flex rounded-pill py-3 px-5 m-2 align-items-center font-weight-bold"
									:class="
										filterCoolingNeed.includes(option.value)
											? 'bg-primary text-white'
											: 'bg-lightest text-dark'
									"
									@click="setFilter(option.value, 'filterCoolingNeed')"
								>
									{{ option.label }}
								</button>
							</div>

							<h3 class="h6">Solution Types</h3>
							<div class="d-flex flex-wrap -mx-2">
								<button
									v-for="(option, index) in filterPillarOptions"
									:key="'option-' + index + '-' + Date.now()"
									class="btn d-flex rounded-pill py-3 px-5 m-2 align-items-center font-weight-bold"
									:class="
										filterPillar.includes(option.value)
											? 'bg-primary text-white'
											: 'bg-lightest text-dark'
									"
									@click="setFilter(option.value, 'filterPillar')"
								>
									{{ option.label }}
								</button>
							</div>
						</div>
					</div>

					<!--- Solutions Table --->
					<div class="position-relative border border-gray bg-white mt-4">
						<!--- Headings --->
						<div
							class="d-flex bg-lightest text-black font-weight-bold border-bottom border-gray"
						>
							<div class="col col-lg-8 py-3">
								<p class="mb-0">
									Solution
								</p>
							</div>
							<div
								class="col-4 py-3 cursor-pointer hover:underline d-flex align-items-center"
								@click="showApproachInfoModal = !showApproachInfoModal"
								v-if="$getMediaBreakpoints.lg"
							>
								<p class="mb-0 mr-2">
									Approach
								</p>

								<!--- Approach Info --->
								<b-icon
									v-if="showApproachInfoModal"
									icon="x-circle"
									aria-hidden="true"
								></b-icon>
								<!-- eslint-disable-next-line prettier/prettier -->
								<b-icon 
									v-else
									icon="question-circle"
									aria-hidden="true"
								></b-icon>
							</div>
							<div v-else class="col-auto py-3">
								<p class="mb-0">
									Approach
								</p>
							</div>
						</div>

						<!--- Approach Info Modal --->
						<div
							v-if="showApproachInfoModal"
							class="w-100 w-lg-50-percent p-3 right-0 position-absolute bg-white shadow z-10"
						>
							<h3>
								{{ fields.approachInfo.title }}
							</h3>
							<prismic-rich-text
								v-if="fields.approachInfo.desc"
								:field="fields.approachInfo.desc"
							/>
						</div>

						<!--- Solutions --->
						<div
							v-for="(solution, solutionIndex) in displayedSolutions"
							:key="'solution-' + solutionIndex + '-' + Date.now()"
						>
							<!--- Top Border --->
							<hr v-if="solutionIndex > 0" class="border-gray" />

							<!--- Solution --->
							<div class="d-flex">
								<!--- Content --->
								<div class="col-9 col-lg-8 py-3">
									<h6 class="font-weight-bold">
										{{ solution.title }}
									</h6>
									<prismic-rich-text
										v-if="solution.desc"
										:field="solution.desc"
									/>
									<prismic-link :field="solution.link.prismic">
										{{ solution.link.text }}
									</prismic-link>
								</div>

								<!--- Tags ---->
								<div class="col-3 col-lg-4 py-3 d-flex flex-wrap h-100">
									<div
										v-if="solution.approach.improve"
										class="my-2 mr-2 mr-lg-5 text-center"
									>
										<WizardApproach
											wizardIcon="improve"
											:class="$getMediaBreakpoints.md ? 'w-4 h-4' : 'w-3 h-3'"
										/>
										<p
											class="text-sm text-uppercase font-weight-bold mb-0 pt-1"
										>
											improve
										</p>
									</div>
									<div
										v-if="solution.approach.leverage"
										class="my-2 mr-2 mr-lg-5 text-center"
									>
										<WizardApproach
											wizardIcon="leverage"
											:class="$getMediaBreakpoints.md ? 'w-4 h-4' : 'w-3 h-3'"
										/>
										<p
											class="text-sm text-uppercase font-weight-bold mb-0 pt-1"
										>
											leverage
										</p>
									</div>
									<div
										v-if="solution.approach.protect"
										class="my-2 mr-2 mr-lg-5 text-center"
									>
										<WizardApproach
											wizardIcon="protect"
											:class="$getMediaBreakpoints.md ? 'w-4 h-4' : 'w-3 h-3'"
										/>
										<p
											class="text-sm text-uppercase font-weight-bold mb-0 pt-1"
										>
											protect
										</p>
									</div>
									<div
										v-if="solution.approach.reduce"
										class="my-2 mr-2 mr-lg-5 text-center"
									>
										<WizardApproach
											wizardIcon="reduce"
											:class="$getMediaBreakpoints.md ? 'w-4 h-4' : 'w-3 h-3'"
										/>
										<p
											class="text-sm text-uppercase font-weight-bold mb-0 pt-1"
										>
											reduce
										</p>
									</div>
									<div
										v-if="solution.approach.shift"
										class="my-2 mr-2 mr-lg-5 text-center"
									>
										<WizardApproach
											wizardIcon="shift"
											:class="$getMediaBreakpoints.md ? 'w-4 h-4' : 'w-3 h-3'"
										/>
										<p
											class="text-sm text-uppercase font-weight-bold mb-0 pt-1"
										>
											shift
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Nav from "@/components/Nav.vue";

import WizardApproach from "@/components/wizard/WizardApproach.vue";

import { mapGetters, mapActions } from "vuex";

export default {
	name: "solutions",
	components: {
		Nav,

		WizardApproach
	},
	metaInfo() {
		return {
			title: this.page.pageTitle
		};
	},
	mounted() {
		this.getContent();

		let multiplier = 0.5;

		if (this.$getMediaBreakpoints.md) multiplier = 1.1;

		if (this.$getMediaBreakpoints.lg) multiplier = 0.65;

		this.height = 200 * multiplier + "px";
	},
	data() {
		return {
			height: "130px",
			page: {
				pageTitle: null,
				pageDesc: null,
				pageTags: null
			},
			fields: {
				hero: {
					headline: "",
					lead: null,
					content: null
				},
				cards: [
					/*
					{
						card_image: null,
						card_title: "",
						card_content: null,
						card_button_link: null,
						card_button_text: "",
						card_button_icon: null
					}
					*/
				],
				solutionsHero: {
					headline: "",
					content: null
				},
				approachInfo: {
					title: "",
					desc: null
				}
			},

			solutions: [],
			displayedSolutions: [],

			orderBy: "abc",

			filterCoolingNeed: [],
			filterCoolingNeedOptions: [
				{
					value: "comfort",
					label: "Comfort & Safety"
				},
				{
					value: "health",
					label: "Health & Care"
				},
				{
					value: "nutrition",
					label: "Food & Nutrition"
				}
			],

			filterPillar: [],
			filterPillarOptions: [
				{
					value: "financial",
					label: "Financial"
				},
				{
					value: "policy",
					label: "Policy"
				},
				{
					value: "services",
					label: "Services"
				},
				{
					value: "tech",
					label: "Technology"
				}
			],

			backgroundImage: "/img/clouds-3.png",
			backgroundGradient: "linear-gradient(180deg, #d9eef1 0%, #fff 50%)",

			showFilterModal: false,
			showApproachInfoModal: false,

			loading: {
				route: false,

				// Set Loading
				solutions: true,
				page: true
			}
		};
	},
	computed: {
		...mapGetters({
			getPrismicSolutions: "prismicWizardTool/getPrismicSolutions",
			getPrismicPage: "prismicPage/getPrismicPageByName"
		}),

		getBackground() {
			return `url('${this.backgroundImage}'), this.backgroundGradient`;
		}
	},
	methods: {
		...mapActions({
			fetchPrismicSolutions: "prismicWizardTool/fetchPrismicSolutions",
			fetchPrismicPage: "prismicPage/fetchPrismicPage"
		}),

		async getContent() {
			// Start Loading
			this.loading.page = true;
			this.loading.solutions = true;

			// Get from store
			var document = this.getPrismicPage("solutions_page");

			// Get from API
			if (!document) document = await this.fetchPrismicPage("solutions_page");

			// Check, parse, after-content
			if (document) {
				// Parse Page
				this.parseDocument(document);

				// Stop Page Loading
				this.loading.page = false;

				// Get Solutions
				await this.getSolutions();

				// Stop Solutions Loading
				this.loading.solutions = false;

				// After Content
				this.afterContent();
			}
		},
		async getSolutions() {
			// Get from store
			var solutions = this.getPrismicSolutions;

			// Get from API
			if (!solutions.size > 0) {
				var solutionIds = await this.fetchPrismicSolutions();

				// Check the SectionIDs
				if (solutionIds.length > 0) solutions = this.getPrismicSolutions;
			}

			// Check and Setup Solutions
			if (solutions.size > 0)
				solutions.forEach(solution => this.parseSolution(solution));
		},

		parseDocument(document) {
			if (document) {
				// Page
				this.page.pageTitle = document.data.page_title;
				this.page.pageDesc = document.data.page_description;
				this.page.pageTags = document.data.page_keywords;

				// Hero
				this.fields.hero.headline = document.data.hero_headline;
				this.fields.hero.lead = document.data.hero_lead;
				this.fields.hero.content = document.data.hero_content;

				// Cards
				this.fields.cards = document.data.cards;

				// Solutions Hero
				this.fields.solutionsHero.headline =
					document.data.solutions_hero_headline;
				this.fields.solutionsHero.content =
					document.data.solutions_hero_content;

				// Approach Info
				this.fields.approachInfo.title = document.data.approach_info_title;
				this.fields.approachInfo.desc = document.data.approach_info_description;
			}
		},
		parseSolution(document) {
			if (document) {
				var userSolution = {
					id: document.id,

					title: document.data.solution_title,
					desc: document.data.solution_description,
					link: {
						prismic: document.data.solution_link,

						// TODO -> Fix Spelling Mistake
						text: document.data.solution_link_text
							? document.data.solution_link_text
							: document.data.soultion_link_text
					},

					/* eslint-disable prettier/prettier */
					approach: {
						improve:  document.data.field_approach_improve,
						leverage: document.data.field_approach_leverage,
						protect:  document.data.field_approach_protect,
						reduce:   document.data.field_approach_reduce,
						shift: 	  document.data.field_approach_shift
					},
					pillar: {
						primary: 	document.data.field_pillar,
						financial:  document.data.field_pillar_financial,
						policy: 	document.data.field_pillar_policy,
						services: 	document.data.field_pillar_services,
						tech: 		document.data.field_pillar_tech
					},
					characteristics: {
						capitalCost: 	document.data.field_characteristics_capital_cost,
						energySaving: 	document.data.field_characteristics_energy_saving,
						energySource: 	document.data.field_characteristics_energy_source,
						integrateEase: 	document.data.field_characteristics_integrate_ease,
						operatingCost: 	document.data.field_characteristics_operating_cost,
						refGwp: 		document.data.field_characteristics_ref_gwp
					},

					risk: {
						high: 	document.data.field_risk_high,
						medium: document.data.field_risk_medium,
						low: 	document.data.field_risk_low
					},
					coolingNeed: {
						comfort: 	document.data.field_cooling_need_comfort,
						health: 	document.data.field_cooling_need_health,
						nutrition: 	document.data.field_cooling_need_nutrition
					},
					userPerspective: {
						agriculture: 	 document.data.field_user_perspective_agriculture,
						health: 		 document.data.field_user_perspective_health,
						industry: 		 document.data.field_user_perspective_industry,
						localGovernment: document.data.field_user_perspective_local_government,

						// TODO -> Fix Spelling Mistake
						nationalGovernment:
							document.data.field_user_perspective_national_government
								? document.data.field_user_perspective_national_government
								: document.data.field_user_perspective_not_government
					}
					/* eslint-enable prettier/prettier */
				};

				// Override Target
				userSolution.link.prismic["target"] = "_blank";

				// Store User Solution
				this.solutions.push(userSolution);
			}
		},

		afterContent() {
			// ABC Sort
			this.displayedSolutions = this.solutions.sort((a, b) => {
				let titleA = a.title.toUpperCase();
				let titleB = b.title.toUpperCase();

				if (titleA < titleB) return -1;

				if (titleB > titleB) return 1;

				return 0;
			});

			this.hashScroll();
		},

		hashScroll() {
			window.setTimeout(async () => {
				// Load Refs
				await this.$refs;

				// HREF Check
				if (
					window &&
					window.location.hash &&
					window.location.hash in this.$refs
				)
					this.$refs[window.location.hash].scrollIntoView();
			}, 125);
		},

		toggleOrderBy() {
			// abc to -abc
			if (this.orderBy == "abc") this.orderBy = "-abc";
			// -abc to abc
			else this.orderBy = "abc";

			this.filterSolutions();
		},
		setFilter(value, filter) {
			// Remove from Filter
			if (this[filter].includes(value))
				// eslint-disable-next-line prettier/prettier
				this[filter] = this[filter].filter(
					filterValue => filterValue != value
				);

			// Add to Filter
			else this[filter].push(value);

			this.filterSolutions();
		},
		filterSolutions() {
			// Filter Solutions
			this.displayedSolutions = this.solutions.filter(solution => {
				// Check if Filter Not Set
				let matchedCoolingNeed = !(this.filterCoolingNeed.length > 0);
				let matchedPillar = !(this.filterPillar.length > 0);

				// Check if Matched Cooling Need
				this.filterCoolingNeed.forEach(coolingNeed => {
					// eslint-disable-next-line prettier/prettier
					if (solution.coolingNeed[coolingNeed])
						matchedCoolingNeed = true;
				});

				// Check if Matched Pillar
				this.filterPillar.forEach(pillar => {
					if (solution.pillar[pillar]) matchedPillar = true;
				});

				// Check if Both Matching
				return matchedCoolingNeed && matchedPillar;
			});

			// ABC Sort
			this.displayedSolutions = this.displayedSolutions.sort((a, b) => {
				let titleA = a.title.toUpperCase();
				let titleB = b.title.toUpperCase();

				if (titleA < titleB) return -1;

				if (titleB > titleB) return 1;

				return 0;
			});

			// Set Reverse
			if (this.orderBy == "-abc")
				this.displayedSolutions = this.displayedSolutions.reverse();
		}
	}
};
</script>
<style lang="scss">
.solutions {
	background: #d9eef1;
	background-image: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.5) 0%,
		#caeaee 29%,
		#d9eef1 100%
	);
	background-size: 100%;
	background-repeat: no-repeat;
	background-color: transparent;
	min-height: 100vh;
	padding-bottom: 2rem;
}
.hero {
	min-height: 100vh;
	background: #d9eef1;
	background-image: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.5) 0%,
		#caeaee 29%,
		#d9eef1 100%
	);
}
</style>
