<template>
	<div class="stories opacity-0 transition-250">
		<Nav :height="this.height" />

		<!-- Hero -->
		<div class="hero stories">
			<div
				class="container-design pb-3 px-4"
				:style="{ paddingTop: this.height }"
			>
				<div class="text-center w-md-75-percent mx-auto">
					<h1 class="display-3 mb-4">
						{{ fields.hero.headline }}
					</h1>
					<prismic-rich-text
						class="lead text-dark"
						v-if="fields.hero.content"
						:field="fields.hero.content"
					/>
				</div>
			</div>
		</div>

		<!-- Cards -->
		<StoryCards
			class="-mt-6 pt-6 pb-lg-6"
			:backgroundImage="'/img/clouds-3.png'"
			:backgroundGradient="'linear-gradient(180deg, #d9eef1 0%, #fff 50%)'"
			:storyCards="fields.storyCards"
		/>

		<!-- Download Toolkit -->
		<DownloadToolkit class="mt-lg-6" :toolkit="fields.toolkit" />

		<!-- Newsletter -->
		<Newsletter :newsletter="fields.newsletter" />
	</div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import StoryCards from "@/components/StoryCards.vue";
import DownloadToolkit from "@/components/DownloadToolkit.vue";
import Newsletter from "@/components/Newsletter.vue";

import Cocoen from "cocoen";
import bodymovin from "lottie-web";

import { mapGetters, mapActions } from "vuex";

export default {
	name: "stories",
	components: {
		Nav,
		StoryCards,
		DownloadToolkit,
		Newsletter
	},
	metaInfo() {
		//TODO: Get from prismic fields?
		return {
			title: this.page.pageTitle,
			meta: [
				{
					vmid: "description",
					name: "description",
					content: this.page.pageDesc
				},
				{
					vmid: "keywords",
					name: "keywords",
					content: this.page.pageTags
				}
			]
		};
	},
	mounted() {
		this.getContent();

		let multiplier = 0.8;

		if (this.$getMediaBreakpoints.md) multiplier = 1.4;

		if (this.$getMediaBreakpoints.lg) multiplier = 1.1;

		this.height = 200 * multiplier + "px";

		bodymovin.loadAnimation({
			container: document.getElementById("bm"),
			renderer: "svg",
			loop: true,
			autoplay: true,
			path: "/animations/data.json"
		});
	},
	data() {
		return {
			height: "130px",
			fields: {
				navButton: {
					download: null,
					downloadText: null
				},
				hero: {
					headline: null,
					content: null
				},
				storyCards: null,
				toolkit: {
					heading: null,
					content: null,
					download: null,
					downloadText: null
				},
				newsletter: {
					heading: null
				}
			},
			page: {
				pageTitle: null,
				pageDesc: null,
				pageTags: null
			}
		};
	},
	computed: {
		...mapGetters({
			getPrismicPage: "prismicPage/getPrismicPageByName"
		})
	},
	methods: {
		...mapActions({
			fetchPrismicPage: "prismicPage/fetchPrismicPage"
		}),

		async getContent() {
			// Get from store
			var document = this.getPrismicPage("stories_page");

			// Get from API
			if (!document) document = await this.fetchPrismicPage("stories_page");

			// Check, parse, after-content
			if (document) {
				this.parseDocument(document);
				this.afterContent();
			}
		},
		afterContent() {
			this.doFadeIn();
		},
		doFadeIn() {
			document.querySelector(".stories").classList.remove("opacity-0");
		},
		parseDocument(document) {
			if (document) {
				// Page
				this.page.pageTitle = document.data.page_title;
				this.page.pageDesc = document.data.page_description;
				this.page.pageTags = document.data.page_keywords;

				// Nav
				this.fields.navButton.download = document.data.nav_button_link;
				this.fields.navButton.downloadText = document.data.nav_button_text;

				// Hero
				this.fields.hero.headline = document.data.hero_headline;
				this.fields.hero.content = document.data.hero_content;

				// Story Cards
				this.fields.storyCards = document.data.story_cards;

				// Toolkit
				this.fields.toolkit.heading = document.data.toolkit_heading;
				this.fields.toolkit.content = document.data.toolkit_content;
				this.fields.toolkit.download = document.data.toolkit_download;
				this.fields.toolkit.downloadText = document.data.toolkit_download_text;

				// Newsletter
				this.fields.newsletter.heading = document.data.newsletter_heading;
			}
		}
	}
};
</script>
<style lang="scss">
.hero {
	&.stories {
		background: #d9eef1;
		background-image: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.5) 0%,
			#caeaee 29%,
			#d9eef1 100%
		);
	}
}
</style>
