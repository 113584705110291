var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"h2 text-darker mb-2"},[_vm._v(" "+_vm._s(_vm.question.fieldText)+" ")]),_c('div',{staticClass:"d-inline"},[(_vm.question.fieldDesc)?_c('prismic-rich-text',{attrs:{"field":_vm.question.fieldDesc}}):_vm._e(),(
				_vm.question.fieldTooltip &&
				_vm.question.fieldTooltip.length &&
				_vm.question.fieldTooltip[0].text
			)?_c('button',{staticClass:"btn btn-link text-sm text-lighter p-0",on:{"click":function () {
					_vm.showTooltip = true;
				}}},[_vm._v(" Help information ")]):_vm._e()],1),(_vm.showTooltip)?_c('div',{staticClass:"fixed-top w-100 h-100 background-tooltip d-flex flex-column justify-content-center align-items-center",on:{"click":function () {
				_vm.showTooltip = false;
			}}},[_c('div',{staticClass:"w-50-percent bg-white p-4 shadow"},[(_vm.question.fieldTooltip)?_c('prismic-rich-text',{attrs:{"field":_vm.question.fieldTooltip}}):_vm._e(),_c('prismic-link',{attrs:{"field":_vm.question.fieldTooltipLink.prismic}},[_vm._v(" "+_vm._s(_vm.question.fieldTooltipLink.text)+" ")])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }