<template>
  <svg
    width="15px"
    height="15px"
    viewBox="0 0 15 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>LinkedIn</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.498039 0 0 0 0 0.772549 0 0 0 0 0.815686 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Desktop"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Cool-Home-Two" transform="translate(-185.000000, -4712.000000)">
        <g id="FOOTER" transform="translate(-126.000000, 4522.000000)">
          <g id="Lower-Footer" transform="translate(126.000000, 148.000000)">
            <g filter="url(#filter-1)" id="social">
              <g transform="translate(34.000000, 41.000000)">
                <path
                  d="M152.72,5.02 C153.72,5.02 154.36,4.34 154.36,3.5 C154.34,2.62 153.72,1.96 152.74,1.96 C151.76,1.96 151.12,2.62 151.14,3.5 C151.12,4.34 151.76,5.02 152.72,5.02 Z M154.26,16 L154.26,6.22 L151.22,6.22 L151.22,16 L154.26,16 Z M159.103636,16 L159.103636,10.36 C159.103636,10.08 159.143636,9.8 159.223636,9.6 C159.423636,9.04 159.923636,8.46 160.763636,8.46 C161.863636,8.46 162.303636,9.32 162.303636,10.58 L162.303636,16 L165.343636,16 L165.343636,10.2 C165.343636,7.32 163.843636,6 161.843636,6 C160.223636,6 159.223636,6.94 158.823636,7.56 L158.763636,7.56 L158.623636,6.22 L155.983636,6.22 C156.023636,7.08 156.063636,8.12 156.063636,9.34 L156.063636,16 L159.103636,16 Z"
                  id="in"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "LinkedIn"
};
</script>
