var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"w-75-percent w-lg-25-percent w-xl-15-percent mr-4 mr-lg-5 mb-4 no-print"},[(_vm.getUserConditional.coolingNeed == 'comfort')?_c('IconWizardComfortSafety'):_vm._e(),(_vm.getUserConditional.coolingNeed == 'nutrition')?_c('IconWizardFoodNutrition'):_vm._e(),(_vm.getUserConditional.coolingNeed == 'health')?_c('IconWizardHealthCare'):_vm._e()],1),_c('div',[_c('h2',{staticClass:"h4"},[(_vm.getUserConditional.coolingNeed == 'comfort')?_c('span',[_vm._v(" Comfort ")]):(_vm.getUserConditional.coolingNeed == 'nutrition')?_c('span',[_vm._v(" Food & Nutrition ")]):(_vm.getUserConditional.coolingNeed == 'health')?_c('span',[_vm._v(" Health ")]):_vm._e()]),(
				_vm.solutionsNeed.comfort
				&&
				_vm.getUserConditional.coolingNeed == 'comfort'
			)?_c('prismic-rich-text',{attrs:{"field":_vm.solutionsNeed.comfort}}):_vm._e(),(
				_vm.solutionsNeed.nutrition
				&&
				_vm.getUserConditional.coolingNeed == 'nutrition'
			)?_c('prismic-rich-text',{attrs:{"field":_vm.solutionsNeed.nutrition}}):_vm._e(),(
				_vm.solutionsNeed.health
				&&
				_vm.getUserConditional.coolingNeed == 'health'
			)?_c('prismic-rich-text',{attrs:{"field":_vm.solutionsNeed.health}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }