import Vue from "vue";
import Router from "vue-router";

import Home from "./views/Home.vue";
import Stories from "./views/Stories.vue";
import Toolkit from "./views/Toolkit.vue";

import Preview from "./views/Preview.vue";
import NotFound from "./views/NotFound.vue";

import Wizard from "./views/wizard/Wizard.vue";
import WizardSection from "./views/wizard/WizardSection.vue";
import WizardGenerate from "./views/wizard/WizardGenerate.vue";
import WizardResult from "./views/wizard/WizardResult.vue";
import WizardTest from "./views/wizard/WizardTest.vue";

import Solutions from "./views/wizard/Solutions.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    /*
      Home
    */
    {
      path: "/",
      name: "home",
      component: Home
    },
    /*
      Stories
    */
    {
      path: "/stories",
      name: "stories",
      component: Stories
    },
    /*
      Toolkit
    */
    {
      path: "/toolkit",
      redirect: "/campaign"
    },
    {
      path: "/campaign",
      name: "toolkit",
      component: Toolkit
    },
    /*
      Wizard
    */
    {
      path: "/cooling-solutions",
      name: "wizard",
      component: Wizard
    },
    {
      path: "/cooling-solutions/:section_uid",
      name: "wizard-section",
      component: WizardSection
    },
    {
      path: "/cooling-solutions/generate",
      name: "wizard-generate",
      component: WizardGenerate
    },
    {
      path: "/cooling-solutions/result",
      name: "wizard-result",
      component: WizardResult
    },
    {
      path: "/cooling-solutions-test",
      name: "wizard-test",
      component: WizardTest
    },
    /* 
      Solutions
    */
    {
      path: "/solutions",
      name: "solutions",
      component: Solutions
    },
    /*
      Prismic Preview
    */
    {
      path: "/preview",
      name: "preview",
      component: Preview
    },
    /*
      404
    */
    {
      path: "/not-found",
      name: "not-found",
      component: NotFound
    },
    /*
      Redirects
    */
    { path: "*", redirect: { name: "not-found" } }
  ],

  scrollBehavior(to, from, savedPosition) {
    if (to.hash)
      return {
        selector: to.hash,
        offset: { x: 0, y: 10 }
      };
    else if (savedPosition)
      return savedPosition;
    else {
      document.getElementById("app").scrollIntoView();
      return { x: 0, y: 0 };
    }
  }
});

export default router;
