<template>
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 19 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Instagram</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.498039 0 0 0 0 0.772549 0 0 0 0 0.815686 0 0 0 1.000000 0"
        ></feColorMatrix>
      </filter>
    </defs>
    <g
      id="Desktop"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Cool-Home-Two" transform="translate(-139.000000, -4712.000000)">
        <g id="FOOTER" transform="translate(-126.000000, 4522.000000)">
          <g id="Lower-Footer" transform="translate(126.000000, 148.000000)">
            <g filter="url(#filter-1)" id="social">
              <g transform="translate(34.000000, 41.000000)">
                <g
                  id="003-instagram-logo"
                  transform="translate(105.000000, 1.000000)"
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                >
                  <path
                    d="M13.7565745,0 L5.24320072,0 C2.3521016,0 0,2.35221398 0,5.2433131 L0,13.7566869 C0,16.6478984 2.3521016,19 5.24320072,19 L13.7565745,19 C16.6478984,19 19,16.647786 19,13.7566869 L19,5.2433131 C19,2.35221398 16.6478984,0 13.7565745,0 Z M17.3143403,13.7566869 C17.3143403,15.7183637 15.7183637,17.3142279 13.7566869,17.3142279 L5.24320072,17.3142279 C3.28163632,17.3142279 1.68577208,15.7183637 1.68577208,13.7566869 L1.68577208,5.2433131 C1.68577208,3.28174871 3.28163632,1.68577208 5.24320072,1.68577208 L13.7565745,1.68577208 C15.7182513,1.68577208 17.3143403,3.28174871 17.3143403,5.2433131 L17.3143403,13.7566869 L17.3143403,13.7566869 Z"
                    id="Shape"
                  ></path>
                  <path
                    d="M9,5 C6.24295847,5 4,7.24300996 4,10.0001148 C4,12.7571048 6.24295847,15 9,15 C11.7570415,15 14,12.7571048 14,10.0001148 C14,7.24300996 11.7570415,5 9,5 Z M9,13.2782012 C7.19238804,13.2782012 5.72164451,11.8076535 5.72164451,10 C5.72164451,8.19223176 7.19227326,6.72156926 9,6.72156926 C10.8077267,6.72156926 12.2783555,8.19223176 12.2783555,10 C12.2783555,11.8076535 10.807612,13.2782012 9,13.2782012 Z"
                    id="Shape"
                  ></path>
                  <path
                    d="M15,3 C14.7372727,3 14.4791818,3.10636364 14.2936364,3.29272727 C14.1071818,3.47818182 14,3.73636364 14,4 C14,4.26281818 14.1072727,4.52090909 14.2936364,4.70727273 C14.4790909,4.89272727 14.7372727,5 15,5 C15.2636364,5 15.5209091,4.89272727 15.7072727,4.70727273 C15.8936364,4.52090909 16,4.26272727 16,4 C16,3.73636364 15.8936364,3.47818182 15.7072727,3.29272727 C15.5218182,3.10636364 15.2636364,3 15,3 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Instagram"
};
</script>
