<template>
	<div>
		<!--- Content --->
		<WizardQuestionContent :question="question" />

		<!--- Number Input --->
		<div class="d-flex w-lg-25-percent mt-4 mb-3">
			<b-form-input 
				type="number" 
				v-model="question.fieldValue"
				class="p-3 text-center w-100"
				style="font-size: 1.5rem;"
				:class="question.fieldUnsure ? 'opacity-50' : ''"
				:disabled="question.fieldUnsure"
				placeholder="Enter value"
			/>
		</div>

		<!--- Option Unsure --->
		<WizardQuestionCountry 
			v-if="
				(
					(
						question.fieldCountryValue ||
						question.fieldCountryValue == 0
					) && 
					question.fieldCountryValue >= 0
				) &&
				question.fieldValue == question.fieldCountryValue
			"
		/>

		<WizardQuestionUnsure
			v-else
			:question="question"
		/>
	</div>
</template>
<script>
import WizardQuestionContent from '../../components/wizard/WizardQuestionContent.vue';
import WizardQuestionUnsure from '../../components/wizard/WizardQuestionUnsure.vue';
import WizardQuestionCountry from '../../components/wizard/WizardQuestionUnsure.vue';

export default {
	name: "WizardQuestionNumberInput",
	props: ["question"],
	components: {
		WizardQuestionContent,
		WizardQuestionUnsure,
		WizardQuestionCountry
	},
	mounted() {
		if(!this.question.fieldValue)
			this.question.fieldValue = 0;
	}
};
</script>
