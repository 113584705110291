<template>
	<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
		<defs>
			<clipPath id="clip-path-policy">
				<path style="fill: none;" d="M17.88 32.36a2.12 2.12 0 012.24 0 .92.92 0 00.48.14.86.86 0 00.63-.27V35l-1.91-1a.74.74 0 00-.68 0l-1.91 1v-2.77a.9.9 0 00.64.27.92.92 0 00.48-.14m2.3-10.29a3.75 3.75 0 002.06 1.21 3.76 3.76 0 001.2 2.11 3.84 3.84 0 000 2.46 3.78 3.78 0 00-1.2 2.1 3.66 3.66 0 00-1.75.91 3.63 3.63 0 00-3 0 3.66 3.66 0 00-1.7-.86 3.73 3.73 0 00-1.2-2.1 3.84 3.84 0 000-2.46 3.84 3.84 0 001.2-2.11 3.72 3.72 0 002.06-1.21 4.11 4.11 0 001.18.2 4.16 4.16 0 001.18-.2M31 14.53a.8.8 0 01.35.19l3.93 3.94a.79.79 0 01.2.34H31zm-8.25 16.84l.21-.06a.89.89 0 00.64-.66 3 3 0 011.13-2 1 1 0 00.24-.91 2.94 2.94 0 010-2.29 1 1 0 00-.24-.91 3 3 0 01-1.13-2 .91.91 0 00-.64-.66 2.87 2.87 0 01-2-1.15.84.84 0 00-.5-.24v-5.24a.76.76 0 01.75-.75h8.29v4.88a1.12 1.12 0 001.13 1.12h4.87v14.25a.76.76 0 01-.75.75h-12zM21.25 13A2.25 2.25 0 0019 15.25v5.52a4.45 4.45 0 01-1.13-.24h-.23a.92.92 0 00-.64.27 2.85 2.85 0 01-2 1.15 1 1 0 00-.65.66 2.89 2.89 0 01-1.12 2 1 1 0 00-.24.91 2.94 2.94 0 010 2.29 1 1 0 00.24.91 2.93 2.93 0 011.12 2 1 1 0 00.65.67l.21.05v4.88a.74.74 0 00.36.64.73.73 0 00.73 0l2.7-1.37 2.66 1.33A.84.84 0 0022 37h12.75A2.25 2.25 0 0037 34.75V19.19a2.29 2.29 0 00-.66-1.6l-3.93-3.93a2.28 2.28 0 00-1.59-.66z"/>
			</clipPath>
			<clipPath id="clip-path-policy-2">
				<path style="fill: none;" d="M21.5 24.55l-3.12 3.06L17 26.29a.32.32 0 00-.45 0l-.22.23a.3.3 0 000 .44l1.78 1.78a.33.33 0 00.45 0l3.58-3.51a.33.33 0 000-.45l-.14-.23a.32.32 0 00-.22-.1.37.37 0 00-.23.1"/>
			</clipPath>
		</defs>
		<path fill="#00699f" d="M0 0h50v50H0z"/>
		<g clip-path="url(#clip-path-policy)">
			<path style="fill: #fff;" d="M10.27 10.27h29.45v29.46H10.27z"/>
		</g>
		<g clip-path="url(#clip-path-policy-2)">
			<path style="fill: #fff;" d="M13.55 21.73H25v9.82H13.55z"/>
		</g>
	</svg>
</template>
<script>
export default {
	name: "IconsWizardPolicy"
};
</script>