<template>
	<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
		<defs>
			<clipPath id="clip-path">
				<path style="fill: none;" d="M18 31.84a2.26 2.26 0 102.26-2.26A2.26 2.26 0 0018 31.84"/>
			</clipPath>
			<clipPath id="clip-path-2">
				<path style="fill: none;" d="M18.92 31.84a1.36 1.36 0 101.36-1.36 1.36 1.36 0 00-1.36 1.36"/>
			</clipPath>
		</defs>
		<path style="fill: #a31a43;" d="M0 0h50v50H0z"/>
		<path style="fill: #fff;" d="M33.42 39.31H16.58a2.48 2.48 0 01-2.49-2.49V13.18a2.48 2.48 0 012.49-2.49h16.84a2.48 2.48 0 012.49 2.49v23.64a2.48 2.48 0 01-2.49 2.49"/>
		<path style="fill: #a31a43;" d="M15.86 12.67h18.27v24.84H15.86z"/>
		<path style="fill: #fff; fill-rule: evenodd;" d="M27.6 33.82a.31.31 0 00.44 0l1.1-1.1 1.11 1.1a.31.31 0 00.44 0l.43-.44a.3.3 0 000-.44L30 31.84l1.11-1.1a.31.31 0 000-.44l-.44-.44a.31.31 0 00-.44 0L29.14 31 28 29.86a.31.31 0 00-.44 0l-.44.44a.31.31 0 000 .44l1.1 1.1-1.1 1.1a.31.31 0 000 .44zM20.28 34.1A2.26 2.26 0 1018 31.84a2.26 2.26 0 002.26 2.26"/>
		<g clip-path="url(#clip-path)">
			<path style="fill: #fff;" d="M15.75 27.31h9.06v9.06h-9.06z"/>
		</g>
		<path d="M20.28 33.2a1.36 1.36 0 10-1.36-1.36 1.36 1.36 0 001.36 1.36" fill-rule="evenodd" fill="#a31a43"/>
		<g clip-path="url(#clip-path-2)">
			<path style="fill: #a31a43;" d="M16.66 28.22h7.24v7.24h-7.24z"/>
		</g>
		<path style="fill: #fff; fill-rule: evenodd;" d="M18.84 20.26a.3.3 0 00.41 0l1-1 1 1a.3.3 0 00.41 0l.41-.42a.3.3 0 000-.41l-1-1 1-1a.3.3 0 000-.41l-.41-.42a.3.3 0 00-.41 0l-1 1-1-1a.3.3 0 00-.41 0l-.41.42a.3.3 0 000 .41l1 1-1 1a.3.3 0 000 .41zM26.48 19.09l2.32-2.32a.17.17 0 01.26 0l2.32 2.32a.19.19 0 010 .26l-.12.11a.17.17 0 01-.26 0l-1.8-1.81v8.23a.19.19 0 01-.19.19h-.16a.19.19 0 01-.19-.19v-8.23l-1.8 1.81a.17.17 0 01-.26 0l-.12-.11a.19.19 0 010-.26M20.2 29.77h.16c.14 0 .25-.06.25-.13v-3.75c0-.07-.11-.12-.25-.12h-.16c-.14 0-.25.05-.25.12v3.75c0 .07.11.13.25.13"/>
		<path style="fill: #fff; fill-rule: evenodd;" d="M20 25.65v.17a.26.26 0 00.28.25h8.62a.26.26 0 00.28-.25v-.17a.27.27 0 00-.28-.25h-8.67a.27.27 0 00-.28.25"/>
	</svg>
</template>
<script>
export default {
	name: "IconsWizardService"
};
</script>