<template>
  <div id="app" class="vh-100">
    <router-view />
    <Footer />

    <cookie-consent class="text-center px-2 light-shadow">
      <template slot="message">
        <div style="color: #25265E;">
          This website uses cookies to ensure you get the best experience.
          <a
            href="https://www.seforall.org/privacy-policy"
            target="_blank"
            rel="noopener"
            style="color: #25265E; text-decoration: underline;"
            >Read&nbsp;more</a
          >
        </div>
      </template>
      <template slot="button">
        <button class="btn btn-white ml-3 mt-3 mt-sm-0" style="color: ##7146AD">
          Accept
        </button>
      </template>
    </cookie-consent>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
import CookieConsent from "vue-cookieconsent-component";

export default {
  name: "app",
  components: {
    Footer,
    CookieConsent
  }
};
</script>
<style src="cocoen/dist/css/cocoen.min.css"></style>
