<template>
	<div
		class="wizard-result transition-250"
		:class="loading.page ? 'opacity-0' : ''"
	>
		<div
			class="
				position-fixed top-0 right-0
				w-100 h-100 background-flyout
				pointer-events-all text-primary
				d-flex align-items-center justify-content-center
			"
			:class="showLoader ? 'z-30' : 'z-10'"
			v-if="showFilter"
		>
			<!-- eslint-disable prettier/prettier -->
			<svg v-if="showLoader" xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 animate-rotation" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="scale (-1, 1)" transform-origin="center" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
			</svg>
			<!-- eslint-enable prettier/prettier -->
		</div>
		<Nav v-if="!this.$getMediaDevices.print" :height="this.height" />

		<div class="container-lg">
			<div
				class="min-h-screen mx-xl-10 pb-6 pt-2-print"
				:style="'padding-top: calc(6rem + ' + height + ');'"
			>
				<!--- White Wizard Area --->
				<div class="w-100 min-h-100 bg-white rounded-xl shadow">
					<div
						class="d-flex d-md-none flex-column align-items-center justify-content-center w-100 h-4 text-center bg-yellow"
					>
						The Cooling For All Solutions Tool is in Beta
					</div>
					<!--- Wizard Head --->
					<div
						class="w-100 d-flex justify-content-end border-bottom no-print"
					>
						<!--- PDF Button --->
						<button
							class="
								btn btn-primary text-uppercase text-sm
								d-flex justify-content-between align-items-center
								w-75-percent w-lg-25-percent my-3 mx-auto mx-lg-4
							"
							@click="printScreen"
						>
							<span> Download Report </span>
							<!-- eslint-disable prettier/prettier -->
							<svg xmlns="http://www.w3.org/2000/svg" class="h-2 w-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
								<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
							</svg>
							<!-- eslint-enable prettier/prettier -->
						</button>
					</div>

					<!--- Wizard Body --->
					<div
						v-if="!loading.result && !loading.page"
						class="py-5 mx-4 mx-lg-8"
					>
						<!--- Your Results --->
						<h1 class="h6 text-sm text-secondary">Your Results</h1>
						<WizardResultRisk :yourResults="fields.yourResults" />

						<hr class="border-dashed my-5" />

						<!--- Solutions Title --->
						<h2 class="h1 display-5">Your Cooling Solutions</h2>

						<!--- Solutions Filter Header --->
						<div class="row justify-content-between align-items-center mt-5">
							<!--- Information --->
							<div class="col-11 col-lg-9">
								<!--- Filter Content --->
								<prismic-rich-text
									v-if="fields.filterContent"
									:field="fields.filterContent"
									class="text-dark font-weight-light rich-text-links"
								/>

								<!--- Solutions Count --->
								<p ref="solutions-count"></p>
							</div>

							<!--- Filter Button --->
							<div class="col-12 col-lg-3">
								<button
									@click="showFlyout()"
									class="w-100 p-3 border border-gray bg-white focus:outline-line"
								>
									<div
										class="d-flex justify-content-between align-items-center"
									>
										<p class="mb-0">
											Filter solutions
											<span
												class="d-block"
												v-if="characteristicsRequirementsFormatted.length > 0"
											>
												<!-- eslint-disable-next-line prettier/prettier -->
												( {{ characteristicsRequirementsFormatted.length }} applied )
											</span>
										</p>
										<div class="icon-sm">
											<b-icon icon="chevron-down" aria-hidden="true"></b-icon>
										</div>
									</div>
								</button>
							</div>
						</div>

						<!--- Solutions Filter Flyout --->
						<div
							class="position-fixed d-flex flex-column h-100 z-20 top-0 -right-100 bg-white transition-500"
							ref="filter-flyout"
						>
							<!--- Header Button --->
							<div
								class="d-flex w-100 px-4 pt-4 justify-content-between align-items-center shadow-sm"
							>
								<h4>
									Solution Filters
								</h4>
								<button
									@click="hideFlyout()"
									class="btn hover:opacity-75 transition-200"
								>
									<b-icon class="w-3 h-3" icon="x"></b-icon>
								</button>
							</div>

							<!--- Filters --->
							<div class="flex-auto overflow-y-scroll p-4">
								<div
									v-for="(filter, filterIndex) in fields.filters"
									:key="`filter-${filter.slug}-${filterIndex}-` + Date.now()"
								>
									<!--- Filter --->
									<h3 class="h6">{{ filter.title }}</h3>
									<prismic-rich-text
										:field="filter.subTitle"
									></prismic-rich-text>
									<div class="d-flex flex-wrap mb-4">
										<!-- eslint-disable prettier/prettier -->
										<div
											v-for="
												[optionValue, optionDisplay] of Object.entries(filter.options)
											"
											:key="`filter-${filter.slug}-${filterIndex}-option-${optionValue}-` + Date.now()"
										>
											<label
												class="btn d-flex rounded-pill py-2 px-3 m-2 align-items-center"
												:class="
													characteristicsRequirements[filter.slug] == optionValue
														? 'bg-primary text-white'
														: 'bg-lightest text-dark'
												"
											>
										<!-- eslint-enable prettier/prettier -->
												<input
													class="d-none"
													v-model="characteristicsRequirements[filter.slug]"
													type="radio"
													:value="optionValue"
												/>

												{{ optionDisplay }}
											</label>
										</div>
									</div>
								</div>
							</div>

							<!--- Footer --->
							<div
								class="w-100 h-8 bg-gray-50 p-4 d-flex align-items-center justify-content-center shadow-lg"
							>
								<button
									class="btn bg-lightest text-dark mx-4"
									@click="resetCharacteristicsRequirements()"
								>
									Clear Filters
								</button>
								<button
									class="btn bg-primary text-white mx-4"
									@click="setCharacteristicsRequirements()"
								>
									Apply Filters
								</button>
							</div>
						</div>

						<!--- Solutions --->
						<div class="border border-lightest rounded-lg my-5">
							<!--- Need Result --->
							<WizardResultNeed
								class="p-4"
								:solutionsNeed="fields.solutionsNeed"
							/>

							<!--- Pillars & Solutions --->
							<!-- eslint-disable prettier/prettier -->
							<WizardResultSolutions
								class="p-md-4"
								v-if="!loading.solutions"
								:approachInfo="fields.approachInfo"
								:characteristicsRequirements="characteristicsRequirementsFormatted"
								:solutionsCountElement="solutionsCountElement"
							/>
							<!-- eslint-enable prettier/prettier -->
						</div>

						<hr class="border-dashed my-5 print-page-break" />

						<!--- User's Answers --->
						<h2 class="h1 display-5">Your Answers</h2>
						<WizardResultAnswers />

						<!--- API Response --->
						<!--
							<h2 class="h1 display-5"> API Response </h2>
							<WizardResultApiResponse />
						-->

						<hr class="border-dashed my-5 print-page-break" />

						<!--- Worked Out --->
						<h2 class="h1 display-5">{{ fields.workedOut.title }}</h2>
						<prismic-rich-text
							v-if="fields.workedOut.desc"
							:field="fields.workedOut.desc"
							class="mb-5"
						/>
						<prismic-image
							class="w-100 rounded object-contain"
							v-if="fields.workedOut.image"
							:field="fields.workedOut.image"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Nav from "@/components/Nav.vue";
import WizardResultRisk from "@/components/wizard/WizardResultRisk.vue";
import WizardResultNeed from "@/components/wizard/WizardResultNeed.vue";
import WizardResultSolutions from "@/components/wizard/WizardResultSolutions.vue";
import WizardResultAnswers from "@/components/wizard/WizardResultAnswers.vue";
// import WizardResultApiResponse from "@/components/wizard/WizardResultApiResponse.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
	name: "wizard-result",
	components: {
		Nav,
		WizardResultRisk,
		WizardResultNeed,
		WizardResultSolutions,
		WizardResultAnswers
	},
	metaInfo() {
		return {
			title: this.page.pageTitle
		};
	},
	mounted() {
		this.getContent();

		let multiplier = 0.5;

		if (this.$getMediaBreakpoints.md) multiplier = 1.1;

		if (this.$getMediaBreakpoints.lg) multiplier = 0.65;

		this.height = 200 * multiplier + "px";
	},
	data() {
		return {
			height: "130px",
			fields: {
				resultBound: {
					lower: 1.5,
					upper: 2.5
				},
				filters: [
					/*
					{
						title: "Energy Saving",
						slug: "energySaving",
						options: {
							1: "< 25",
							2: "25 - 70",
							3: "> 70"
						}
					}
					*/
				],
				yourResults: {
					lowRisk: null,
					mediumRisk: null,
					highRisk: null
				},
				workedOut: {
					title: null,
					desc: null,
					image: null
				},
				approachInfo: {
					title: null,
					desc: null
				},
				solutionsNeed: {
					comfort: null,
					nutrition: null,
					health: null
				}
			},
			page: {
				pageTitle: null,
				pageDesc: null,
				pageTags: null
			},
			loading: {
				route: false,

				// Set Loading
				page: true,
				result: true,
				solutions: true
			},
			showFilter: false,
			showLoader: false,
			/* eslint-disable prettier/prettier */
			characteristicsRequirements: {
				"capitalCost": 0,
				"energySaving": 0,
				"energySource": 0,
				"integrateEase": 0,
				"operatingCost": 0,
				"refGwp": 0
			},
			/* eslint-enable prettier/prettier */
			characteristicsRequirementsFormatted: [],
			characteristicsRequirementsCount: 0,
			solutionsCountElement: null
		};
	},
	computed: {
		...mapGetters({
			getUserExists: "wizardToolUser/getUserExists",
			getUserConditional: "wizardToolUser/getUserConditional",
			getUserRisk: "wizardToolUser/getUserRisk",

			getPrismicPage: "prismicPage/getPrismicPageByName"
		})
	},
	methods: {
		...mapMutations({
			setUserRiskResult: "wizardToolUser/setUserRiskResult"
		}),

		...mapActions({
			fetchPrismicPage: "prismicPage/fetchPrismicPage",
			setupUserSolutions: "wizardToolUser/setupUserSolutions"
		}),

		async getContent() {
			// Set Loading
			this.loading.page = true;
			this.loading.result = true;
			this.loading.solutions = true;

			// Check User
			if (this.getUserExists) {
				// Get from store
				var document = this.getPrismicPage("wizard_result_page");

				// Get from API
				if (!document)
					document = await this.fetchPrismicPage("wizard_result_page");

				// Check, parse, after-content
				if (document) {
					// Parse Page
					this.parseDocument(document);

					// Stop Page Loading
					this.loading.page = false;

					// After Content
					this.afterContent();
				} else this.$router.push({ name: "wizard" });
			} else this.$router.push({ name: "wizard" });
		},

		parseDocument(document) {
			if (document) {
				// Page
				this.page.pageTitle = document.data.page_title;
				this.page.pageDesc = document.data.page_description;
				this.page.pageTags = document.data.page_keywords;

				// Risk Bounds
				this.fields.resultBound.lower = document.data.result_bound_lower
					? document.data.result_bound_lower
					: 1.5;

				this.fields.resultBound.upper = document.data.result_bound_upper
					? document.data.result_bound_upper
					: 2.5;

				// Filters
				this.fields.filterContent = document.data.result_filter_content;

				/* eslint-disable prettier/prettier */
				if (document.data.body.length > 0)
					for (
						const resultsFilter
						of
						document.data.body.filter(slice => slice.slice_type == "results_filter")
					) {
						// Map's the Prismic Item Array to a Value: Label Object
						const filterOptions = {};
						resultsFilter.items.forEach(filterOption => {
							filterOptions[filterOption.filter_option_value] =
								filterOption.filter_option_label;
						});

						this.fields.filters.push({
							"title": 	resultsFilter.primary.filter_title,
							"subTitle": resultsFilter.primary.filter_subtitle,
							"slug": 	resultsFilter.primary.filter_slug,
							"options": 	filterOptions
						});
					}

				// Your Results
				this.fields.yourResults.lowRisk = document.data.your_results_low_risk;
				this.fields.yourResults.mediumRisk = document.data.your_results_medium_risk;
				this.fields.yourResults.highRisk = document.data.your_results_high_risk;

				// Worked Out
				this.fields.workedOut.title = document.data.worked_out_title;
				this.fields.workedOut.desc = document.data.worked_out_description;
				this.fields.workedOut.image = document.data.worked_out_image;

				// Approach Info
				this.fields.approachInfo.title = document.data.approach_info_title;
				this.fields.approachInfo.desc = document.data.approach_info_description;

				// Solutions Need
				this.fields.solutionsNeed.comfort = document.data.solutions_need_comfort;
				this.fields.solutionsNeed.nutrition = document.data.solutions_need_nutrition;
				this.fields.solutionsNeed.health = document.data.solutions_need_health;

				/* eslint-enable prettier/prettier */
			}
		},
		async afterContent() {
			var riskResult = 0;

			// Check over Cooling Need for the right Result to use
			switch (this.getUserConditional.coolingNeed) {
				case "comfort":
					riskResult = this.getUserRisk.hc.result;
					break;

				case "health":
					riskResult = this.getUserRisk.hs.result;
					break;

				default:
					riskResult = this.getUserRisk.fn.result;
					break;
			}

			// Check if it's in the Low Bound
			if (riskResult > this.fields.resultBound.upper)
				this.setUserRiskResult("high");
			else if (riskResult > this.fields.resultBound.lower)
				this.setUserRiskResult("medium");
			else this.setUserRiskResult("low");

			// Stop Result Loading
			this.loading.result = false;

			// Setup Intro Questions
			await this.setupUserSolutions();

			// Call Set Solutions Count Element
			this.setSolutionsCountElement();

			// Stop Loading Solutions
			this.loading.solutions = false;
		},

		setCharacteristicsRequirements() {
			// Show Loader
			this.showLoader = true;

			// Reset Data
			this.characteristicsRequirementsFormatted = [];

			// Loop over the key: value object and format into array
			// eslint-disable-next-line prettier/prettier
			for (const [characteristic, minimumStr] of Object.entries(this.characteristicsRequirements))  {
				const minimum = parseInt(minimumStr);
				if (!minimum || isNaN(minimum)) continue;
				if (minimum > 0)
					this.characteristicsRequirementsFormatted.push({
						characteristic,
						minimum
					});
			}

			// Hide Loader
			window.setTimeout(() => {
				this.showLoader = false;
			}, 1500);

			// Call Set Solutions Count Element
			this.setSolutionsCountElement();
		},
		resetCharacteristicsRequirements() {
			/* eslint-disable prettier/prettier */
			this.characteristicsRequirements = {
				"capitalCost": 0,
				"energySaving": 0,
				"energySource": 0,
				"integrateEase": 0,
				"operatingCost": 0,
				"refGwp": 0
			};
			/* eslint-enable prettier/prettier */

			this.setCharacteristicsRequirements();
		},

		async showFlyout() {
			// Show Element
			this.showFilter = true;

			// Get Flyout Element
			const flyoutElement = await this.$refs["filter-flyout"];
			if (!flyoutElement) {
				this.showFilter = false;
				return;
			}

			// Lock Body
			document.body.classList.add("overflow-hidden");

			// Fly the Element
			flyoutElement.classList.add("right-0");
			window.setTimeout(
				() => flyoutElement.classList.remove("-right-100"),
				125
			);
		},
		async hideFlyout() {
			// Get Flyout Element
			const flyoutElement = await this.$refs["filter-flyout"];
			if (!flyoutElement) {
				this.showFilter = false;
				return;
			}

			// Fly the Element
			flyoutElement.classList.add("-right-100");
			// eslint-disable-next-line prettier/prettier
			window.setTimeout(
				() => flyoutElement.classList.remove("right-0"),
				125
			);

			// Unlock Body
			document.body.classList.remove("overflow-hidden");

			// Hide Element
			this.showFilter = false;
		},

		async setSolutionsCountElement() {
			// Set Solutions Count Element by Ref
			this.solutionsCountElement = await this.$refs["solutions-count"];
		},

		printScreen() {
			if (window) window.print();
		}
	}
};
</script>
<style lang="scss">
.wizard-result {
	background: #d9eef1;
	background-image: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.5) 0%,
		#caeaee 29%,
		#d9eef1 100%
	);
}
</style>
