import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";

import prismicPage from "@/store/prismic-page"
import prismicWizardTool from "@/store/prismic-wizard-tool"
import wizardToolUser from "@/store/wizard-tool-user"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    prismicPage,
    prismicWizardTool: prismicWizardTool,
    wizardToolUser: wizardToolUser
  }
  // plugins: [createPersistedState()],
});
