<template>
	<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
		<defs>
			<clipPath id="clip-path">
				<path style="fill: none;" d="M23.58 23.82c0-1.8 1.17-3.26 2.6-3.26s2.61 1.46 2.61 3.26-1.17 3.25-2.61 3.25-2.6-1.46-2.6-3.25m-1.3 0c0 2.51 1.75 4.55 3.9 4.55s3.91-2 3.91-4.55-1.75-4.56-3.91-4.56-3.9 2-3.9 4.56m-7.81-6.51h2.6a2.6 2.6 0 01-2.6 2.6zm3.91 13a3.91 3.91 0 00-3.91-3.9v-5.2a3.91 3.91 0 003.91-3.9H34a3.91 3.91 0 003.91 3.9v5.21a3.91 3.91 0 00-3.91 3.9h1.3a2.61 2.61 0 012.61-2.6v2.6H14.47v-2.6a2.61 2.61 0 012.6 2.6zm16.91-13h2.61v2.6a2.6 2.6 0 01-2.61-2.6M14.47 16a1.3 1.3 0 00-1.3 1.3v13a1.31 1.31 0 001.3 1.31H37.9a1.31 1.31 0 001.3-1.31v-13a1.3 1.3 0 00-1.3-1.3z"/>
			</clipPath>
			<clipPath id="clip-path-2">
				<path style="fill: none;" d="M11.58 18.79h24.85v14.79H11.58z"/>
			</clipPath>
			<clipPath id="clip-path-3">
				<path style="fill: none;" d="M21.21 26.18c0-1.79 1.17-3.25 2.61-3.25s2.6 1.46 2.6 3.25-1.17 3.26-2.6 3.26-2.61-1.44-2.61-3.26m-1.3 0c0 2.52 1.75 4.56 3.91 4.56s3.9-2 3.9-4.56-1.75-4.55-3.9-4.55-3.91 2-3.91 4.55m-7.8-6.5h2.6a2.61 2.61 0 01-2.6 2.6zm3.9 13a3.91 3.91 0 00-3.9-3.9v-5.2a3.91 3.91 0 003.9-3.9h15.62a3.91 3.91 0 003.9 3.9v5.21a3.91 3.91 0 00-3.9 3.9h1.3a2.6 2.6 0 012.6-2.6v2.6H12.11v-2.6a2.6 2.6 0 012.6 2.6zm16.92-13h2.6v2.6a2.61 2.61 0 01-2.6-2.6m-20.82-1.31a1.31 1.31 0 00-1.31 1.31v13A1.31 1.31 0 0012.11 34h23.42a1.3 1.3 0 001.3-1.3v-13a1.31 1.31 0 00-1.3-1.31z"/>
			</clipPath>
		</defs>
		<path style="fill: #1a496a;" d="M0 0h50v50H0z"/>
		<g clip-path="url(#clip-path)">
			<path style="fill: #fff;" d="M10.21 13.05h31.94v21.53H10.21z"/>
		</g>
		<path style="fill: #1a496a;" d="M11.58 18.79h24.84v14.79H11.58z"/>
		<g clip-path="url(#clip-path-2)">
			<path style="fill: #1a496a;" d="M8.62 15.83h30.76v20.7H8.62z"/>
		</g>
		<path d="M23.82 29.44c-1.44 0-2.61-1.46-2.61-3.26s1.17-3.25 2.61-3.25 2.6 1.46 2.6 3.25-1.17 3.26-2.6 3.26m0-7.81c-2.16 0-3.91 2-3.91 4.55s1.75 4.56 3.91 4.56 3.9-2 3.9-4.56-1.75-4.55-3.9-4.55m11.71.65a2.6 2.6 0 01-2.6-2.6h2.6zm0 6.51a3.91 3.91 0 00-3.9 3.9h1.3a2.59 2.59 0 012.6-2.6v2.6H12.11v-2.6a2.6 2.6 0 012.6 2.6H16a3.91 3.91 0 00-3.9-3.9v-5.21a3.91 3.91 0 003.9-3.9h15.63a3.91 3.91 0 003.9 3.9zm-23.42-9.11h2.6a2.61 2.61 0 01-2.6 2.6zm23.42-1.3H12.11a1.31 1.31 0 00-1.31 1.3v13A1.31 1.31 0 0012.11 34h23.42a1.3 1.3 0 001.3-1.3v-13a1.3 1.3 0 00-1.3-1.3" fill-rule="evenodd" fill="#fff"/>
		<g clip-path="url(#clip-path-3)">
			<path style="fill: #fff;" d="M7.85 15.42h31.94v21.53H7.85z"/>
		</g>
	</svg>
</template>
<script>
export default {
	name: "IconsWizardFinancial"
};
</script>
