<template>
	<div
		class="wizard-generate transition-250"
		:class="loading.page ? 'opacity-0' : ''"
	>
		<Nav :height="this.height" />

		<div class="container-lg">
			<div class="h-screen mx-xl-10 pb-6" :style="'padding-top: calc(6rem + ' + height + ');'">
				<div class="d-flex d-md-none flex-column align-items-center justify-content-center w-100 h-4 text-center bg-yellow">
					The Cooling For All Solutions Tool is in Beta
				</div>
				<!--- White Wizard Area --->
				<div
					class="
						w-100 h-100 text-center
						bg-white rounded-xl shadow
						d-flex flex-column justify-content-center align-items-center
					"
				>
					<h2 class="h1">Generating Results...</h2>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Nav from "@/components/Nav.vue";
import Vue from "vue";

import { mapMutations, mapGetters } from "vuex";

export default {
	name: "wizard-generate",
	components: {
		Nav
	},
	metaInfo() {
		return {
			title: "Generating..."
		}
	},
	mounted() {
		this.getContent();

		let multiplier = 0.5;

		if (this.$getMediaBreakpoints.md)
			multiplier = 1.1;

		if (this.$getMediaBreakpoints.lg)
			multiplier = 0.65;

		this.height = 200 * multiplier + "px";
	},
	data() {
		return {
			height: "130px",
			calculatorAttempts: 0,
			loading: {
				route: false,
				calculation: false,

				// Set Loading
				page: true
			}
		}
	},
	computed: {
		...mapGetters({
			getUserExists: "wizardToolUser/getUserExists",
			getUserFields: "wizardToolUser/getUserFields"
		})
	},
	methods: {
		...mapMutations({
			setUserRisk: "wizardToolUser/setUserRisk",
			setApiResponse: "wizardToolUser/setApiResponse"
		}),

		async getContent() {
			// Set Loading
			this.loading.page = true;

			// Check User
			if(this.getUserExists){
				// Stop Loading Page
				this.loading.page = false;

				// After Content
				this.afterContent();
			}
			else
				this.$router.push({ name: 'wizard' });
		},
		afterContent() {
			this.doWizardRiskCalculation();
		},

		async doWizardRiskCalculation() {
			// Set Loading
			this.loading.calculation = true;
			this.loading.route = true;

			// Call Calculation
			await Vue.prototype.$wizardRiskCalculator
				.sendWizardRiskCalculator(this.getUserFields)
				.then(
					(response) => {
						// Check Response
						if (
							!response ||
							response.status < 200 ||
							response.status >= 300 ||
							!response.data ||
							!response.data.data
						) {
							this.calculatorAttempts++;

							// Try again
							if(this.calculatorAttempts < 3)
								window.setTimeout(
									() => this.doWizardRiskCalculation(),
									1000
								);

							// Error Out to Wizard
							else
								this.$router.push(
									{
										name: 'wizard'
									}
								).then(
									() => this.loading.route = false
								);
						}
						else
							// Delay Response
							window.setTimeout(
								() => {
									// Stop Loading Calculation
									this.loading.calculation = false;

									// Set User Risk
									this.setUserRisk(response.data.data);

									// Go to Results
									this.$router.push(
										{
											name: 'wizard-result'
										}
									).then(
										() => this.loading.route = false
									);
								},
								1500
							);

						this.setApiResponse(response.data)
					}
				);
		}
	}
};
</script>
<style lang="scss">
.wizard-generate {
	background: #d9eef1;
    background-image: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.5) 0%,
		#caeaee 29%,
		#d9eef1 100%
    );
}
</style>