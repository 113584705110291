<template>
	<div class="home opacity-0 transition-500">
		<Nav :height="this.height" :button="fields.navButton" />

		<!-- Hero -->
		<div class="hero home">
			<div
				class="container-design d-flex px-4"
				:style="{ paddingTop: this.height }"
			>
				<!--- Centering --->
				<div class="row justify-content-center align-items-center pb-5">
					<!--- Content --->
					<div class="col-md-10 col-lg-6 mb-4 mb-md-0 order-lg-2">
						<h1 class="display-4 mb-4">
							{{ fields.hero.headline }}
						</h1>
						<prismic-rich-text
							class="lead text-dark strong:font-weight-bold"
							style="color: #163B41;"
							v-if="fields.hero.content"
							:field="fields.hero.content"
						/>
					</div>

					<!--- Video --->
					<div class="col-md-10 col-lg-6 mb-4 mb-md-0 order-lg-1">
						<div class="position-relative" style="padding-top: 56.25%">
							<iframe
								v-if="fields.hero.video"
								class="w-100 h-100 mt-3 shadow-lg top-0 left-0 position-absolute"
								width="560"
								height="315"
								:src="fields.hero.video.url"
								title="YouTube video player"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Three Atoms -->
		<Atoms class="pb-md-5" :atoms="fields.atoms" />

		<!-- Image Slider Right -->
		<FeatureRight :feature="fields.featureRight" />

		<!-- Image Slider Left -->
		<FeatureLeft
			:feature="fields.featureLeft"
			:backgroundGradient="
				'linear-gradient(180deg, #fff 0%, #fff 90%, #d9eef1 105%, #eaf7f8 110%)'
			"
			class="pb-5 pb-md-0"
		/>

		<!-- Newsletter -->
		<Newsletter :newsletter="fields.newsletter" />
	</div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Newsletter from "@/components/Newsletter.vue";
import FeatureLeft from "@/components/FeatureLeft.vue";
import FeatureRight from "@/components/FeatureRight.vue";
import Atoms from "@/components/Atoms.vue";

import Cocoen from "cocoen";
import bodymovin from "lottie-web";

import { mapGetters, mapActions } from "vuex";

export default {
	name: "home",
	components: {
		Nav,
		Newsletter,
		FeatureLeft,
		FeatureRight,
		Atoms
	},
	metaInfo() {
		return {
			title: this.page.pageTitle,
			meta: [
				{
					vmid: "description",
					name: "description",
					content: this.page.pageDesc
				},
				{
					vmid: "keywords",
					name: "keywords",
					content: this.page.pageTags
				},
				{
					vmid: "og:title",
					name: "og:title",
					content: this.page.pageTitle
				},
				{
					vmid: "og:description",
					name: "og:description",
					content: this.page.pageDesc
				},
				{
					vmid: "og:image",
					name: "og:image",
					content: this.fields.hero.image && this.fields.hero.image.url
				},
				{
					vmid: "og:url",
					name: "og:url",
					content: window.location.href.replace(location.hash, "")
				},
				{
					vmid: "twitter:card",
					name: "twitter:card",
					content: "summary_large_image"
				},
				{
					vmid: "twitter:title",
					name: "twitter:title",
					content: this.page.pageTitle
				},
				{
					vmid: "twitter:description",
					name: "twitter:description",
					content: this.page.pageDesc
				},
				{
					vmid: "twitter:image",
					name: "twitter:image",
					content: this.fields.hero.image && this.fields.hero.image.url
				}
			]
		};
	},
	mounted() {
		this.getContent();

		let multiplier = 0.8;

		if (this.$getMediaBreakpoints.md) multiplier = 1.4;

		if (this.$getMediaBreakpoints.lg) multiplier = 1.1;

		this.height = 200 * multiplier + "px";

		bodymovin.loadAnimation({
			container: document.getElementById("bm"),
			renderer: "svg",
			loop: true,
			autoplay: true,
			path: "/animations/data.json"
		});
	},
	data() {
		return {
			height: "130px",
			cocoenPos: [],
			oldScrollY: 0,
			fields: {
				navButton: {
					download: null,
					downloadText: null
				},
				hero: {
					image: null,
					video: null,
					headline: null,
					content: null
				},
				featureRight: {
					title: null,
					heading: null,
					content: null,
					cards: null,
					imageLeft: null,
					imageRight: null
				},
				featureLeft: {
					title: null,
					heading: null,
					content: null,
					cards: null,
					imageLeft: null,
					imageRight: null
				},
				atoms: {
					heading: null,
					content: null,
					cards: null
				},
				toolkit: {
					heading: null,
					content: null,
					download: null,
					downloadText: null
				},
				reasons: {
					heading: null,
					content: null,
					cards: null
				},
				newsletter: {
					heading: null
				}
			},
			page: {
				pageTitle: null,
				pageDesc: null,
				pageTags: null
			}
		};
	},
	computed: {
		...mapGetters({
			getPrismicPage: "prismicPage/getPrismicPageByName"
		})
	},
	methods: {
		...mapActions({
			fetchPrismicPage: "prismicPage/fetchPrismicPage"
		}),

		async getContent() {
			// Get from store
			var document = this.getPrismicPage("home_page");

			// Get from API
			if (!document) document = await this.fetchPrismicPage("home_page");

			// Check, parse, after-content
			if (document) {
				this.parseDocument(document);
				this.afterContent();
			}
		},
		afterContent() {
			this.doFadeIn();
			this.startCocoen();
			this.setupCocenScroll();
		},
		doFadeIn() {
			document.querySelector(".home").classList.remove("opacity-0");
		},
		startCocoen() {
			let that = this;
			window.setTimeout(function() {
				document.querySelectorAll(".cocoen").forEach(function(ele) {
					that.cocoenPos.push({
						position: ele.getBoundingClientRect().top,
						element: ele
					});
					ele.classList.remove("opacity-0");
					new Cocoen(ele);
				});
			}, 1000);
		},
		setupCocenScroll() {
			let that = this;
			window.addEventListener("scroll", function(event) {
				if (window.scrollY - 250 >= that.oldScrollY) {
					that.oldScrollY = window.scrollY;
					that.cocoenPos.forEach(function(cocoen) {
						if (cocoen.position <= that.oldScrollY + 500) {
							cocoen.element.firstElementChild.classList.add("cocoen-w-50");
							cocoen.element.firstElementChild.classList.add("transition-1000");

							cocoen.element.lastElementChild.classList.add("left-50");
							cocoen.element.lastElementChild.classList.add("transition-1000");

							window.setTimeout(function() {
								cocoen.element.firstElementChild.classList.remove(
									"transition-1000"
								);
								cocoen.element.lastElementChild.classList.remove(
									"transition-1000"
								);
							}, 1000);
						}
					});
				}
			});
		},
		parseDocument(document) {
			if (document) {
				// Page
				this.page.pageTitle = document.data.page_title;
				this.page.pageDesc = document.data.page_description;
				this.page.pageTags = document.data.page_keywords;

				// Nav
				this.fields.navButton.download = document.data.nav_button_link;
				this.fields.navButton.downloadText = document.data.nav_button_text;

				// Hero
				this.fields.hero.video = document.data.hero_video;
				this.fields.hero.image = document.data.hero_image;
				this.fields.hero.headline = document.data.hero_headline;
				this.fields.hero.content = document.data.hero_content;

				// Feature Right
				this.fields.featureRight.title = document.data.feature_right_title;
				this.fields.featureRight.heading = document.data.feature_right_heading;
				this.fields.featureRight.content = document.data.feature_right_content;
				this.fields.featureRight.cards = document.data.feature_right_cards;

				this.fields.featureRight.imageLeft =
					document.data.feature_right_image_left;
				this.fields.featureRight.imageRight =
					document.data.feature_right_image_right;

				// Feautre Left
				this.fields.featureLeft.title = document.data.feature_left_title;
				this.fields.featureLeft.heading = document.data.feature_left_heading;
				this.fields.featureLeft.content = document.data.feature_left_content;
				this.fields.featureLeft.cards = document.data.feature_left_cards;

				this.fields.featureLeft.imageLeft =
					document.data.feature_left_image_left;
				this.fields.featureLeft.imageRight =
					document.data.feature_left_image_right;

				// Atoms
				this.fields.atoms.heading = document.data.atoms_heading;
				this.fields.atoms.content = document.data.atoms_content;
				this.fields.atoms.cards = document.data.atoms_cards;

				// Toolkit
				this.fields.toolkit.heading = document.data.toolkit_heading;
				this.fields.toolkit.content = document.data.toolkit_content;
				this.fields.toolkit.download = document.data.toolkit_download;
				this.fields.toolkit.downloadText = document.data.toolkit_download_text;

				// Reasons
				this.fields.reasons.heading = document.data.reasons_heading;
				this.fields.reasons.content = document.data.reasons_content;
				this.fields.reasons.cards = document.data.reasons_cards;

				// Newsletter
				this.fields.newsletter.heading = document.data.newsletter_heading;
			}
		}
	}
};
</script>
<style lang="scss">
.hero {
	&.home {
		min-height: 100%;
		background: #d9eef1;
		background-image: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.5) 0%,
			#caeaee 29%,
			#d9eef1 100%
		);
	}
}
</style>
