<template>
	<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
		<path d="M10 0h30a10 10 0 0110 10v40H0V10A10 10 0 0110 0z" fill="#058ba1"/>
		<path fill="#fff" d="M13.71 24.19a2.42 2.42 0 112.42 2.42 2.42 2.42 0 01-2.42-2.42m-1.62 0a4 4 0 104-4 4 4 0 00-4 4m11.3-2.42h12.1a4 4 0 014 4v4h-16.1zM9.27 15.32a.4.4 0 00-.4.4v18.56a.4.4 0 00.4.4h.81a.4.4 0 00.4-.4v-2.83h29v2.83a.4.4 0 00.41.4h.8a.4.4 0 00.4-.4v-8.47a5.64 5.64 0 00-5.64-5.65H22.58a.81.81 0 00-.81.81v8.87H10.48V15.72a.4.4 0 00-.4-.4z" />
	</svg>
</template>
<script>
export default {
	name: "IconsWizardHealthCare"
};
</script>
