<template>
	<div>
		<!--- Content --->
		<WizardQuestionContent :question="question" />

		<!--- Range --->
		<div 
			class="pt-5 position-relative d-flex"
			:class="question.fieldUnsure ? 'opacity-50' : ''"
		>
			<!--- Slider --->
			<div 
				class="
					flex-auto ml-2 mx-md-0 h-1-5 position-relative
					border border-gray rounded-pill pointer-events-none
				"
			>
				<!--- Background --->
				<div 
					class="h-100 rounded-pill-left transition-125"
					:class="
						question.fieldValue == question.fieldCountryValue
							? 'bg-primary'
							: question.fieldUnsure
								? 'bg-gray'
								: 'bg-green'
					"
					:style="
						'width: ' + 
						(
							(question.fieldValue >= 0 ? question.fieldValue : 0) 
							/ question.fieldMax
						) * 100 + 
						'%;'
					"
				>
				</div>

				<!--- Dragable Dot --->
				<div 
					class="
						position-absolute
						bg-white h-2 w-2 transition-125
						border border-gray rounded-circle
					"
					:style="
						'top: -0.25rem; left: calc(' + 
						(
							(question.fieldValue >= 0 ? question.fieldValue : 0)
							/ question.fieldMax
						) * 100 + 
						'% - 1rem);'
					"
				>
				</div>

				<!--- Input Overlay --->
				<input
					type="range"
					step="1" 
					min="0" 
					:max="question.fieldMax" 
					v-model="question.fieldValue"
					:disabled="question.fieldUnsure"
					class="position-absolute opacity-0 top-0 pointer-events-all"
					:class="question.fieldUnsure ? 'cursor-not-allowed' : ''"
					style="width: calc(100% + 1rem); height: calc(100% + 1rem); top: -0.5rem;"
				/>
			</div>

			<!--- Percentage --->
			<p class="w-6 w-lg-12 pl-2 pl-lg-4 pointer-events-none">
				<span
					class="h1"
					:style="question.fieldValue == 0 ? 'color: #EDA576!important;' : ''"
				>
					{{ 
						(
							question.fieldValue >= 0 ? 
								Number(question.fieldValue).toLocaleString() :
								'~'
						) + (
							question.fieldUnits == '%' ? 
								"%" : 
								""
						)
					}}
				</span>
				<span 
					:class="$getMediaBreakpoints.lg ? 'h3' : 'h5'"
					v-if="question.fieldUnits != '%'"
					:style="question.fieldValue == 0 ? 'color: #EDA576!important;' : ''"
				>
					{{ question.fieldUnits }}
				</span>
			</p>
		</div>

		<!--- Option Unsure --->
		<WizardQuestionCountry 
			v-if="
				(
					(
						question.fieldCountryValue ||
						question.fieldCountryValue == 0
					) && 
					question.fieldCountryValue >= 0
				) &&
				question.fieldValue == question.fieldCountryValue
			"
		/>

		<WizardQuestionUnsure
			v-else
			:question="question"
		/>
	</div>
</template>
<script>
import WizardQuestionContent from '../../components/wizard/WizardQuestionContent.vue';
import WizardQuestionUnsure from '../../components/wizard/WizardQuestionUnsure.vue';
import WizardQuestionCountry from '../../components/wizard/WizardQuestionCountry.vue';

export default {
	name: "WizardQuestionRange",
	props: ["question"],
	components: {
		WizardQuestionContent,
		WizardQuestionUnsure,
		WizardQuestionCountry
	}
};
</script>
