<template>
	<div class="d-flex">
		<!--- Image Comfort/Health/Nutrition --->
		<div class="w-75-percent w-lg-25-percent w-xl-15-percent mr-4 mr-lg-5 mb-4 no-print">
			<IconWizardComfortSafety v-if="getUserConditional.coolingNeed == 'comfort'"/>
			<IconWizardFoodNutrition v-if="getUserConditional.coolingNeed == 'nutrition'"/>
			<IconWizardHealthCare v-if="getUserConditional.coolingNeed == 'health'"/>
		</div>

		<!--- Content Comfort/Health/Nutrition --->
		<div>
			<!--- Heading Low/Medium/High Text --->
			<h2 class="h4">
				<span v-if="getUserConditional.coolingNeed == 'comfort'"> 
					Comfort
				</span>
				<span v-else-if="getUserConditional.coolingNeed == 'nutrition'"> 
					Food &amp; Nutrition
				</span>
				<span v-else-if="getUserConditional.coolingNeed == 'health'"> 
					Health
				</span>
			</h2>

			<!--- Desc Low/Medium/High Text --->
			<prismic-rich-text
				v-if="
					solutionsNeed.comfort
					&&
					getUserConditional.coolingNeed == 'comfort'
				"
				:field="solutionsNeed.comfort"
			/>
			<prismic-rich-text
				v-if="
					solutionsNeed.nutrition
					&&
					getUserConditional.coolingNeed == 'nutrition'
				"
				:field="solutionsNeed.nutrition"
			/>
			<prismic-rich-text
				v-if="
					solutionsNeed.health
					&&
					getUserConditional.coolingNeed == 'health'
				"
				:field="solutionsNeed.health"
			/>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";

import IconWizardComfortSafety from "@/components/icons/wizard/ComfortSafety.vue";
import IconWizardFoodNutrition from "@/components/icons/wizard/FoodNutrition.vue";
import IconWizardHealthCare from "@/components/icons/wizard/HealthCare.vue";

export default {
	name: "WizardResultNeed",
	components: {
		IconWizardComfortSafety,
		IconWizardFoodNutrition,
		IconWizardHealthCare
	},
	props: ["solutionsNeed"],
	computed: {
		...mapGetters({
			getUserConditional: "wizardToolUser/getUserConditional"
		})
	}
};
</script>