<template>
	<nav
		id="nav"
		ref="navcontainer"
		class="navbar position-absolute w-100 navbar-expand-md navbar-light font-gotham-rounded transition-250"
	>
		<a
			href="https://www.seforall.org/"
			target="_blank"
			class="navbar-brand mx-auto d-md-none"
		>
			<Logo />
		</a>
		<button
			class="navbar-toggler ml-auto border-0"
			type="button"
			data-toggle="collapse"
			data-target="#navbar"
			aria-controls="navbar"
			aria-expanded="false"
			aria-label="Toggle navigation"
			@click="toggleNav"
		>
			<span class="navbar-toggler-icon"></span>
		</button>
		<div
			class="d-flex flex-column flex-lg-row w-100 h-100 container-design justify-content-start align-items-center"
		>
			<div
				class="collapse navbar-collapse flex-grow-0 w-100 order-2 order-lg-0 py-lg-5 text-upper"
				id="navbar"
				ref="navbar"
				:style="{ top: this.height }"
			>
				<!--- Icon --->
				<div class="d-none d-md-inline-block">
					<router-link :to="{ name: 'home' }" class="navbar-brand">
						<Logo />
					</router-link>
				</div>

				<!--- Nav --->
				<div class="navbar-nav align-items-center">
					<router-link
						:to="{ name: 'home' }"
						active-class="active"
						exact
						class="nav-item text-darker nav-link text-uppercase p-0 mx-2 mx-md-4 mt-3 mb-md-0"
					>
						Home
					</router-link>
					<router-link
						:to="{ name: 'toolkit' }"
						active-class="active"
						exact
						class="nav-item text-darker nav-link text-uppercase p-0 mx-2 mx-md-4 mt-3 mb-md-0"
					>
						Campaign
					</router-link>
					<router-link
						:to="{ name: 'solutions' }"
						active-class="active"
						exact
						class="nav-item text-darker nav-link text-uppercase p-0 mx-2 mx-md-4 mt-3 mb-md-0"
					>
						Solutions
					</router-link>
					<router-link
						:to="{ name: 'stories' }"
						active-class="active"
						exact
						class="nav-item text-darker nav-link text-uppercase p-0 mx-2 mx-md-4 mt-3 mb-md-0"
					>
						Insights
					</router-link>
				</div>

				<!--- CTA --->
				<router-link
					:to="{ name: 'toolkit' }"
					class="d-none d-lg-flex flex-column ml-auto"
				>
					<!--- Nav Link --->
					<div class="btn btn-yellow text-uppercase ">
						<div class="d-flex align-items-center justify-content-between pl-2">
							<div class="mr-3 font-80 text-darker">
								Download the toolkit
							</div>
							<div class="icon-large text-darker">
								<b-icon icon="arrow-right-short" aria-hidden="true"></b-icon>
							</div>
						</div>
					</div>
				</router-link>
			</div>
		</div>
	</nav>
</template>
<script>
import Logo from "@/components/icons/Logo.vue";

export default {
	name: "Nav",
	components: {
		Logo
	},
	props: ["height", "button"],
	computed: {
		appUrl() {
			return process.env.VUE_APP_APP_URL;
		}
	},
	methods: {
		toggleNav() {
			let bodyClassList = document.body.classList;
			let navClassList = this.$refs.navbar.classList;
			let navContainerClassList = this.$refs.navcontainer.classList;

			bodyClassList.add("transition-250");
			bodyClassList.add("opacity-0");

			if (navClassList.contains("show"))
				window.setTimeout(
					() =>
						this.hideNav(bodyClassList, navClassList, navContainerClassList),
					250
				);
			else
				window.setTimeout(
					() =>
						this.showNav(bodyClassList, navClassList, navContainerClassList),
					250
				);

			window.setTimeout(function() {
				bodyClassList.remove("opacity-0");
			}, 500);
		},
		hideNav(bodyClassList, navClassList, navContainerClassList) {
			window.scrollTo(0, 0);
			navClassList.remove("show");

			navContainerClassList.remove("bg-white");
			bodyClassList.remove("overflow-hidden");
			bodyClassList.remove("vh-100");
		},
		showNav(bodyClassList, navClassList, navContainerClassList) {
			window.scrollTo(0, 0);
			navClassList.add("show");

			navContainerClassList.add("bg-white");
			bodyClassList.add("overflow-hidden");
			bodyClassList.add("vh-100");
		}
	},
	beforeDestroy() {
		let bodyClassList = document.body.classList;
		let navClassList = this.$refs.navbar.classList;
		let navContainerClassList = this.$refs.navcontainer.classList;

		if (navClassList.contains("show"))
			this.hideNav(bodyClassList, navClassList, navContainerClassList);
	}
};
</script>
<style lang="scss" scoped>
@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

.-ml-lg-50 {
	@include media-breakpoint-up(lg) {
		margin-left: -50%;
	}
}

.navbar {
	max-height: 200px;
	height: 100%;
	background-color: transparent;
	background: url("/img/clouds.png") top right no-repeat;
	background: url("/img/clouds.webp") top right no-repeat;
	background-size: contain;

	.nav-link {
		position: relative;
		transition: 250ms;

		&::after {
			content: "";
			position: absolute;

			transition: 250ms;

			width: 100%;
			height: 0.19rem;
			bottom: -0.095rem;
			left: 0;

			border-radius: 1rem;
			background: transparent;
		}

		&.active,
		&:hover {
			&::after {
				content: "";
				position: absolute;

				width: 100%;
				height: 0.19rem;
				bottom: -0.095rem;
				left: 0;

				border-radius: 1rem;
				background: $primary;
			}

			color: $primary !important;
		}
	}

	.navbar-toggler {
		@include media-breakpoint-down(md) {
			position: absolute;
			top: 1rem;
			right: 1rem;
		}
	}

	.navbar-collapse {
		@include media-breakpoint-down(sm) {
			z-index: 9999999;
			background: white;
			width: 100vw;
			height: 100vh;
			position: absolute;
			top: 160px !important;
			left: 0;
		}
	}
}
</style>
