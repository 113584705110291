<template>
	<div class="atoms">
		<div class="container-design">
			<div class="w-75 mx-auto">
				<div class="row py-5">
					<div class="col-12 text-center mb-md-5">
						<h2>{{ this.atoms.heading }}</h2>
						<prismic-rich-text
							class="lead"
							v-if="this.atoms.content"
							:field="this.atoms.content"
						/>
					</div>
					<div
						v-for="(card, index) in this.atoms.cards"
						:key="'atoms-card-' + index"
						class="col-md-4"
					>
						<div class="d-flex flex-column mx-4">
							<div class="mt-4 mb-1">
								<prismic-image
									class="w-100 image-fluid"
									v-if="card.card_image"
									:field="card.card_image"
								/>
								<picture v-else>
									<source srcset="/img/placeholder.webp" type="image/webp" />
									<source srcset="/img/placeholder.png" type="image/png" />
									<img width="180" src="/img/placeholder.png" />
								</picture>
							</div>
							<h6>{{ card.card_heading }}</h6>
							<prismic-rich-text
								class="font-80 rich-text-links text-atoms"
								v-if="card.card_content"
								:field="card.card_content"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Atoms",
	props: ["atoms"]
};
</script>
<style lang="scss">
.atoms {
	min-height: 50vh;
	background: #d9eef1;
	background-image: linear-gradient(
		180deg,
		#d9eef1 0%,
		#caeaee 29%,
		#d9eef1 100%
	);
}
</style>
