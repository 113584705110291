<template>
	<div>
		<!--- Heading Low/Medium/High Text --->
		<h2 class="h1 display-5">
			Based on living in {{ getUserCountry.name }} your access to cooling risk today is 
			<span v-if="getUserRiskResult == 'low'" class="rounded-pill bg-green px-3 text-white"> 
				Low
			</span>
			<span v-else-if="getUserRiskResult == 'medium'" class="rounded-pill bg-lighter px-3 text-white"> 
				Medium
			</span>
			<span v-else-if="getUserRiskResult == 'high'" class="rounded-pill bg-yellow px-3 text-white"> 
				High
			</span>
		</h2>

		<!--- Desc Low/Medium/High Text --->
		<prismic-rich-text
			v-if="
				yourResults.lowRisk 
				&&
				getUserRiskResult == 'low'
			"
			:field="yourResults.lowRisk"
		/>
		<prismic-rich-text
			v-if="
				yourResults.mediumRisk 
				&&
				getUserRiskResult == 'medium'
			"
			:field="yourResults.mediumRisk"
		/>
		<prismic-rich-text
			v-if="
				yourResults.highRisk 
				&&
				getUserRiskResult == 'high'
			"
			:field="yourResults.highRisk"
		/>
	</div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
	name: "WizardResultRisk",
	props: ["yourResults"],
	computed: {
		...mapGetters({
			getUserCountry: "wizardToolUser/getUserCountry",
			getUserRiskResult: "wizardToolUser/getUserRiskResult"
		})
	}
};
</script>
