import Vue from 'vue';

// Creating the matchMedia breakpoints
const matchMediaBreakpoints = {
	sm: window.matchMedia("(min-width: 576px)"),
	md: window.matchMedia("(min-width: 768px)"),
	lg: window.matchMedia("(min-width: 992px)"),
	xl: window.matchMedia("(min-width: 1200px)")
};

// Creating the matchMedia for devices
const matchMediaDevices = {
	speech: window.matchMedia("speech"),
	screen: window.matchMedia("screen"),
	print: window.matchMedia("print")
};

// Setting up breakpoint default matches
const mediaBreakpoints = {};
for (const [key, value] of Object.entries(matchMediaBreakpoints)) {
	mediaBreakpoints[key] = value.matches;
}

// Setting up device default matches
const mediaDevices = {};
for (const [key, value] of Object.entries(matchMediaDevices)) {
	mediaDevices[key] = value.matches;
}

// Assigning Change Listeners for Breakpoints
for (const [key, value] of Object.entries(matchMediaBreakpoints)) {
	value.addEventListener(
		"change",
		mediaQueryList => (mediaBreakpoints[key] = mediaQueryList.matches)
	);
}

// Assigning Change Listeners for Devices
for (const [key, value] of Object.entries(matchMediaDevices)) {
	value.addEventListener(
		"change",
		mediaQueryList => (mediaDevices[key] = mediaQueryList.matches)
	);
}

// Vue Reative Wrapper and Plugin Export for Getting the Media Breakpoints
var getMedia = new Vue({ data: { mediaBreakpoints, mediaDevices } });

export default {
	install(Vue) {
		Vue.prototype.$getMediaBreakpoints = getMedia.mediaBreakpoints;
		Vue.prototype.$getMediaDevices = getMedia.mediaDevices;
	}
};

/*function setupBreakpoints(){
	var matchMedia;

	switch(breakpoint){
		case "sm":
			matchMedia = window.matchMedia("(min-width: 576px)");
			break;

		case "md":
			matchMedia = window.matchMedia("(min-width: 768px)");
			break;

		case "lg":
			matchMedia = window.matchMedia("(min-width: 992px)");
			break;

		case "xl":
			matchMedia = window.matchMedia("(min-width: 1200px)");
			break;

		default:
			matchMedia = window.matchMedia("(min-width: 0px)");
			break;
	}

	matchMedia.addListener(element)
}*/
