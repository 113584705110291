<template>
	<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
		<circle cx="25" cy="25" r="25" fill="#e33a63"/>
		<path fill="#fff" d="M15.77 15.64l-.47.5a.82.82 0 000 1.16L30.9 32l-11.22.29a.81.81 0 00-.8.85v.69a.82.82 0 00.84.8l14.38-.39a.83.83 0 00.8-.85L34.48 19a.81.81 0 00-.84-.79h-.69a.82.82 0 00-.8.85l.35 11.21-15.56-14.66a.82.82 0 00-1.17 0"/>
	</svg>
</template>
<script>
export default {
	name: "IconsWizardReduce"
};
</script>
