<template>
	<div class="text-primary d-flex align-content-center">
		<svg xmlns="http://www.w3.org/2000/svg" class="h-2 w-2 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9" />
		</svg>
		<p>This information was pre-populated based on existing country information.</p>
	</div>
</template>
<script>
export default {
	name: "WizardQuestionCountry"
};
</script>