<template>
	<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
		<path d="M10 0h30a10 10 0 0110 10v40H0V10A10 10 0 0110 0z" fill="#b5cd77"/>
		<path fill="#fff" d="M14.8 29a11.8 11.8 0 01.5-6 4.42 4.42 0 013.13-3.11c.78-.14 3.15 0 6.57 1.95 3.4-1.92 5.79-2.09 6.57-1.95A4.42 4.42 0 0134.7 23a11.8 11.8 0 01.5 6c-.24 1.36-1.7 8.15-6.76 8.15a2.32 2.32 0 01-1-.34 4.92 4.92 0 00-4.82 0 2.33 2.33 0 01-1 .34C16.5 37.1 15 30.31 14.8 29M25 19.81a14.84 14.84 0 00-6.09-1.72 3.86 3.86 0 00-.78.06c-4.39.77-5.8 6.72-5 11.1s3.31 9.58 8.47 9.58a4 4 0 001.86-.56 3.23 3.23 0 011.54-.41 3.29 3.29 0 011.58.41 3.92 3.92 0 001.86.56c5.15 0 7.69-5.2 8.46-9.58s-.64-10.33-5-11.1a3.86 3.86 0 00-.78-.06A14.84 14.84 0 0025 19.81m1.86-5.91a4.16 4.16 0 012.42-1 4.13 4.13 0 01-1 2.41 4.13 4.13 0 01-2.41.95 4.13 4.13 0 01.95-2.41m-1.22-1.23C23.75 14.56 24.18 18 24.18 18a6.27 6.27 0 00.85.05 6.58 6.58 0 004.52-1.51c1.88-1.85 1.45-5.33 1.45-5.33a7.81 7.81 0 00-.85 0 6.55 6.55 0 00-4.52 1.5" />
	</svg>
</template>
<script>
export default {
	name: "IconsWizardFoodNutrition"
};
</script>
