var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"h2 text-darker mb-4"},[_vm._v(" "+_vm._s(_vm.question.fieldText)+" ")]),(_vm.data.countries)?_c('div',{staticClass:"d-flex flex-column w-lg-75-percent mx-auto mb-3 countries-dropdown"},[_c('div',{staticClass:"position-relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.data.countriesFilter),expression:"data.countriesFilter"}],staticClass:"p-2 w-100 rounded-0 border focus:outline-none",attrs:{"type":"text","placeholder":"Choose a country"},domProps:{"value":(_vm.data.countriesFilter)},on:{"focus":function($event){return _vm.showCountriesModal()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.data, "countriesFilter", $event.target.value)}}}),_c('div',{staticClass:"position-absolute top-0 right-0 p-2 pointer-events-none"},[_c('b-icon',{attrs:{"icon":"arrow-down"}})],1)]),(_vm.data.showCountriesModal)?_c('div',{staticClass:"border"},[_c('label',{staticClass:"w-100 p-2 px-lg-4 mb-0 text-left cursor-pointer",class:_vm.question.fieldValue == ''
						? 'bg-lightest text-white'
						: 'bg-white text-dark'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.question.fieldValue),expression:"question.fieldValue"}],staticClass:"d-none",attrs:{"type":"radio","value":"","selected":""},domProps:{"checked":_vm._q(_vm.question.fieldValue,"")},on:{"change":[function($event){return _vm.$set(_vm.question, "fieldValue", "")},function($event){_vm.data.countriesFilter = ''}]}}),_vm._v(" Choose a country ")]),_vm._l((_vm.data.countries.filter(
						function (country) { return country.name.toLowerCase().includes(_vm.data.countriesFilter.toLowerCase()); }
					).splice(0, 10)),function(country,index){return _c('label',{key:("introQuestionCountry-" + (country.key) + "-" + index),staticClass:"w-100 p-2 px-lg-4 mb-0 text-left cursor-pointer",class:_vm.question.fieldValue == country.key
						? 'bg-lightest text-dark'
						: 'bg-white text-dark'},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.question.fieldValue),expression:"question.fieldValue"}],staticClass:"d-none",attrs:{"type":"radio"},domProps:{"value":country.key,"checked":_vm._q(_vm.question.fieldValue,country.key)},on:{"change":[function($event){return _vm.$set(_vm.question, "fieldValue", country.key)},function () { _vm.data.countriesFilter = country.name; _vm.hideCountriesModal(); }]}}),_vm._v(" "+_vm._s(country.name)+" ")])})],2):_vm._e()]):_vm._e(),_c('div',{staticClass:"text-sm text-lighter font-weight-bold"},[(_vm.question.fieldDesc)?_c('prismic-rich-text',{attrs:{"field":_vm.question.fieldDesc}}):_vm._e(),(
				_vm.question.fieldTooltip &&
				_vm.question.fieldTooltip.length &&
				_vm.question.fieldTooltip[0].text
			)?_c('button',{staticClass:"btn btn-link text-sm text-lighter p-0",on:{"click":function () {
					_vm.data.showTooltip = true;
				}}},[_vm._v(" More info... ")]):_vm._e()],1),(_vm.data.showTooltip)?_c('div',{staticClass:"fixed-top w-100 h-100 background-tooltip d-flex flex-column justify-content-center align-items-center",on:{"click":function () {
				_vm.data.showTooltip = false;
			}}},[_c('div',{staticClass:"w-50-percent bg-white p-4 shadow"},[(_vm.question.fieldTooltip)?_c('prismic-rich-text',{attrs:{"field":_vm.question.fieldTooltip}}):_vm._e(),_c('prismic-link',{attrs:{"field":_vm.question.fieldTooltipLink.prismic}},[_vm._v(" "+_vm._s(_vm.question.fieldTooltipLink.text)+" ")])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }