<template>
  <div class="download-toolkit">
    <div class="container-design px-4 py-md-5 h-100 position-relative">
      <div
        class="d-flex align-items-center justify-content-center text-center h-100 w-md-75-percent w-lg-50-percent mx-auto"
      >
        <div class="py-5">
          <h3 class="h1 mb-3">{{ this.toolkit.heading }}</h3>
          <prismic-rich-text
            class="text-gradient-h1 mb-4 lead"
            v-if="this.toolkit.content"
            :field="this.toolkit.content"
          />
          <prismic-link
            class="btn btn-white text-uppercase shadow-lg"
            v-if="this.toolkit.download"
            :field="this.toolkit.download"
          >
            <div class="d-flex align-items-center justify-content-between px-2">
              <div class="mr-3 font-80 font-weight-bold text-primary">
                {{ this.toolkit.downloadText }}
              </div>
              <div class="icon-large">
                <b-icon icon="download" aria-hidden="true"></b-icon>
              </div>
            </div>
          </prismic-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DownloadToolkit",
  props: ["toolkit"]
};
</script>
<style lang="scss">
.download-toolkit {
  position: relative;
  min-height: 30vh;

  &::before {
    content: "";
    position: absolute;
    opacity: 0.64;

    height: 100%;
    width: 100%;

    background: #caebf5;
    background-image: url("/img/clouds-flip.png"),
          linear-gradient(180deg, #caebf5 0%, #ddf1f3 100%);
    background-image: url("/img/clouds-flip.webp"),
      linear-gradient(180deg, #caebf5 0%, #ddf1f3 100%);
    background-position: bottom right;
    background-repeat: no-repeat;
    // clip-path: ellipse(100% 55% at 48% 51%);
  }
}
</style>
