<template>
  <div class="container-design px-4 pt-3">
    <div class="row justify-content-center pt-5 py-lg-5">
      <div v-if="this.feature.image" class="col-md-8 col-lg-6">
        <prismic-image class="w-100 image-fluid" :field="this.feature.image" />
      </div>
      <div class="col-md-10 col-lg-6">
        <p class="text-light text-uppercase mb-1">
          {{ this.feature.title }}
        </p>
        <h2 class="h1 mb-3">{{ this.feature.heading }}</h2>
        <prismic-rich-text
          v-if="this.feature.content"
          :field="this.feature.content"
        />

        <button v-if="this.button" @click="this.button.action" class="btn btn-yellow text-uppercase mt-5">
          <div class="d-flex align-items-center justify-content-between pl-2">

            <div class="mr-3 font-80 text-darker">
              {{ this.button.text }}
            </div>
            <div class="icon-large text-darker">
              <b-icon icon="arrow-right-short" aria-hidden="true"></b-icon>
            </div>

          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FeatureLeftText",
  props: ["feature", "button"]
};
</script>
