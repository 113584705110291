export default function(doc) {
  if (doc.isBroken) {
    return "/not-found";
  }

  // If it is a Single Custom Type with the API ID of "home"
  if (doc.type === "home_page") {
    return "/";
  }

  if (doc.type === "stories_page") {
    return "/stories";
  }

  if (doc.type === "toolkit_page") {
    return "/toolkit";
  }

  if (doc.type === "wizard_page") {
    return "/wizard";
  }

  return "/not-found";
}
