<template>
	<div>
		<!--- Content --->
		<p class="h2 text-darker mb-4">
			{{ question.fieldText }}
		</p>

		<!--- Dropdown --->
		<div
			v-if="data.countries"
			class="d-flex flex-column w-lg-75-percent mx-auto mb-3 countries-dropdown"
		>
			<!--- Country Search Input --->
			<div class="position-relative">
				<input
					type="text"
					class="p-2 w-100 rounded-0 border focus:outline-none"
					v-model="data.countriesFilter"
					@focus="showCountriesModal()"
					placeholder="Choose a country"
				/>
				<div class="position-absolute top-0 right-0 p-2 pointer-events-none">
					<b-icon icon="arrow-down" />
				</div>
			</div>

			<!--- Country Modal --->
			<div v-if="data.showCountriesModal" class="border">
				<label
					class="w-100 p-2 px-lg-4 mb-0 text-left cursor-pointer"
					:class="
						question.fieldValue == ''
							? 'bg-lightest text-white'
							: 'bg-white text-dark'
					"
				>
					<input
						type="radio"
						value=""
						v-model="question.fieldValue"
						@change="data.countriesFilter = ''"
						class="d-none"
						selected
					/>
					Choose a country
				</label>
				<!-- eslint-disable prettier/prettier -->
				<label
					class="w-100 p-2 px-lg-4 mb-0 text-left cursor-pointer"
					v-for="
						(country, index)
						in
						data.countries.filter(
							country => country.name.toLowerCase().includes(data.countriesFilter.toLowerCase())
						).splice(0, 10)
					"
					:key="`introQuestionCountry-${country.key}-${index}`"
					:class="
						question.fieldValue == country.key
							? 'bg-lightest text-dark'
							: 'bg-white text-dark'
					"
				>
				<!-- eslint-enable prettier/prettier -->
					<input
						type="radio"
						:value="country.key"
						v-model="question.fieldValue"
						@change="() => { data.countriesFilter = country.name; hideCountriesModal(); }"
						class="d-none"
					/>
					{{ country.name }}
				</label>
			</div>
		</div>

		<!--- Content --->
		<div class="text-sm text-lighter font-weight-bold">
			<prismic-rich-text
				v-if="question.fieldDesc"
				:field="question.fieldDesc"
			/>
			<button
				class="btn btn-link text-sm text-lighter p-0"
				v-if="
					question.fieldTooltip &&
					question.fieldTooltip.length &&
					question.fieldTooltip[0].text
				"
				@click="
					() => {
						data.showTooltip = true;
					}
				"
			>
				More info...
			</button>
		</div>

		<!--- ToolTip Popup --->
		<div
			class="fixed-top w-100 h-100 background-tooltip d-flex flex-column justify-content-center align-items-center"
			v-if="data.showTooltip"
			@click="
				() => {
					data.showTooltip = false;
				}
			"
		>
			<div class="w-50-percent bg-white p-4 shadow">
				<prismic-rich-text
					v-if="question.fieldTooltip"
					:field="question.fieldTooltip"
				/>
				<prismic-link :field="question.fieldTooltipLink.prismic">
					{{ question.fieldTooltipLink.text }}
				</prismic-link>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
	name: "WizardIntroQuestionCountry",
	props: ["question"],
	data() {
		return {
			init: {
				countries: [],
				countriesFilter: "",
				showTooltip: false,
				showCountriesModal: false
			},
			data: {}
		};
	},
	mounted() {
		// Setup Data
		this.data = this.init;

		// Get Content
		this.getContent();
	},
	computed: {
		...mapGetters({
			getPrismicCountries: "prismicWizardTool/getPrismicCountries"
		})
	},
	methods: {
		...mapActions({
			fetchPrismicCountries: "prismicWizardTool/fetchPrismicCountries"
		}),

		async getContent() {
			// Get from store
			var countries = this.getPrismicCountries;

			// Get from API
			if (!countries.size > 0) {
				var countryIds = await this.fetchPrismicCountries();

				// Check the CountryIDs
				if (countryIds.length > 0) countries = this.getPrismicCountries;
			}

			// Check, parse, after-content
			if (countries.size > 0)
				countries.forEach((countryValue, countryKey) => {
					this.data.countries.push({
						key: countryKey,
						name: countryValue.data.country_name,
						desc: countryValue.data.country_description
					});
				});
		},

		checkClickCountriesModal(event) {
			if (!event.target.closest(".countries-dropdown"))
				this.hideCountriesModal();
		},
		showCountriesModal() {
			window.addEventListener("click", this.checkClickCountriesModal);
			this.data.showCountriesModal = true;
		},
		hideCountriesModal() {
			window.removeEventListener("click", this.checkClickCountriesModal);
			this.data.showCountriesModal = false;
		}

		// countriesInputPlaceholder() {
		// 	const country = this.data.countries.find(
		// 		country => country.key == this.question.fieldValue
		// 	);

		// 	if (country) return country.name;
		// 	return "Choose a country";
		// }
	}
};
</script>
