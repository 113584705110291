<template>
	<div class="container-fluid px-4 py-5 border-top border-bottom text-center">
		<div class="container-design">
			<div class="row justify-content-center align-items-center">
				<div class="col-md-6 col-lg-7 py-3">
					<p class="h4 text-uppercase m-0 w-auto text-gradient-h1">
						{{ this.newsletter.heading }}
					</p>
				</div>
				<div class="col-md-4 col-lg-2 offset-md-1 offset-lg-0 py-3">
					<a
						href="https://www.seforall.org/cooling-for-all/sign-up"
						class="d-block btn btn-primary text-uppercase"
					>
						<div class="d-flex align-items-center justify-content-between pl-2">
							<div class="mr-3 font-80">Sign Up Now!</div>
							<div class="icon-large">
								<b-icon icon="arrow-right-short" aria-hidden="true"></b-icon>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "Newsletter",
	props: ["newsletter"]
};
</script>
