<template>
	<div
		class="wizard-section transition-250"
		:class="loading.section ? 'opacity-0' : ''"
	>
		<Nav :height="height" />

		<div class="container-lg">
			<div
				class="min-h-screen mx-xl-10 pb-6"
				:style="'padding-top: calc(6rem + ' + height + ');'"
			>
				<!--- White Wizard Area --->
				<div class="w-100 min-h-100 bg-white rounded-xl shadow">
					<div class="d-flex flex-column align-items-center justify-content-center w-100 h-4 text-center bg-yellow">
						The Cooling For All Solutions Tool is in Beta
					</div>
					<!--- Wizard Body --->
					<div class="pb-5 px-2">
						<!--- Country Question --->
						<div v-if="maxDisplayQuestions == 0" class="pt-5 w-lg-75-percent mx-auto">
							<p class="text-sm text-primary text-uppercase font-weight-bold mb-2">
								Question 0
							</p>
							<WizardIntroQuestionCountry :question="country" class="pb-5" />

							<button
								class="btn btn-yellow text-uppercase d-block mx-auto"
								@click="getContent"
							>
								<div class="d-flex align-items-center justify-content-between pl-2">
									<p class="mr-3 mb-0 text-sm font-weight-bold text-darker">
										Get Questions
									</p>
									<div class="icon-large text-darker">
										<b-icon icon="arrow-right-short" aria-hidden="true"></b-icon>
									</div>
								</div>
							</button>
						</div>

						<!--- All Questions --->
						<div v-else-if="!loading.questions" class="pt-5 w-lg-75-percent mx-auto">
							<!--- Question Loop --->
							<div
								v-for="(question, index) in displayedQuestions"
								:key="'question-' + index + '-' + Date.now()"
							>
								<hr v-if="index > 0" class="border-dashed my-5" />
								<p class="text-sm text-primary text-uppercase font-weight-bold mb-2">
									Question {{ (index + 1) + "/" + (questions.length + 1) }} - {{question.fieldName}}
								</p>
								<component
									:is="question.component"
									:question="question"
								/>
							</div>

							<!--- More Questions to Load Section --->
							<div v-if="questions.length > maxDisplayQuestions">
								<hr class="border-dashed my-5" />
								<button
									class="btn btn-yellow text-uppercase d-block mx-auto"
									@click="increaseDisplayQuestions"
								>
									<div class="d-flex align-items-center justify-content-between pl-2">
										<p class="mr-3 mb-0 text-sm font-weight-bold text-darker">
											More Questions
										</p>
										<div class="icon-large text-darker">
											<b-icon icon="arrow-right-short" aria-hidden="true"></b-icon>
										</div>
									</div>
								</button>
							</div>

							<!--- End Wizard --->
							<div v-else>
								<!--- WizardQuestionSelect :question="coolingNeed" class="pb-5" / --->

								<hr class="border-dashed my-5" />
								<button
									class="btn btn-yellow text-uppercase d-block mx-auto"
									@click="endWizard"
									:disabled="loading.route"
								>
									<div class="d-flex align-items-center justify-content-between pl-2">
										<p class="mr-3 mb-0 text-sm font-weight-bold text-darker">
											Generate Report
										</p>
										<div class="icon-large text-darker">
											<b-icon icon="arrow-right-short" aria-hidden="true"></b-icon>
										</div>
									</div>
								</button>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Nav from "@/components/Nav.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";

import WizardQuestionRange from '../../components/wizard/WizardQuestionRange.vue';
import WizardQuestionDropdown from '../../components/wizard/WizardQuestionDropdown.vue';
import WizardQuestionSelect from '../../components/wizard/WizardQuestionSelect.vue';
import WizardQuestionNumberInput from '../../components/wizard/WizardQuestionNumberInput.vue';
import WizardIntroQuestionCountry from '../../components/wizard/WizardIntroQuestionCountry.vue';

export default {
	name: "wizard-test",
	components: {
		Nav,

		WizardQuestionRange,
		WizardQuestionDropdown,
		WizardQuestionSelect,
		WizardQuestionNumberInput,
		WizardIntroQuestionCountry
	},
	data() {
		const data = {
			height: "130px",

			country: {
				fieldText: "Select a Country",
				fieldValue: ""
			},
			coolingNeed: {
				fieldText: "Select a Need",
				fieldValue: "",
				fieldOptions: [
					{

					}
				]
			},

			displayedQuestions: [],
			questions: [],

			maxDisplayQuestions: 0,
			maxDisplayIncrease: 16,

			loading: {
				route: false,

				// Set Loading
				user: true,
				questions: true
			}
		}

		return data;
	},
	computed: {
		...mapGetters({
			getUserQuestions: "wizardToolUser/getUserQuestions"
		})
	},
	methods: {
		...mapMutations({
			setUserConditional: "wizardToolUser/setUserConditional"
		}),

		...mapActions({
			setupUser: "wizardToolUser/setupUser",
			setupCountry: "wizardToolUser/setupCountry"
		}),

		async getContent() {
			// Set Loading
			this.loading.questions = true;

			// Start User
			await this.startUser();

			// Get Questions
			this.questions = this.getUserQuestions;

			// Stop Loading Questions
			this.loading.questions = false;

			// After Content
			this.afterContent();
		},

		afterContent() {
			this.increaseDisplayQuestions();
		},

		async startUser() {
			// Filter Questions
			let introQuestionFields = {
				country: this.country.fieldValue,
				coolingNeed: "any",
				socialEco: "any",
				userPerspective: "any",
				climate: "0"
			};

			// Set User Config
			this.setUserConditional(introQuestionFields);

			// Setup User
			await this.setupUser();

			// Stop User Loading
			this.loading.user = false;
		},

		increaseDisplayQuestions() {
			this.maxDisplayQuestions = this.questions.length;
			//this.maxDisplayQuestions += this.maxDisplayIncrease;

			for(let index = 0; index < this.questions.length; index++) {
				// Check that we are under the display cap
				if(index >= this.maxDisplayQuestions) return;

				// Only push new questions
				if(index >= this.displayedQuestions.length) {
					// Set Compononent
					switch(this.questions[index].fieldType){
						case 'dropdown':
							this.questions[index].component = WizardQuestionDropdown;
							break;

						case 'select':
							this.questions[index].component = WizardQuestionSelect;
							break;

						case 'range':
							this.questions[index].component = WizardQuestionRange;
							break;

						default:
							this.questions[index].component = WizardQuestionNumberInput;
							break;
					}

					// Push new question
					this.displayedQuestions.push(this.questions[index]);
				}
			}
		},

		endWizard() {
			// Disable Button
			this.loading.route = true;

			// GoTo the Results
			this.$router.push(
				{
					name: 'wizard-generate'
				}
			).then(
				() => // Enable Button
					this.loading.route = false
			);
		}
	}
};
</script>
<style lang="scss">
.wizard-section {
	background: #d9eef1;
    background-image: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0.5) 0%,
		#caeaee 29%,
		#d9eef1 100%
    );
}
</style>