import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const prismicWizardTool = {
	namespaced: true,
	state: {
		prismicSections: new Map(),
		prismicQuestions: new Map(),
		prismicIntroQuestions: new Map(),
		prismicCountries: new Map(),
		prismicSolutions: new Map()
	},
	getters: {
		// Normal GETs for the Maps
		getPrismicSections: state => {
			return state.prismicSections;
		},
		getPrismicSectionByUid: state => prismicSection => {
			return state.prismicSections.get(prismicSection);
		},

		getPrismicQuestions: state => {
			return state.prismicQuestions;
		},
		getPrismicQuestionById: state => prismicQuestion => {
			return state.prismicQuestions.get(prismicQuestion);
		},

		getPrismicIntroQuestions: state => {
			return state.prismicIntroQuestions;
		},
		getPrismicIntroQuestionById: state => prismicIntroQuestion => {
			return state.prismicIntroQuestions.get(prismicIntroQuestion);
		},

		getPrismicCountries: state => {
			return state.prismicCountries;
		},
		getPrismicCountryByUid: state => prismicCountry => {
			return state.prismicCountries.get(prismicCountry);
		},

		getPrismicSolutions: state => {
			return state.prismicSolutions;
		},
		getPrismicSolutionsById: state => prismicSolution => {
			return state.prismicSolutions.get(prismicSolution);
		},

		// Functionality GETs
		getJSON: state => {
			return JSON.stringify({
				/* eslint-disable prettier/prettier */
				"prismicSections": 		 Array.from(state.prismicSections.entries()),
				"prismicQuestions": 	 Array.from(state.prismicQuestions.entries()),
				"prismicIntroQuestions": Array.from(state.prismicIntroQuestions.entries()),
				"primsicCountries": 	 Array.from(state.primsicCountries.entries()),
				"prismicSolutions":		 Array.from(state.prismicSolutions.entries())
				/* eslint-enable prettier/prettier */
			});
		}
	},
	mutations: {
		setPrismicSection(state, payload) {
			state.prismicSections.set(payload.uid, payload.document);
		},
		setPrismicQuestion(state, payload) {
			state.prismicQuestions.set(payload.id, payload.document);
		},
		setPrismicIntroQuestion(state, payload) {
			state.prismicIntroQuestions.set(payload.id, payload.document);
		},
		setPrismicCountry(state, payload) {
			state.prismicCountries.set(payload.uid, payload.document);
		},
		setPrismicSolution(state, payload) {
			state.prismicSolutions.set(payload.id, payload.document);
		}
	},
	actions: {
		async fetchPrismicSections({ commit }, prismicPromise = null) {
			return new Promise(async (resolve, reject) => {
				// Default Prismic Promise
				if (!prismicPromise)
					prismicPromise = new Promise((resolve, reject) => {
						resolve(
							Vue.prototype.$prismic.client.query(
								Vue.prototype.$prismic.Predicates.at(
									"document.type",
									"wizard_section"
								),
								{ pageSize: 100 }
							)
						);
					});

				// Default Documents Return
				const documents = [];

				// Wait for Promise
				await prismicPromise.then(async data => {
					// Check Response
					if (data.results !== undefined && data.results.length > 0)
						// Loop Over and Store Sections
						data.results.forEach(document => {
							commit("setPrismicSection", {
								uid: document.uid,
								document: document
							});
							documents.push(document.uid);
						});
				});

				// Return Null or Document IDs
				resolve(documents);
			});
		},
		async fetchPrismicQuestions({ commit }, prismicPromise = null) {
			return new Promise(async (resolve, reject) => {
				// Default Prismic Promise
				if (!prismicPromise)
					prismicPromise = new Promise((resolve, reject) => {
						resolve(
							Vue.prototype.$prismic.client.query(
								Vue.prototype.$prismic.Predicates.at(
									"document.type",
									"wizard_question"
								),
								{ pageSize: 100 }
							)
						);
					});

				// Default Documents Return
				const documents = [];

				// Wait for Promise
				await prismicPromise.then(async data => {
					// Check Response
					if (data.results !== undefined && data.results.length > 0)
						// Loop Over and Store Questions
						data.results.forEach(document => {
							commit("setPrismicQuestion", {
								id: document.id,
								document: document
							});
							documents.push(document.id);
						});
				});

				// Return Null or Document IDs
				resolve(documents);
			});
		},
		async fetchPrismicIntroQuestions({ commit }, prismicPromise = null) {
			return new Promise(async (resolve, reject) => {
				// Default Prismic Promise
				if (!prismicPromise)
					prismicPromise = new Promise((resolve, reject) => {
						resolve(
							Vue.prototype.$prismic.client.query(
								Vue.prototype.$prismic.Predicates.at(
									"document.type",
									"wizard_intro_question"
								),
								{ pageSize: 100 }
							)
						);
					});

				// Default Documents Return
				const documents = [];

				// Wait for Promise
				await prismicPromise.then(async data => {
					// Check Response
					if (data.results !== undefined && data.results.length > 0)
						// Loop Over and Store Questions
						data.results.forEach(document => {
							commit("setPrismicIntroQuestion", {
								id: document.id,
								document: document
							});
							documents.push(document.id);
						});
				});

				// Return Null or Document IDs
				resolve(documents);
			});
		},
		async fetchPrismicCountries({ commit, dispatch }, prismicPromise = null) {
			return new Promise(async (resolve, reject) => {
				// Default Prismic Promise
				if (!prismicPromise)
					prismicPromise = new Promise((resolve, reject) => {
						resolve(
							Vue.prototype.$prismic.client.query(
								Vue.prototype.$prismic.Predicates.at(
									"document.type",
									"wizard_country"
								),
								{
									pageSize: 100,
									orderings: "[my.wizard_country.country_name]"
								}
							)
						);
					});

				// Default Documents Return
				const documents = [];

				// Wait for Promise
				await prismicPromise.then(async data => {
					// Check Response
					if (data.results !== undefined && data.results.length > 0)
						// Loop Over and Store Questions
						data.results.forEach(document => {
							commit("setPrismicCountry", {
								uid: document.uid,
								document: document
							});
							documents.push(document.uid);
						});

					// Call again is there are other pages
					if (data.page != data.total_pages)
						await dispatch(
							"fetchPrismicCountries",
							new Promise((resolve, reject) => {
								resolve(
									Vue.prototype.$prismic.client.query(
										Vue.prototype.$prismic.Predicates.at(
											"document.type",
											"wizard_country"
										),
										{
											pageSize: 100,
											orderings: "[my.wizard_country.country_name]",
											page: data.page + 1
										}
									)
								);
							})
						).then(data => documents.concat(data));
				});

				// Return Null or Document IDs
				resolve(documents);
			});
		},
		async fetchPrismicSolutions({ commit }, prismicPromise = null) {
			return new Promise(async (resolve, reject) => {
				// Default Prismic Promise
				if (!prismicPromise)
					prismicPromise = new Promise((resolve, reject) => {
						resolve(
							Vue.prototype.$prismic.client.query(
								Vue.prototype.$prismic.Predicates.at(
									"document.type",
									"wizard_solution"
								),
								{ pageSize: 100 }
							)
						);
					});

				// Default Documents Return
				const documents = [];

				// Wait for Promise
				await prismicPromise.then(async data => {
					// Check Response
					if (data.results !== undefined && data.results.length > 0)
						// Loop Over and Store Questions
						data.results.forEach(document => {
							commit("setPrismicSolution", {
								id: document.id,
								document: document
							});
							documents.push(document.id);
						});
				});

				// Return Null or Document IDs
				resolve(documents);
			});
		}
	}
};

export default prismicWizardTool;
