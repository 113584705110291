<template>
<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
	<circle cx="25" cy="25" r="25" fill="#4f3b90"/>
	<path fill="#fff" d="M20.22 27.15a4.28 4.28 0 00-2.58.86l-1.72 1.29h-3.47a.36.36 0 00-.36.36v.71a.36.36 0 00.36.36h3.95l2.1-1.58a2.86 2.86 0 011.72-.57h6.57a1.08 1.08 0 110 2.15h-3.94a.72.72 0 100 1.43h8.07a1.92 1.92 0 001.2-.41l2.76-2.21a1 1 0 011.27 0 1 1 0 010 1.47l-4.55 3.61a1.91 1.91 0 01-1.2.42H12.45a.36.36 0 00-.36.36v.71a.36.36 0 00.36.36h18a3.36 3.36 0 002.09-.73L37 32.12a2.4 2.4 0 00.1-3.65 2.42 2.42 0 00-3.13 0l-2.76 2.21a.47.47 0 01-.3.1h-1.85a2.52 2.52 0 00.21-1.45 2.58 2.58 0 00-2.59-2.13zm.34-8.52a2.26 2.26 0 01.16-3.23 2 2 0 012.77.26L25 17.24l1.51-1.58a2 2 0 012.77-.26 2.26 2.26 0 01.16 3.23L25 23.28zm4.94-4l-.5.52-.5-.52a3.42 3.42 0 00-4.7-.34 3.71 3.71 0 00-.25 5.32l4.88 5.11a.78.78 0 001.14 0l4.88-5.11a3.7 3.7 0 00-.25-5.32 3.11 3.11 0 00-2.08-.79 3.73 3.73 0 00-2.62 1.13" />
</svg>
</template>
<script>
export default {
	name: "IconsWizardProtect"
};
</script>
