<template>
	<div class="d-inline-block position-relative">
		<div class="w-3 w-md-4 h-auto">
			<component :is="iconComponent" v-if="iconComponent != undefined" />
		</div>
		<div
			v-if="showApproachName"
			class="text-capitalize text-sm text-center py-1"
		>
			{{ wizardIcon }}
		</div>
	</div>
</template>
<script>
import IconWizardImprove from "@/components/icons/wizard/Improve.vue";
import IconWizardLeverage from "@/components/icons/wizard/Leverage.vue";
import IconWizardProtect from "@/components/icons/wizard/Protect.vue";
import IconWizardReduce from "@/components/icons/wizard/Reduce.vue";
import IconWizardShift from "@/components/icons/wizard/Shift.vue";

export default {
	name: "WizardApproach",
	props: ["wizardIcon", "showApproachName"],
	components: {
		IconWizardImprove,
		IconWizardLeverage,
		IconWizardProtect,
		IconWizardReduce,
		IconWizardShift
	},
	data () {
		return {
			iconComponent: undefined
		}
	},
	mounted () {
		switch (this.wizardIcon) {
			case "improve":
				this.iconComponent = IconWizardImprove;
				break;
			case "leverage":
				this.iconComponent = IconWizardLeverage;
				break;
			case "protect":
				this.iconComponent = IconWizardProtect;
				break;
			case "reduce":
				this.iconComponent = IconWizardReduce;
				break;
			case "shift": 
				this.iconComponent = IconWizardShift;
				break;
			default: 
				break;
		}
	}
};
</script>
