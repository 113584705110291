<template>
  <div class="toolkit opacity-0 transition-250">
    <Nav :height="this.height" />

    <!-- Hero -->
    <div class="hero toolkit">
      <div
        class="container-design pb-3 px-4"
        :style="{ paddingTop: this.height }"
      >
        <div class="text-center w-md-75-percent mx-auto">
          <h1 class="display-3 mb-4">
            {{ fields.hero.headline }}
          </h1>
          <prismic-rich-text
            class="lead text-dark"
            v-if="fields.hero.content"
            :field="fields.hero.content"
          />
          <a
            href="#stories"
            class="btn btn-outline-white my-3 text-uppercase text-primary"
          >
            <div class="d-flex align-items-center justify-content-between px-2">
              <div class="mr-3 font-80 font-weight-bold">
                {{ fields.hero.downloadText }}
              </div>
              <div class="icon-large">
                <b-icon icon="download" aria-hidden="true"></b-icon>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <!-- Tablet -->
    <Tablet class="d-none d-md-block" :tablet="fields.tablet" />

    <!-- Feature Left Text -->
    <FeatureLeftText :feature="fields.featureLeft" />

    <!-- Header -->
    <TextHeader
      class="pb-3 pt-5 mt-5"
      :header="fields.header"
      :backgroundGradient="'linear-gradient(180deg, #fff 0%, #d9eef3 100%)'"
    />

    <!-- Cards -->
    <StoryCards
      id="stories"
      :storyCards="fields.storyCards"
      :backgroundGradient="
        'linear-gradient(0deg, rgba(202, 235, 245, 64%) 0%, #caebf5 50%, #d9eef3 100%)'
      "
    />

    <!-- Download Toolkit -->
    <DownloadToolkit :toolkit="fields.downloadToolkit" />

    <!-- Newsletter -->
    <Newsletter :newsletter="fields.newsletter" />
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";
import Tablet from "@/components/Tablet.vue";
import FeatureLeftText from "@/components/FeatureLeftText.vue";
import TextHeader from "@/components/TextHeader.vue";
import StoryCards from "@/components/StoryCards.vue";
import DownloadToolkit from "@/components/DownloadToolkit.vue";
import Newsletter from "@/components/Newsletter.vue";

import Cocoen from "cocoen";
import bodymovin from "lottie-web";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "toolkit",
  components: {
    Nav,
    Tablet,
    FeatureLeftText,
    TextHeader,
    StoryCards,
    DownloadToolkit,
    Newsletter
  },
  metaInfo() {
    //TODO: Get from prismic fields?
    return {
      title: this.page.pageTitle,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.page.pageDesc
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: this.page.pageTags
        }
      ]
    };
  },
  mounted() {
    this.getContent();

		let multiplier = 0.8;

		if (this.$getMediaBreakpoints.md)
			multiplier = 1.4;

		if (this.$getMediaBreakpoints.lg)
			multiplier = 1.1;

    this.height = 200 * multiplier + "px";

    bodymovin.loadAnimation({
      container: document.getElementById("bm"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/animations/data.json"
    });
  },
  data() {
    return {
      height: "130px",
      fields: {
        navButton: {
          download: null,
          downloadText: null
        },
        hero: {
          headline: null,
          content: null
        },
        tablet: {
          image: null,
          content: null
        },
        featureLeft: {
          title: null,
          heading: null,
          content: null,
          image: null
        },
        header: {
          heading: null,
          content: null
        },
        storyCards: null,
        downloadToolkit: {
          heading: null,
          content: null,
          download: null,
          downloadText: null
        },
        newsletter: {
          heading: null
        }
      },
      page: {
        pageTitle: null,
        pageDesc: null,
        pageTags: null
      }
    };
  },
  computed: {
    ...mapGetters({ 
      getPrismicPage: "prismicPage/getPrismicPageByName"
    })
  },
  methods: {
    ...mapActions({ 
      fetchPrismicPage: "prismicPage/fetchPrismicPage"
    }),

    async getContent() {
      // Get from store
      var document = this.getPrismicPage("toolkit_page");

      // Get from API
      if(!document)
        document = await this.fetchPrismicPage("toolkit_page");

      // Check, parse, after-content
      if(document) {
        this.parseDocument(document);
        this.afterContent();
      }
    },
    afterContent() {
      this.doFadeIn();
    },
    doFadeIn() {
      document.querySelector(".toolkit").classList.remove("opacity-0");
    },
    parseDocument(document) {
      if (document) {
        // Page
        this.page.pageTitle = document.data.page_title;
        this.page.pageDesc = document.data.page_description;
        this.page.pageTags = document.data.page_keywords;

        // Nav
        this.fields.navButton.download = document.data.nav_button_link;
        this.fields.navButton.downloadText = document.data.nav_button_text;

        // Hero
        this.fields.hero.headline = document.data.hero_headline;
        this.fields.hero.content = document.data.hero_content;
        this.fields.hero.downloadText = document.data.hero_button_text;

        // Tablet
        this.fields.tablet.image = document.data.tablet_image;
        this.fields.tablet.content = document.data.tablet_content;

        // FeatureLeft
        this.fields.featureLeft.title = document.data.feature_left_text_title;

        this.fields.featureLeft.heading =
          document.data.feature_left_text_heading;
        this.fields.featureLeft.content =
          document.data.feature_left_text_content;

        this.fields.featureLeft.image = document.data.feature_left_text_image;

        // Header
        this.fields.header.heading = document.data.header_heading;
        this.fields.header.content = document.data.header_content;

        // Story Cards
        this.fields.storyCards = document.data.story_cards;

        // Download Toolkit
        this.fields.downloadToolkit.heading = document.data.toolkit_heading;
        this.fields.downloadToolkit.content = document.data.toolkit_content;
        this.fields.downloadToolkit.download = document.data.toolkit_download;
        this.fields.downloadToolkit.downloadText =
          document.data.toolkit_download_text;

        // Newsletter
        this.fields.newsletter.heading = document.data.newsletter_heading;
      }
    }
  }
};
</script>
<style lang="scss">
.hero {
  &.toolkit {
    min-height: unset;
    background: #d9eef1;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 0%,
      #caeaee 29%,
      #d9eef1 100%
    );
  }
}
</style>
